import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { get } from 'api/fetch';

// ================== Types ================== //

type CompanyInfoType = {
  companyID: number;
  companyCoversDependantCosts: boolean;
  hasCompletedCompany: boolean;
  name: string;
};

type CurrentContractType = {
  companyContractID?: number;
  company: CompanyInfoType;
};

type CompanyInfoState = {
  isLoading: boolean;
  error: object | string | null;
  companyInfo?: Pick<CompanyInfoType, 'companyID'> | null;
};

type CompanyContractState = {
  isLoading: boolean;
  error: object | string | null;
  currentContract?: CurrentContractType | null;
};

type State = {
  companyInfo: CompanyInfoState;
  companyContract: CompanyContractState;
};

// ================== Reducers ================== //

const initialState: State = {
  companyInfo: {
    isLoading: false,
    error: null,
    companyInfo: null
  },
  companyContract: {
    isLoading: false,
    error: null,
    currentContract: null
  }
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // fetchCompanyInfo
    fetchCompanyInfoRequest: (state) => {
      state.companyInfo = {
        isLoading: true,
        error: null
      };
    },
    fetchCompanyInfoSuccess: (state, { payload }) => {
      state.companyInfo = {
        isLoading: false,
        error: null,
        companyInfo: payload
      };
    },
    fetchCompanyInfoFailure: (state, { payload }) => {
      state.companyInfo = {
        isLoading: false,
        error: payload,
        companyInfo: null
      };
    },
    // fetchCompanyContract
    fetchCompanyContractRequest: (state) => {
      state.companyContract = {
        isLoading: true,
        error: null
      };
    },
    fetchCompanyContractSuccess: (state, { payload }) => {
      state.companyContract = {
        isLoading: false,
        error: null,
        currentContract: {
          ...payload,
          isHybrid:
            (payload.companyBillingAmount && payload.companyBillingAmount > 0) ||
            (payload.planHolderBillingAmount && payload.planHolderBillingAmount > 0) ||
            (payload.companyBillingPercentage &&
              payload.companyBillingPercentage !== 100) ||
            (payload.planHolderBillingPercentage &&
              payload.planHolderBillingPercentage !== 100) ||
            false
        }
      };
    },
    fetchCompanyContractFailure: (state, { payload }) => {
      state.companyContract = {
        isLoading: false,
        error: payload
      };
    },
    updateCompanyInfo: (state, { payload }) => {
      state.companyInfo.companyInfo = {
        ...state.companyInfo.companyInfo,
        ...payload
      };
    }
  }
});

export default slice.reducer;

const { actions } = slice;

// ================== Actions ================== //

export const fetchCompanyInfo = () => async (dispatch: Dispatch) => {
  dispatch(actions.fetchCompanyInfoRequest());

  try {
    const response = await get('/rh/companies', {
      api: 'rh',
      redirectOnUnauthorized: true
    });
    dispatch(actions.fetchCompanyInfoSuccess(response));
  } catch (error) {
    dispatch(actions.fetchCompanyInfoFailure(error));
  }
};

export const updateCompanyInfo = (info: Partial<CompanyInfoType>) => (dispatch: any) => {
  dispatch(actions.updateCompanyInfo(info));
};

export const fetchCompanyContract =
  (isMember = false) =>
  async (dispatch: any) => {
    dispatch(actions.fetchCompanyContractRequest());
    try {
      const response = await get(
        isMember ? `/insurance/rh/contracts/company/member` : `/rh/contracts/company`,
        {
          api: isMember ? 'backend' : 'rh',
          redirectOnUnauthorized: true
        }
      );
      if (isEmpty(response)) {
        dispatch(actions.fetchCompanyContractFailure('No company contract'));
      } else {
        dispatch(actions.fetchCompanyContractSuccess(response));
      }
    } catch (error) {
      dispatch(actions.fetchCompanyContractFailure(error));
    }
  };
