import { createSlice } from '@reduxjs/toolkit';
import { post } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/referrer',
  initialState: {
    isLoading: false,
    error: null,
    referrer: null,
    referrerIsAffinity: null
  },
  reducers: {
    referrerCheckRequest: (state) => {
      state.isLoading = true;
      state.error = null;
      state.referrer = null;
    },
    referrerCheckSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.referrer = payload;
      state.referrerIsAffinity = payload?.companyType === 'AFFINITY';
    },
    referrerCheckFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.referrer = null;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const referrerCheck =
  (token, referrer, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(actions.referrerCheckRequest());

    try {
      const response = await post('/referrers/check', { token, referrer }, { api: 'rh' });

      dispatch(actions.referrerCheckSuccess(response));
      onSuccess();
    } catch (error) {
      dispatch(actions.referrerCheckFailure(error));
    }
  };
