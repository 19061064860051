const urlParams = new URLSearchParams(window.location.search);

export const FEATURE_MEMBER_EXPERIENCE =
  process.env.REACT_APP_FEATURE_MEMBER_EXPERIENCE === 'true';

export const FEATURE_COMPANY_SIGNUP =
  urlParams.get('enable-company-signup') === 'true' ||
  process.env.REACT_APP_FEATURE_COMPANY_SIGNUP === 'true';

export const COMPANY_SIGNUP_URL = process.env.REACT_APP_COMPANY_SIGNUP_URL;
if (!COMPANY_SIGNUP_URL) {
  throw new Error(
    `env.REACT_APP_COMPANY_SIGNUP_URL must be set when env.REACT_APP_FEATURE_COMPANY_SIGNUP is enabled`
  );
}

export const FEATURE_LIFE_ADDON = process.env.REACT_APP_FEATURE_LIFE_ADDON === 'true';
