import { combineReducers } from 'redux';

import login from 'views/Login/state';
import claims from 'views/Claims/state';
import companyProfile from 'views/CompanyProfile/reducer';
import companyPurchase from 'views/CompanyPurchase/state';
import companyRegistration from 'views/CompanyRegistration/reducer';
import memberSignup from 'views/MemberSignup/state';
import memberActivation from 'views/MemberActivation/reducer';
import memberProfile from 'views/MemberProfile/reducer';
import company from 'state/company';
import planholder from 'state/planholder';
import dependantConfig from 'state/dependantConfig';
import plans from 'state/plans';
import plan from 'state/plan';
import referrer from 'state/referrer';
import app from 'state/app';
import websiteData from 'state/websiteData';
import companySignupStatus from 'state/companySignupStatus/slice';
import beneficiaries from 'state/beneficiaries';
import explorer from './explorer';

const remoteHealth = combineReducers({
  planholder,
  dependantConfig,
  plan,
  plans,
  company,
  login,
  companyRegistration,
  companyProfile,
  companyPurchase,
  memberSignup,
  memberActivation,
  memberProfile,
  claims,
  referrer,
  explorer,
  websiteData,
  companySignupStatus,
  beneficiaries
});

const appReducer = combineReducers({ app, remoteHealth });

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default function createReducer() {
  return rootReducer;
}
