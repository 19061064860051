import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FullStory, isInitialized, init } from '@fullstory/browser';
import { destroyCookie } from 'nookies';
import { decodeJWT } from 'auth';

type Path = string;
type OrgId = string;

type FullStoryProps = {
  pathOrgIdMap: Record<Path, OrgId>;
};

/**
 * Finds closest path in paths
 * ```javascript
 * const paths = ['/', '/company', '/company/signup']
 * findClosestPath(paths, '/company/signup/step1') // '/company/signup'
 * findClosestPath(paths, '/company/explore') // '/company'
 * findClosestPath(paths, '/company') // '/company' 
 * findClosestPath(paths, '/faq') // '/'
 * ```
 `
 */
const findClosestPath = (paths: Path[], path: Path) => {
  const matchingPaths = paths
    .filter((p) => path.startsWith(p))
    .sort((a, b) => {
      const aa = a.endsWith('/') ? a : `${a}/`;
      const bb = b.endsWith('/') ? b : `${b}/`;
      return bb.split('/').length - aa.split('/').length;
    });
  return matchingPaths[0];
};

const getWindowOrgId = () => {
  return (window as Window & typeof globalThis & { fullstoryOrgId: OrgId })
    .fullstoryOrgId;
};

const setWindowOrgId = (orgId: OrgId) => {
  (window as Window & typeof globalThis & { fullstoryOrgId: OrgId }).fullstoryOrgId =
    orgId;
};

const stopFullStory = () => {
  if (!isInitialized()) {
    return;
  }
  FullStory('shutdown');
  // @ts-ignore
  delete window.FS;
  // @ts-ignore
  delete window._fs_loaded; // eslint-disable-line no-underscore-dangle
  delete window._fs_initialized; // eslint-disable-line no-underscore-dangle

  // @ts-ignore
  destroyCookie({}, 'fs_uid', {
    path: '/'
  });
};

const startFullStory = (orgId: OrgId) => {
  if (isInitialized()) {
    // correct org already initialized, do nothing
    if (getWindowOrgId() === orgId) {
      return;
    }

    // Wrong org initialized, shutdown before re-init
    stopFullStory();
  }

  // FullStory not initialized, init
  init({ orgId });
  setWindowOrgId(orgId);

  const userId = decodeJWT('userID');

  if (userId) {
    FullStory('setIdentity', userId);
  }
};

const FullStorySwitcher = ({ pathOrgIdMap }: FullStoryProps) => {
  if (!pathOrgIdMap['/']) {
    throw new Error(
      'FullStorySwitcher: pathOrgIdMap must have a default orgId { "/": OrgId }'
    );
  }

  const location = useLocation();

  const orgId = useMemo(() => {
    const closestPath = findClosestPath(Object.keys(pathOrgIdMap), location.pathname);
    return pathOrgIdMap[closestPath] ?? pathOrgIdMap['/'];
  }, [location.pathname]);

  useEffect(() => {
    startFullStory(orgId);
  }, [orgId]);

  return null;
};

export default FullStorySwitcher;
