import concatenateReducers from 'redux-concatenate-reducers';

export const filterReducer = (reducer) => {
  let knownKeys = Object.keys(reducer(undefined, { type: '@@FILTER/INIT' }));

  return (state, action) => {
    let filteredState = state;

    if (knownKeys.length && state !== undefined) {
      filteredState = knownKeys.reduce((current, key) => {
        current[key] = state[key];
        return current;
      }, {});
    }

    const newState = reducer(filteredState, action);

    let nextState = state;

    if (newState !== filteredState) {
      knownKeys = Object.keys(newState);
      nextState = {
        ...state,
        ...newState
      };
    }

    return nextState;
  };
};

export const mergeReducers = (...reducers) =>
  concatenateReducers(reducers.map((reducer) => filterReducer(reducer)));
