import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
 0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const SpinnerContainer = styled.div`
  text-align: center;
  position: relative;
  margin: auto;
  animation: ${bounce} 2s infinite;
`;

export const Logo = styled.img<LogoProps>`
  height: ${({ size }) => size || '50px'};
`;

type LogoProps = {
  size?: string;
};
