import { useReducer, useCallback, useEffect } from 'react';
import constate from 'constate';
import { getId } from 'hooks/useId';
import { ToastProps } from './types';

type Toast = {
  id: string;
  toastProps: ToastProps;
  createdAt: number;
  duration: number;
};

type Action =
  | {
      type: 'ADD_TOAST';
      payload: Toast;
    }
  | {
      type: 'REMOVE_TOAST';
      payload: { id: string };
    };

const reducer = (state: Toast[], { type, payload }: Action) => {
  switch (type) {
    case 'ADD_TOAST':
      return [payload, ...state];

    case 'REMOVE_TOAST':
      return state.filter((t) => t.id !== payload.id);

    default:
      return state;
  }
};

const useToaster = () => {
  const [toasts, dispatch] = useReducer(reducer, []);

  const addToast = useCallback(
    (toastProps: Omit<ToastProps, 'onClose'>, duration: number = 5000) => {
      const id = getId();

      const handleClose = () => {
        dispatch({
          type: 'REMOVE_TOAST',
          payload: { id }
        });
      };

      dispatch({
        type: 'ADD_TOAST',
        payload: {
          id,
          toastProps: {
            ...toastProps,
            onClose: handleClose
          },
          createdAt: Date.now(),
          duration
        }
      });

      return handleClose;
    },
    []
  );

  const removeToast = useCallback((id: string) => {
    dispatch({
      type: 'REMOVE_TOAST',
      payload: { id }
    });
  }, []);

  // Keep track of toast duration
  useEffect(() => {
    if (!toasts.length) {
      return;
    }

    const expireToasts = () => {
      const now = Date.now();
      toasts.forEach((t) => {
        if (now - t.createdAt >= t.duration) {
          removeToast(t.id);
        }
      });
    };

    expireToasts();
    const interval = setInterval(expireToasts, 100);

    return () => {
      clearInterval(interval as unknown as number);
    };
  }, [toasts, removeToast]);

  return { toasts, addToast, removeToast };
};

export const [ToasterProvider, useToasterContext] = constate(useToaster);
