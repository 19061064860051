import { createSlice } from '@reduxjs/toolkit';
import { get, put } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/WebsiteData',
  initialState: {
    isLoading: false,
    error: null,
    hasSeenMemberImportPopup: null,
    isFinchIntegrationEnabled: null
  },
  reducers: {
    setHasSeenMemberImportPopup: (state, { payload }) => {
      state.hasSeenMemberImportPopup = payload;
    },

    fetchMemberWebsiteDataRequest: (state) => {
      state.isLoading = true;
    },
    fetchMemberWebsiteDataSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.hasSeenMemberImportPopup = payload.hasSeenMemberImportPopup;
      state.isFinchIntegrationEnabled = payload.isFinchIntegrationEnabled;
    },
    fetchMemberWebsiteDataFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const fetchMemberWebsiteData = () => async (dispatch) => {
  dispatch(actions.fetchMemberWebsiteDataRequest());

  try {
    const response = await get(`/rh/member-website-data`, { api: 'rh' });
    dispatch(actions.fetchMemberWebsiteDataSuccess(response));
  } catch (error) {
    dispatch(actions.fetchMemberWebsiteDataFailure(error));
  }
};

export const memberHasSeenImportPopup = () => async (dispatch) => {
  dispatch(actions.setHasSeenMemberImportPopup(true));

  const body = {
    hasSeenMemberImportPopup: true
  };
  put(`/rh/member-website-data`, body, { api: 'rh' });
};
