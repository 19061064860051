import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get, post } from 'api/fetch';

// ================== Actions ================== //

export const fetchLatestContractInformation = createAsyncThunk(
  'remote_health/MemberProfile/contract/fetchLatest',
  async () => {
    const response = await get('/rh/contracts/member/current-self', { api: 'rh' });
    return response;
  }
);

export const fetchClaimableContracts = createAsyncThunk(
  'remote_health/MemberProfile/contract/fetchClaimable',
  async () => {
    const response = await get('/rh/contracts/member/allClaimable', {
      api: 'rh'
    });
    return response;
  }
);

export const updateMemberContractStartDate = createAsyncThunk(
  'MemberProfile/contract/updateStartDate',
  async (isoDate) => {
    await post(`/member/onboarding/update-start-date/${isoDate}`, null, { api: 'rh' });
    return isoDate;
  }
);

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/MemberProfile/contract',
  initialState: {
    isLoading: false,
    contract: null,
    error: null,
    isLoadingClaimableContracts: false,
    claimableContractsError: false,
    claimableContracts: null
  },
  extraReducers: {
    [fetchLatestContractInformation.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchLatestContractInformation.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.contract = payload;
    },
    [fetchLatestContractInformation.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.contract = null;
      state.error = payload;
    },
    [updateMemberContractStartDate.fulfilled]: (state, { payload }) => {
      state.contract.startDate = payload;
    },
    [fetchClaimableContracts.pending]: (state) => {
      state.isLoadingClaimableContracts = true;
    },
    [fetchClaimableContracts.fulfilled]: (state, { payload }) => {
      state.isLoadingClaimableContracts = false;
      state.claimableContractsError = null;
      state.claimableContracts = payload;
    },
    [fetchClaimableContracts.rejected]: (state, { payload }) => {
      state.isLoadingClaimableContracts = false;
      state.claimableContracts = [];
      state.claimableContractsError = payload;
    }
  }
});

export const { actions } = slice;
export default slice.reducer;
