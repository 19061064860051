import { createSlice } from '@reduxjs/toolkit';
import { post, get } from 'api/fetch';
import ReactGA from 'react-ga';
import format from 'date-fns/format';
import history from 'routing/history';
import { isValidEmail } from 'assets/helpers';
import { firePurchaseTrackingEvent } from 'api/analytics';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'companyPurchase/members',
  initialState: {
    isLoadingPurchase: false,
    isLoadingMembers: false,
    isLoadingAdmin: false,

    error: null,

    contractForAdmin: null,

    memberEmails: [],
    invalidEmails: [],

    memberStatus: [],
    membersAdded: [],
    membersFailed: [],

    areInvitesSent: false
  },
  reducers: {
    addMemberEmails: (state, { payload }) => {
      state.memberEmails = payload.emails;
      state.invalidEmails = payload.invalidEmails;
    },
    // sendEmailInvites
    sendEmailInvitesRequest: (state) => {
      state.isLoadingMembers = true;
      state.error = null;
    },
    sendEmailInvitesSuccess: (state, { payload }) => {
      state.isLoadingMembers = false;
      state.error = null;
      state.memberStatus = payload?.memberStatus || [];
      state.membersAdded = payload?.membersAdded || [];
      state.membersFailed = payload?.membersFailed || [];
    },
    sendEmailInvitesFailure: (state, { payload }) => {
      state.isLoadingMembers = false;
      state.error = payload;
    },
    fetchContractForAdminRequest: (state) => {
      state.isLoadingAdmin = true;
      state.error = null;
    },
    fetchContractForAdminSuccess: (state, { payload }) => {
      state.isLoadingAdmin = false;
      state.error = null;
      state.contractForAdmin = payload;
    },
    fetchContractForAdminFailure: (state, { payload }) => {
      state.isLoadingAdmin = false;
      state.contractForAdmin = null;
      state.error = payload;
    },
    initiateCompanyPurchaseRequest: (state) => {
      state.isLoadingPurchase = true;
      state.error = null;
    },
    initiateCompanyPurchaseSuccess: (state) => {
      state.isLoadingPurchase = false;
    },
    initiateCompanyPurchaseFailure: (state, { payload }) => {
      state.isLoadingPurchase = false;
      state.error = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const addMemberEmails = (emails) => (dispatch) => {
  dispatch(
    actions.addMemberEmails({
      emails,
      invalidEmails: (emails || []).filter((email) => !isValidEmail(email.value.trim()))
    })
  );
};

export const fetchContractForAdmin = () => async (dispatch) => {
  dispatch(actions.fetchContractForAdminRequest());
  try {
    const response = await get(`/rh/contracts/company`, {
      api: 'rh'
    });
    dispatch(actions.fetchContractForAdminSuccess(response));
  } catch (error) {
    dispatch(actions.fetchContractForAdminFailure(error));
  }
};

export const initiateCompanyPurchase = (next) => async (dispatch, useState) => {
  dispatch(actions.initiateCompanyPurchaseRequest());

  const { config, price } = useState().remoteHealth.companyProfile.purchase;
  const { userID } = useState().app.user;

  const body = {
    insuranceConfig: {
      isCompany: true,
      company: true,
      usElective: config.addons.includes('COVERAGE'),
      deductible: config.addons.includes('DEDUCTIBLE') ? 0 : 250,
      paymentFrequency: config.paymentFrequency === 'Annually' ? 'YEARLY' : 'MONTHLY',
      addons: config.addons,
      memberCount: {
        memberCount0to17: config.members['0to17'],
        memberCount18to39: config.members['18to39'],
        memberCount40to50: config.members['40to49'],
        memberCount51to60: config.members['50to59'],
        memberCount61to70: config.members['60to69']
      }
    },
    startDate: format(new Date(), 'yyyy-MM-dd'),
    includeDependants: config.addons.includes('DEPENDANTS'),
    companyContractDetails: {
      otherInsuranceDetails: null,
      companyRegistrationNumber: null,
      companyEntityCountries: []
    }
  };

  try {
    await post('/insurance/rh/purchase/company', body);

    dispatch(actions.initiateCompanyPurchaseSuccess());

    ReactGA.event({
      category: 'Remote Health',
      action: 'Purchase'
    });

    // send analytics
    const affiliateID = localStorage.getItem('referenceID');
    firePurchaseTrackingEvent(
      userID,
      'remoteHealth',
      price?.price?.totalMonthlyPrice * 12,
      'single',
      affiliateID
    );

    if (next) {
      history.push(next);
    }
  } catch (error) {
    dispatch(actions.initiateCompanyPurchaseFailure(error));
  }
};
export const sendEmailInvites = (personalizedMessage) => async (dispatch, useState) => {
  dispatch(actions.sendEmailInvitesRequest());
  const { memberEmails } = useState().remoteHealth.companyPurchase.members;
  const { companyInfo } = useState().remoteHealth.company.companyInfo;

  const planMembers = (companyInfo?.members || []).map(({ email }) => email);
  const addingSelf =
    memberEmails.filter((email) => email.value === companyInfo?.email).length > 0;

  const body = {
    add: memberEmails
      .filter(
        (email) =>
          planMembers.indexOf(email.value) < 0 && companyInfo?.email !== email.value
      )
      .map((email) => ({
        email: email.value
      }))
  };

  if (personalizedMessage) {
    body.personalNote = personalizedMessage;
  }

  if (addingSelf && planMembers.indexOf(companyInfo?.email) < 0) {
    body.addingSelf = true;
  }

  try {
    dispatch(actions.sendEmailInvitesRequest());
    const members = await post('/insurance/rh/members', body);
    dispatch(actions.sendEmailInvitesSuccess(members));

    if (!members?.membersFailed) {
      history.push('/company');
    }
  } catch (error) {
    dispatch(actions.sendEmailInvitesFailure(error));
  }
};
