import { createSlice } from '@reduxjs/toolkit';
import { post } from 'api/fetch';
import { updatePlanholderInfo } from 'state/planholder';
import { updateCompanyInfo } from 'state/company';
import { pushToJWT } from 'auth';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'companyRegistration',
  initialState: {
    isLoading: false,
    error: null,
    companyInfo: null,
    planAdminInfo: null,
    isCompanyInfoValid: false
  },
  reducers: {
    // setCompanyInfo
    setCompanyInfoRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setCompanyInfoSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    setCompanyInfoFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    updateCompanyInfoForm: (state, { payload }) => {
      state.companyInfo = payload;
    },
    updatePlanAdminForm: (state, { payload }) => {
      state.planAdminInfo = payload;
    },
    setCompanyInfoValidity: (state, { payload }) => {
      state.isCompanyInfoValid = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

const buildSignupRequestUrl = () => {
  const isPlatform = window.localStorage.getItem('isPlatform');
  let companyType = window.localStorage.getItem('companyType');

  if (!companyType) {
    companyType = isPlatform === 'true' ? 'AFFINITY' : 'DEFAULT';
  }

  const queryParams = {
    isPlatform: isPlatform === 'true' ? 'true' : 'false',
    companyType
  };

  const queryString = Object.keys(queryParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

  return `/insurance/rh/companies/signup?${queryString}`;
};

export const setCompanyInfo = (info) => async (dispatch) => {
  dispatch(actions.setCompanyInfoRequest());

  const body = {
    name: info.name,
    email: info.email,
    address: {
      country: info.country,
      city: '',
      state: '',
      line1: '',
      line2: '',
      zipCode: ''
    },
    hasCompletedCompany: false,
    consentToTermsAndConditions: true,
    referenceID: localStorage.getItem('referenceID') ?? null,
    campaignSlug: localStorage.getItem('campaign') ?? null,
    consentToMarketing: info.consentToMarketing,
    planAdministrator: {
      sameAddress: true
    }
  };

  try {
    const response = await post(buildSignupRequestUrl(), body);
    pushToJWT({
      companyID: response.companyID
    });
    dispatch(
      updatePlanholderInfo({
        role: 'owner',
        isCompanyOwner: true
      })
    );
    dispatch(
      updateCompanyInfo({
        companyID: response.companyID
      })
    );
    dispatch(actions.setCompanyInfoSuccess());
  } catch (error) {
    dispatch(actions.setCompanyInfoFailure(error));
  }
};

export const updateCompanyInfoForm = (info) => (dispatch) => {
  dispatch(actions.updateCompanyInfoForm(info));
};

export const updatePlanAdminForm = (info) => (dispatch) => {
  dispatch(actions.updatePlanAdminForm(info));
};

export const setCompanyInfoValidity = (isValid) => (dispatch) => {
  dispatch(actions.setCompanyInfoValidity(isValid));
};
