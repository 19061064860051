import { createSlice } from '@reduxjs/toolkit';
import { post } from 'api/fetch';
import { updatePlanholderInfo } from 'state/planholder';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'memberActivation/step2',
  initialState: {
    isLoading: false,
    error: null,
    loadingDependantID: null,
    isValid: false
  },
  reducers: {
    // addDependant
    addDependantRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    addDependantSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    addDependantFailure: (state, { payload }) => {
      state.isLoading = true;
      state.error = payload;
    },
    // deleteDependant
    deleteDependantRequest: (state, { payload }) => {
      state.loadingDependantID = payload;
      state.error = null;
    },
    deleteDependantSuccess: (state) => {
      state.loadingDependantID = null;
      state.error = null;
    },
    deleteDependantFailure: (state, { payload }) => {
      state.loadingDependantID = null;
      state.error = payload;
    },
    // deleteDependant
    editDependantRequest: (state, { payload }) => {
      state.loadingDependantID = payload;
      state.error = null;
    },
    editDependantSuccess: (state) => {
      state.loadingDependantID = null;
      state.error = null;
    },
    editDependantFailure: (state, { payload }) => {
      state.loadingDependantID = null;
      state.error = payload;
    },
    setValidity: (state, { payload }) => {
      state.isValid = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const addDependant = (info, schoolCertificate) => async (dispatch) => {
  dispatch(actions.addDependantRequest());

  const body = {
    add: [{ ...info }]
  };

  try {
    const response = await post('/insurance/rh/plan-holders/dependant', body);
    if (schoolCertificate) {
      const formData = new FormData();
      formData.append('identificationFile', schoolCertificate);
      const dependant = response.dependants.find(
        (d) => d.firstName === info.firstName && d.lastName === info.lastName
      );
      await post(
        `/insurance/rh/plan-holders/school-certificate?dependantID=${dependant.dependantID}`,
        formData
      );
    }
    await dispatch(updatePlanholderInfo({ dependants: response.dependants }));
    dispatch(actions.addDependantSuccess());
  } catch (error) {
    dispatch(actions.addDependantFailure(error));
  }
};

export const deleteDependant = (id) => async (dispatch, useState) => {
  if (useState().remoteHealth.memberActivation.step2.loadingDependantID) return;

  dispatch(actions.deleteDependantRequest(id));

  const body = {
    delete: [{ dependantID: id }]
  };

  try {
    const response = await post('/insurance/rh/plan-holders/dependant', body);
    await dispatch(updatePlanholderInfo({ dependants: response.dependants }));
    dispatch(actions.deleteDependantSuccess());
  } catch (error) {
    dispatch(actions.deleteDependantFailure(error));
  }
};

export const editDependant = (info, schoolCertificate) => async (dispatch, useState) => {
  if (useState().remoteHealth.memberActivation.step2.loadingDependantID) return;

  dispatch(actions.editDependantRequest(info.dependantID));

  const body = {
    edit: [{ ...info }]
  };

  try {
    const response = await post('/insurance/rh/plan-holders/dependant', body);
    if (schoolCertificate) {
      const formData = new FormData();
      formData.append('identificationFile', schoolCertificate);
      await post(
        `/insurance/rh/plan-holders/school-certificate?dependantID=${info.dependantID}`,
        formData
      );
    }
    await dispatch(updatePlanholderInfo({ dependants: response.dependants }));
    dispatch(actions.editDependantSuccess());
  } catch (error) {
    dispatch(actions.editDependantFailure(error));
  }
};

export const setValidity = (isValid) => (dispatch) => {
  dispatch(actions.setValidity(isValid));
};
