import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { get } from 'api/fetch';

// ================== Types ================== //

export type PlanType =
  | 'AFFINITY_STANDARD'
  | 'AFFINITY_PREMIUM'
  | 'AFFINITY_MATERNITY'
  | 'AFFINITY_ESSENTIAL';

export type Plan = {
  planId: number;
  companyPlanId: number;
  name: string;
  displayName: string;
  descriptionShort: string;
  descriptionLong: string;
  deductible: number;
  providesExtendedAreaOfCoverage: boolean;
  type: PlanType;
  addons: Addon[];
  pricing: Pricing[];
  annualMaximumAmount: number;
};

export type AddonType = 'VISION' | 'SCREENING' | 'OUTPATIENT' | 'DENTAL' | 'MATERNITY';

export type Addon = {
  addonId: number;
  type: AddonType;
  maxCoverageAmountPerPeriod: number;
  coInsurancePercentage: number;
  descriptionShort: string;

  // TODO: fix typo on backend if possible
  descrptionLong: string;
};

type Pricing = {
  lowerAgeLimit: number;
  upperAgeLimit: number;
  pricingPerMember: number;
  validFrom: Date;
};

type State = {
  isLoading: boolean;
  dependantConfig: null;
  error: null;
  plans?: Plan[] | null;
};

// ================== Reducers ================== //

const initialState: State = {
  isLoading: false,
  dependantConfig: null,
  error: null,
  plans: null
};

const slice = createSlice({
  name: 'remote_health/onboarding/plans',
  initialState,
  reducers: {
    fetchPlansRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchPlanSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.plans = payload || null;
    },
    fetchPlanFailure: (state, { payload }) => {
      state.isLoading = false;
      state.plans = null;
      state.error = payload;
    }
  }
});

export const { actions } = slice;

// ================== Actions ================== //

export const fetchPlansWithPricing = () => async (dispatch: Dispatch) => {
  dispatch(actions.fetchPlansRequest());

  try {
    const response = await get(
      `/member/onboarding/plans-with-pricing`,

      // Implicitly setting `redirectOnUnauthorized` to undefined (falsy) here
      // @ts-ignore
      {
        api: 'rh'
      }
    );
    dispatch(actions.fetchPlanSuccess(response));
  } catch (error) {
    dispatch(actions.fetchPlanFailure(error));
  }
};

export default slice.reducer;
