import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { get } from 'api/fetch';
import isEmpty from 'lodash/isEmpty';
import { COMPANY_STATUS_ORDER, CompanyStatus, SelfSignedCompany } from './consts';

export const slice = createSlice({
  name: 'companySignupStatus',
  initialState: {
    isLoading: false,
    isSelfSignedCompany: false,
    status: null as CompanyStatus,
    statusMap: new Map<Required<CompanyStatus>, boolean>(
      COMPANY_STATUS_ORDER.map((x) => [x, false])
    )
  },
  reducers: {
    fetchCompanySignupStatusRequest: (state) => {
      state.isLoading = true;
    },
    fetchCompanySignupStatusSuccess: (
      state,
      { payload }: { payload: SelfSignedCompany | undefined }
    ) => {
      state.isLoading = false;
      state.status = payload?.status;
      state.isSelfSignedCompany = !!state.status;
      state.statusMap = new Map(COMPANY_STATUS_ORDER.map((x) => [x, false]));

      if (state.status) {
        // eslint-disable-next-line no-restricted-syntax
        for (const x of COMPANY_STATUS_ORDER) {
          state.statusMap.set(x, true);
          if (x === state.status) break;
        }
      }
    },
    fetchCompanySignupStatusFailure: (state) => {
      state.isLoading = false;
    }
  }
});

const { actions } = slice;

export const fetchCompanyStatus = () => async (dispatch: Dispatch) => {
  dispatch(actions.fetchCompanySignupStatusRequest());
  try {
    const selfSignedCompanyRes = (await get('/company/signup/company-info', {
      api: 'rh',
      redirectOnUnauthorized: false
    })) as SelfSignedCompany | undefined;

    if (isEmpty(selfSignedCompanyRes)) {
      dispatch(actions.fetchCompanySignupStatusFailure());
      return;
    }

    dispatch(actions.fetchCompanySignupStatusSuccess(selfSignedCompanyRes));
  } catch (error) {
    dispatch(actions.fetchCompanySignupStatusFailure());
  }
};

export default slice.reducer;
