import { combineReducers } from 'redux';
import { mergeReducers } from 'state/mergeReducers';
import validation from './validation';
import details from '../Details/state';
import otherInsurance from '../OtherInsurance/state';
import summary from '../Summary/state';
import members from '../Members/state';

export default mergeReducers(
  validation,
  combineReducers({
    details,
    otherInsurance,
    summary,
    members
  })
);
