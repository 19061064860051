import { useEffect, type FC, type PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import useId from 'hooks/useId';

const getPortalDiv = (id: string) => {
  let div = document.getElementById(id);
  if (!div) {
    div = document.createElement('div');
    div.id = id;
    document.body.appendChild(div);
  }
  return div;
};

const AutoPortal: FC<PropsWithChildren> = ({ children }) => {
  const id = useId();

  // Get or create portal element based on id
  const portalDiv = getPortalDiv(id);

  // Clean up portal element after unmount
  useEffect(
    () => () => {
      // Wait for children to unmount first
      // It's not perfect, should be fine tho
      setTimeout(() => portalDiv.remove(), 1000);
    },
    [id]
  );

  // @ts-ignore wonky types
  return createPortal(children, portalDiv);
};

export default AutoPortal;
