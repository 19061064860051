import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post } from 'api/fetch';
import { initialize } from 'state/app';
import { createJWT } from 'auth';
import { fireSignupTrackingEvent } from 'api/analytics';
import history from 'routing/history';

export const checkValidLink = createAsyncThunk(
  'validSignupLink',
  async ({ token, email }) => {
    const response = await get(
      `/users/eligibleSignupLink?${new URLSearchParams({
        token,
        email
      })}`
    );
    return response;
  }
);

// ================== Reducers ================== //

const slice = createSlice({
  name: 'memberSignup',
  initialState: {
    alreadyActive: false,
    isLoading: false,
    error: null
  },
  reducers: {
    // signup
    signupRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    signupSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    signupFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  },
  extraReducers: {
    [checkValidLink.fulfilled]: (state, { payload }) => {
      state.alreadyActive = payload === 2;
      state.isLoading = false;
      state.error = payload === 0;
    },
    [checkValidLink.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.error = error?.message || true;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const signup = (email, password, activationToken) => async (dispatch) => {
  dispatch(actions.signupRequest());

  try {
    const response = await post('/insurance/rh/members/activate', {
      email,
      password,
      activationToken
    });
    await createJWT({
      userID: response.userID,
      accessToken: response.accessToken,
      companyID: response.companyID
    });
    await dispatch(initialize());
    dispatch(actions.signupSuccess());

    const affiliateID = localStorage.getItem('referenceID');
    fireSignupTrackingEvent(response.userID, 'email', affiliateID);

    history.push('/member');
  } catch (error) {
    dispatch(actions.signupFailure(error));
  }
};

export const signupAffinity = (email, password, token, referrer) => async (dispatch) => {
  dispatch(actions.signupRequest());

  try {
    const response = await post(
      '/rh/members/affinity/signup',
      {
        email,
        password,
        token,
        referrer
      },
      { api: 'rh' }
    );
    await createJWT({
      userID: response.userID,
      accessToken: response.accessToken,
      companyID: response.companyID
    });
    await dispatch(initialize());
    dispatch(actions.signupSuccess());

    const affiliateID = localStorage.getItem('referenceID');
    fireSignupTrackingEvent(response.userID, 'email', affiliateID);

    history.push('/member/affinity/registration/1');
  } catch (error) {
    dispatch(actions.signupFailure(error));
  }
};
