import { createSlice } from '@reduxjs/toolkit';
import { post } from 'api/fetch';
import history from 'routing/history';
import { updatePlanholderInfo } from 'state/planholder';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'memberActivation/step3',
  initialState: {
    isLoading: false,
    error: null
  },
  reducers: {
    // createMemberContract
    createMemberContractRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    createMemberContractSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
      state.isValid = true;
    },
    createMemberContractFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const createMemberContract = () => async (dispatch) => {
  dispatch(actions.createMemberContractRequest());

  try {
    await post(`/member/onboarding/finish`, null, { api: 'rh' });
    dispatch(actions.createMemberContractSuccess());
    const updatedPlanHolderStatus = {
      onboardingStatus: 'COMPLETED'
    };

    updatePlanholderInfo(updatedPlanHolderStatus)(dispatch);
    history.push('/member/activation/4');
  } catch (error) {
    dispatch(actions.createMemberContractFailure(error));
  }
};
