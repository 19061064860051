import feather from 'assets/img/logos/feather-white.png';
import featherDark from 'assets/img/logos/feather-white-dark.png';
import featherBlue from 'assets/img/logos/feather-blue.png';
import { SpinnerContainer, Logo } from './styled';

const Spinner = ({ variant, size, className }: Props) => {
  const getLoadingLogo = () => {
    switch (variant) {
      case 'dark':
        return featherDark;
      case 'blue':
        return featherBlue;
      default:
        return feather;
    }
  };

  return (
    <SpinnerContainer className={className}>
      <Logo src={getLoadingLogo()} alt="loading..." size={size} />
    </SpinnerContainer>
  );
};

type Props = {
  variant: string;
  size?: string;
  className?: string;
};

export default Spinner;
