import { type FC, type PropsWithoutRef } from 'react';
import styled from 'styled-components';

type CloseButtonProps = PropsWithoutRef<JSX.IntrinsicElements['button']>;

const Wrapper = styled.button`
  position: relative;
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  cursor: pointer;

  & > svg {
    display: block;
    margin: 0;

    & > path {
      stroke: var(--toast-text);
    }
  }
`;

const CloseButton: FC<CloseButtonProps> = (props) => {
  return (
    <Wrapper {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 9L9 19M9 9L19 19"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
};

export default CloseButton;
