import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { path, assocPath, isNil, mergeDeepRight } from 'ramda';
import createReducer from './reducer';

const persistentStorePaths = [
  ['app', 'facebookLogin'],
  ['app', 'user'],
  ['app', 'geo']
];

const saveState = (value = []) => {
  localStorage.setItem('reduxStore', JSON.stringify(value));
};

const loadState = (reducer) => {
  const initialState = reducer({}, '');
  const savedValue = JSON.parse(localStorage.getItem('reduxStore')) || [];
  const incomingState = savedValue.reduce((acc, { storePath, value }) => {
    return assocPath(storePath, value, acc);
  }, {});
  return mergeDeepRight(initialState, incomingState);
};

export default function configureAppStore() {
  const reducer = createReducer();
  const preloadedState = loadState(reducer);

  const store = configureStore({
    middleware: [...getDefaultMiddleware({ serializableCheck: false })],
    reducer,
    preloadedState
  });

  store.subscribe(() => {
    const state = store.getState();
    const saveValue = persistentStorePaths
      .map((storePath) => ({
        value: path(storePath, state),
        storePath
      }))
      .filter((x) => !isNil(x.value));
    saveState(saveValue);
  });

  return store;
}
