import COUNTRIES from 'assets/data/countries';
import Fuse from 'fuse.js';

export const getFullCountry = (countryCode) =>
  countryCode && COUNTRIES.filter((c) => c.code === countryCode)[0];

export const getDependantContractPrice = (dependant) => {
  let contractPrice = dependant?.contracts?.[0]?.insuranceCost || 0;
  contractPrice = dependant?.companyMonthlyCost + dependant?.memberMonthlyCost;

  return dependant.contracts?.length > 0
    ? `${contractPrice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}`
    : '-';
};

export const getMemberContractPrice = (member) => {
  const cost = member.contractCost;
  return cost && member.status !== 'canceled' && member.status !== 'expired'
    ? `${cost.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}`
    : '-';
};

export const memberCostWithoutDependantsLocal = (member) => {
  const totalDependantCost =
    member?.dependants?.reduce(
      (acc, dependant) =>
        acc + (dependant?.companyMonthlyCost + dependant?.memberMonthlyCost),
      0
    ) || 0;

  const memberCost = member.contractCost - totalDependantCost;

  return memberCost.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formatPrice = (price) =>
  price != null
    ? `${price.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })}`
    : '-';

export const createFullMemberName = (member) =>
  member.firstName && member.lastName
    ? member.firstName.concat(' ', member.lastName)
    : '-';

export const getTodaysDate = () => {
  const currentDate = new Date();
  return `${currentDate.getFullYear()}-${
    currentDate.getMonth() + 1
  }-${currentDate.getDate()}`;
};

export const sortMembers = (members, sortDirection, sortKey, groupsList) => {
  const getGroupName = (groupId) =>
    groupsList?.find((group) => group.groupId === groupId)?.name || groupId;

  const sortKeyCallbacks = {
    status: (a, b) =>
      sortDirection === 'asc'
        ? a.status.localeCompare(b.status)
        : b.status.localeCompare(a.status),
    lastName: (a, b) =>
      sortDirection === 'asc'
        ? `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`)
        : `${b.lastName} ${b.firstName}`.localeCompare(`${a.lastName} ${a.firstName}`),
    groupId: (a, b) =>
      sortDirection === 'asc'
        ? getGroupName(a.groupId).localeCompare(getGroupName(b.groupId))
        : getGroupName(b.groupId).localeCompare(getGroupName(a.groupId)),
    email: (a, b) =>
      sortDirection === 'asc'
        ? a.email.localeCompare(b.email)
        : b.email.localeCompare(a.email),
    dependantCount: (a, b) =>
      sortDirection === 'asc'
        ? a.dependantCount - b.dependantCount
        : b.dependantCount - a.dependantCount,
    planName: (a, b) =>
      sortDirection === 'asc'
        ? a.planName.localeCompare(b.planName)
        : b.planName.localeCompare(a.planName),
    contractCost: (a, b) =>
      sortDirection === 'asc'
        ? a.contractCost - b.contractCost
        : b.contractCost - a.contractCost,
    locationCountry: (a, b) =>
      sortDirection === 'asc'
        ? a.locationCountry.localeCompare(b.locationCountry)
        : b.locationCountry.localeCompare(a.locationCountry)
  };

  members.sort((a, b) => {
    if (a[sortKey] && !b[sortKey]) return -1;
    if (!a[sortKey] && b[sortKey]) return 1;
    if (a[sortKey] && b[sortKey]) {
      return sortKeyCallbacks[sortKey](a, b);
    }
    return 0;
  });
};

export const searchMembers = (members, searchValue) => {
  const fuseSearchOptions = {
    shouldSort: true,
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['firstName', 'lastName', 'email', 'locationCountry', 'policyID', 'userID']
  };

  if (searchValue && searchValue.length > 0) {
    const fuse = new Fuse(members, fuseSearchOptions);
    return fuse.search(searchValue);
  }
  return members;
};

/** Returns only the members that match all the filters */
export const filterMembers = (members, activeFilters) => {
  const filterKeyCallbacks = {
    fullLocationCountry: (memberValue, value) =>
      memberValue === getFullCountry(value).name,
    groupId: (memberValue, value) => memberValue === value,
    status: (memberValue, value) => memberValue?.toLowerCase() === value.toLowerCase()
  };

  return members.filter((member) => {
    let matchesAllFilters = true;
    Object.entries(activeFilters).forEach(([key, value]) => {
      if (value) {
        matchesAllFilters =
          matchesAllFilters && filterKeyCallbacks[key](member[key], value);
      }
    });
    return matchesAllFilters;
  });
};

const filterCostsForMember = (member, memberCosts) => {
  return memberCosts?.filter((costs) => costs?.member === member?.userID) || [];
};

const calculateAutoPricedMonthlyTotal = (allCostsForMember) => {
  return allCostsForMember.reduce(
    (acc, costDetail) =>
      acc + (costDetail?.companyMonthlyCost || 0) + (costDetail?.memberMonthlyCost || 0),
    0
  );
};

const calculateTotalMemberContribution = (allCostsForMember) => {
  return allCostsForMember.reduce(
    (acc, costDetail) => acc + (costDetail?.memberMonthlyCost || 0),
    0
  );
};

const calculateTotalCompanyContribution = (member, allCostsForMember) => {
  return allCostsForMember.length !== 0
    ? allCostsForMember.reduce(
        (acc, costDetail) => acc + (costDetail?.companyMonthlyCost || 0),
        0
      )
    : member?.contractCost || 0;
};

const calculateTotalDependantCost = (member) =>
  member?.dependants?.reduce(
    (acc, dependant) => acc + (dependant?.contracts?.[0]?.insuranceCost || 0),
    0
  ) || 0;

export const totalDependantCostLocal = (member) => {
  const dependants = member?.dependants ?? [];
  const totalCost = dependants.reduce((acc, dependant) => {
    const companyCost = dependant?.companyMonthlyCost || 0;
    const memberCost = dependant?.memberMonthlyCost || 0;
    return acc + companyCost + memberCost;
  }, 0);

  return totalCost.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
};

const getDependantWithCosts = (dependant, allCostsForMember) => {
  const dependantCost = allCostsForMember?.filter(
    (costDetail) => costDetail.dependant === dependant.dependantID
  );

  return {
    ...dependant,
    companyMonthlyCost: dependantCost?.[0]?.companyMonthlyCost,
    memberMonthlyCost: dependantCost?.[0]?.memberMonthlyCost
  };
};

const parseMembers = (membersArr, memberCosts) => {
  return (
    membersArr &&
    membersArr.map((member) => {
      const allCostsForMember = filterCostsForMember(member, memberCosts);
      const policyHolderCosts = allCostsForMember.filter((c) => !c.dependant);

      const autoPricedMonthlyTotal = calculateAutoPricedMonthlyTotal(allCostsForMember);
      const manuallyPricedTotalYearlyCostIncludingDependants = member?.contractCost || 0;
      const totalMonthlyCost =
        allCostsForMember.length !== 0
          ? autoPricedMonthlyTotal
          : manuallyPricedTotalYearlyCostIncludingDependants;

      const totalMemberContribution = calculateTotalMemberContribution(allCostsForMember);
      const totalCompanyContribution = calculateTotalCompanyContribution(
        member,
        allCostsForMember
      );
      const totalDependantCost = calculateTotalDependantCost(member);

      return {
        ...member,
        dependants: member?.dependants?.map((dependant) =>
          getDependantWithCosts(dependant, allCostsForMember)
        ),
        isAutoPriced: allCostsForMember.length !== 0,
        isSelected: false,
        monthlyCost: totalMonthlyCost,
        totalMemberMonthlyContribution: totalMemberContribution,
        totalCompanyMonthlyContribution: totalCompanyContribution,
        memberMonthlyContribution: policyHolderCosts[0]?.memberMonthlyCost || 0,
        companyMonthlyContribution: policyHolderCosts[0]?.companyMonthlyCost || 0,
        contractCost:
          member?.status === 'canceled' || member?.status === 'expired'
            ? 0
            : totalMonthlyCost,
        contractCostWithoutDependants: member?.contractCost
          ? member?.contractCost - (totalDependantCost || 0)
          : '-',
        fullLocationCountry: member?.locationCountry
          ? getFullCountry(member?.locationCountry)?.name
          : null,
        planName: member.plan?.displayName || member.comapnyPlan?.displayName || '-'
      };
    })
  );
};

export const mapMembersToCosts = (members, memberCosts) =>
  parseMembers(members, memberCosts);

export const isCompanyContractHybrid = (companyContract) => {
  return (
    (companyContract.companyBillingAmount && companyContract.companyBillingAmount > 0) ||
    (companyContract.planHolderBillingAmount &&
      companyContract.planHolderBillingAmount > 0) ||
    (companyContract.companyBillingPercentage &&
      companyContract.companyBillingPercentage !== 100) ||
    (companyContract.planHolderBillingPercentage &&
      companyContract.planHolderBillingPercentage !== 100) ||
    false
  );
};

export const relationshipTypes = {
  CHILD: 'Child',
  PARTNER: 'Partner/Spouse',
  PARENT: 'Parent'
};
