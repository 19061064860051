import { createSlice } from '@reduxjs/toolkit';
import { FullStory } from '@fullstory/browser';
import { post } from 'api/fetch';
import { initialize } from 'state/app';
import { createJWT } from 'auth';
import { fireLoginTrackingEvent } from 'api/analytics';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/login',
  initialState: {
    isLoading: false,
    error: null
  },
  reducers: {
    // login
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    loginFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    clearError: (state) => {
      state.error = null;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const login = (email, password) => async (dispatch) => {
  dispatch(actions.loginRequest());
  try {
    const response = await post(
      '/users/login',
      { email, password },
      { redirectOnUnauthorized: false }
    );
    await createJWT({
      userID: response.userID,
      accessToken: response.accessToken,
      companyID: response.companyID
    });
    await dispatch(initialize());
    fireLoginTrackingEvent(response.userID, 'email');
    FullStory('setIdentity', response.userID, {
      displayName: response.name,
      email
    });
    dispatch(actions.loginSuccess(response));
  } catch (error) {
    dispatch(actions.loginFailure(error));
  }
};

export const clearError = () => (dispatch) => {
  dispatch(actions.clearError());
};
