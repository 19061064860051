import axios from 'axios';
import { decodeJWT, clearJWT } from 'auth';
import { backendApiUrl, rhApiUrl } from '.';

// ================== Util ================== //

export function handleError(
  method,
  error,
  url,
  options = { redirectOnUnauthorized: true }
) {
  let response = {};

  if (
    error &&
    error.response?.status === 401 &&
    options.redirectOnUnauthorized === true
  ) {
    clearJWT();
    window.location.reload();
  }

  if (error.response) {
    response = {
      statusCode: error.response.statusCode,
      type: error.response.type,
      ...error.response.data
    };
  }

  return Promise.reject(response);
}

export function createRequest({
  apiUrl = backendApiUrl,
  path,
  data,
  method,
  customHeaders = {},
  options,
  responseType
}) {
  const getUrl = () => {
    switch (options?.api) {
      case 'rh':
        return rhApiUrl + path;
      case 'backend':
      default:
        return apiUrl + path;
    }
  };
  const url = getUrl();
  const accessToken = decodeJWT('accessToken');
  const userID = decodeJWT('userID');
  const companyID = decodeJWT('companyID');

  const headers = {
    'Content-Type': 'application/json',
    ...customHeaders
  };

  if (accessToken) {
    headers.accessToken = accessToken;
  }

  if (userID) {
    headers.userID = userID;
  }

  if (companyID) {
    headers.companyID = companyID;
  }

  return {
    url,
    headers,
    method,
    data,
    responseType
  };
}

// ================== Public ================== //

const getNoCacheUrl = (url) => {
  if (url.includes('?')) {
    return `${url}&nocache=${new Date().getTime()}`;
  }
  return `${url}?nocache=${new Date().getTime()}`;
};

export function get(
  url,
  options = {
    redirectOnUnauthorized: true,
    api: 'backend'
  },
  customHeaders,
  nocache = false
) {
  const request = createRequest({
    path: nocache ? getNoCacheUrl(url) : url,
    method: 'get',
    customHeaders,
    options
  });
  return axios(request)
    .then((response) => response.data)
    .catch((error) => handleError('GET', error, url, options));
}

export function post(
  url,
  data = {},
  options = { redirectOnUnauthorized: true, api: 'backend' },
  customHeaders,
  responseType = 'application/json'
) {
  return axios(
    createRequest({
      path: url,
      data,
      method: 'POST',
      customHeaders,
      options,
      responseType
    })
  )
    .then((response) => response.data)
    .catch((error) => handleError('POST', error, url, options));
}

export function postMultipart(url, data = {}, options = { api: 'backend' }) {
  const body = Object.keys(data).reduce((f, key) => {
    f.append(key, data[key]);
    return f;
  }, new FormData());
  return axios(createRequest({ path: url, data: body, method: 'POST', options }))
    .then((response) => response.data)
    .catch((error) => handleError('POST_MULTIPART', error, url, options));
}

export function put(
  url,
  data = {},
  options = { redirectOnUnauthorized: true, api: 'backend' },
  customHeaders,
  responseType = 'application/json'
) {
  return axios(
    createRequest({
      path: url,
      data,
      method: 'PUT',
      customHeaders,
      options,
      responseType
    })
  )
    .then((response) => response.data)
    .catch((error) => handleError('PUT', error, url));
}

export function del(url, data = {}, options = { api: 'backend' }) {
  return axios(createRequest({ path: url, data, method: 'DELETE', options }))
    .then((response) => response.data)
    .catch((error) => handleError('DELETE', error, url));
}
