import styled, { css } from 'styled-components';
import { colors } from 'style/theme';

export enum LinkVariants {
  PRIMARY,
  WHITE,
  BLACK
}

type LinkProps = {
  underline?: boolean;
  variant?: LinkVariants;
};

const getLinkColor = (variant?: LinkVariants) => {
  switch (variant) {
    case LinkVariants.WHITE:
      return colors.white;
    case LinkVariants.BLACK:
      return colors.black;
    case LinkVariants.PRIMARY:
    default:
      return colors.indigo;
  }
};

export const Link = styled.a<LinkProps>`
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  color: ${({ variant }) => getLinkColor(variant)};
`;

const noMargin = css`
  margin-top: 0;
  margin-bottom: 0;
`;

const font = css`
  font-family: 'Helvetica Now', 'Helvetica', Arial, sans-serif;
`;

// TODO: Add rest of display text styles from figma
// Export text styles to enable building of dynamic text components
// Example: src/views/Home/components/Hero/styled.ts - Heading-component

/**
 * @deprecated use `display1Style` from `@safetywing-tech/company-ui`
 */
export const display1Style = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 80px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `Display1` from `@safetywing-tech/company-ui`
 */
export const Display1 = styled.h1`
  ${display1Style};
`;

/**
 * @deprecated use `display2Style` from `@safetywing-tech/company-ui`
 */
export const display2Style = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `Display2` from `@safetywing-tech/company-ui`
 */
export const Display2 = styled.h1`
  ${display2Style};
`;

/**
 * @deprecated use `display3Style` from `@safetywing-tech/company-ui`
 */
export const display3Style = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `Display3` from `@safetywing-tech/company-ui`
 */
export const Display3 = styled.h3`
  ${display3Style};
`;

// TODO: Add rest of headings from figma

/**
 * @deprecated use `heading3Style` from `@safetywing-tech/company-ui`
 */
export const heading3Style = css`
  ${font};
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `Heading3` from `@safetywing-tech/company-ui`
 */
export const Heading3 = styled.h3`
  ${heading3Style};
`;

// TODO: Add rest of titles from figma

/**
 * @deprecated use `titleXlStyle` from `@safetywing-tech/company-ui`
 */
export const titleXlStyle = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `TitleXl` from `@safetywing-tech/company-ui`
 */
export const TitleXl = styled.h3`
  ${titleXlStyle};
`;

/**
 * @deprecated use `titleLStyle` from `@safetywing-tech/company-ui`
 */
export const titleLStyle = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `TitleL` from `@safetywing-tech/company-ui`
 */
export const TitleL = styled.h3`
  ${titleLStyle};
`;

/**
 * @deprecated use `titleMStyle` from `@safetywing-tech/company-ui`
 */
export const titleMStyle = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `TitleM` from `@safetywing-tech/company-ui`
 */
export const TitleM = styled.h5`
  ${titleMStyle};
`;

/**
 * @deprecated use `titleSStyle` from `@safetywing-tech/company-ui`
 */
export const titleSStyle = css`
  ${font};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  ${noMargin};
`;

/**
 * @deprecated use `TitleS` from `@safetywing-tech/company-ui`
 */
export const TitleS = styled.h5`
  ${titleSStyle};
`;

// TODO: Add rest of paragraphs from figma

/**
 * @deprecated use `paragraphLStyle` from `@safetywing-tech/company-ui`
 */
export const paragraphLStyle = css`
  ${font};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `ParagraphL` from `@safetywing-tech/company-ui`
 */
export const ParagraphL = styled.p`
  ${paragraphLStyle};
`;

/**
 * @deprecated use `paragraphMStyle` from `@safetywing-tech/company-ui`
 */
export const paragraphMStyle = css`
  ${font};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `ParagraphM` from `@safetywing-tech/company-ui`
 */
export const ParagraphM = styled.p`
  ${paragraphMStyle};
`;

/**
 * @deprecated use `paragraphSStyle` from `@safetywing-tech/company-ui`
 */
export const paragraphSStyle = css`
  ${font};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `ParagraphS` from `@safetywing-tech/company-ui`
 */
export const ParagraphS = styled.p`
  ${paragraphSStyle};
`;

/**
 * @deprecated use `paragraphXsStyle` from `@safetywing-tech/company-ui`
 */
export const paragraphXsStyle = css`
  ${font};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `ParagraphXs` from `@safetywing-tech/company-ui`
 */
export const ParagraphXs = styled.p`
  ${paragraphXsStyle};
`;

// TODO: Add rest of the labels from figma

/**
 * @deprecated use `labelXlStyle` from `@safetywing-tech/company-ui`
 */
export const labelXlStyle = css`
  ${font};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `LabelXl` from `@safetywing-tech/company-ui`
 */
export const LabelXl = styled.span`
  ${labelXlStyle};
`;

/**
 * @deprecated use `labelMStyle` from `@safetywing-tech/company-ui`
 */
export const labelMStyle = css`
  ${font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `LabelM` from `@safetywing-tech/company-ui`
 */
export const LabelM = styled.span`
  ${labelMStyle};
`;

/**
 * @deprecated use `labelSStyle` from `@safetywing-tech/company-ui`
 */
export const labelSStyle = css`
  ${font};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `LabelS` from `@safetywing-tech/company-ui`
 */
export const LabelS = styled.span`
  ${labelSStyle};
`;

/**
 * @deprecated use `labelXsStyle` from `@safetywing-tech/company-ui`
 */
export const labelXsStyle = css`
  ${font};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  ${noMargin};
`;

/**
 * @deprecated use `LabelXs` from `@safetywing-tech/company-ui`
 */
export const LabelXs = styled.span`
  ${labelXsStyle};
`;

export const AccentText = styled.span<{ colorName: keyof typeof colors }>`
  color: ${(p) => colors[p.colorName]};
`;
