import { createSlice } from '@reduxjs/toolkit';
import { del, get, post, put } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'explorer',
  initialState: {
    error: null,
    errorMessage: null,
    isLoading: false,
    isLoadingContract: false,
    isLoadingDraftMembers: false,
    contract: null,
    draftMembers: null,
    draftMembersFailed: null
  },
  reducers: {
    // Fetch draft members
    fetchDraftMembersRequest: (state) => {
      state.isLoading = true;
      state.isLoadingDraftMembers = true;
    },
    fetchDraftMembersSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
      state.draftMembers = payload.reverse();
    },
    fetchDraftMembersFailure: (state) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
      state.error = true;
      state.errorMessage = 'Unable to fetch draft members';
    },
    // Create new draft members
    addDraftMembersRequest: (state) => {
      state.isLoading = true;
      state.isLoadingDraftMembers = true;
    },
    addDraftMemberSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
      state.draftMembers = [payload, ...(state.draftMembers || [])];
    },
    addDraftMemberFailure: (state, { payload }) => {
      state.error = true;
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
      state.draftMembersFailed = [...(state.draftMembersFailed || []), payload];
      state.errorMessage = `Unable to add the following members: ${state.draftMembersFailed.join(
        ', '
      )}`;
    },
    // Fetch company contract
    fetchCompanyContractRequest: (state) => {
      state.isLoading = true;
      state.isLoadingContract = true;
    },
    fetchCompanyContractSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingContract = false;
      state.contract = payload;
    },
    fetchCompanyContractFailure: (state) => {
      state.isLoading = false;
      state.isLoadingContract = false;
      state.error = true;
      state.errorMessage = 'Unable to fetch company contract';
    },
    // Delete draft member
    deleteDraftMemberRequest: (state) => {
      state.isLoading = true;
      state.isLoadingDraftMembers = true;
    },
    deleteDraftMemberSuccess: (state, { payload }) => {
      state.draftMembers = state.draftMembers.filter((m) => m.id !== payload);
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },
    deleteDraftMemberFailure: (state) => {
      state.isLoading = false;
      state.isLoadingDraftMembers = false;
    },
    // Update draft member
    updateDraftMemberRequest: (state) => {
      state.isLoading = true;
    },
    updateDraftMemberSuccess: (state, { payload }) => {
      state.draftMembers = state.draftMembers.map((m) =>
        m.id === payload.id ? payload : m
      );
      state.isLoading = false;
    },
    updateDraftMemberFailure: (state) => {
      state.isLoading = false;
    }
  }
});

export default slice.reducer;

const { actions } = slice;

// ================== Actions ================== //

export const fetchDraftMembers = (companyContractId) => async (dispatch) => {
  dispatch(actions.fetchDraftMembersRequest());
  try {
    const response = await get(
      `/rh/draftMember/all?companyContractId=${companyContractId}`,
      { api: 'rh' }
    );
    dispatch(actions.fetchDraftMembersSuccess(response));
  } catch (error) {
    dispatch(actions.fetchDraftMembersFailure(error));
  }
};

export const addEmptyDraftMember = (companyContractId) => async (dispatch) => {
  dispatch(actions.addDraftMembersRequest());
  try {
    const draftMember = await post(
      `/rh/draftMember`,
      { companyContractId },
      { redirectOnUnauthorized: true, api: 'rh' }
    );
    dispatch(actions.addDraftMemberSuccess(draftMember));
  } catch {
    dispatch(actions.addDraftMemberFailure());
  }
};

export const fetchCompanyContract = () => async (dispatch) => {
  dispatch(actions.fetchCompanyContractRequest());
  try {
    const response = await get(`/rh/contracts/company`, {
      api: 'rh'
    });
    dispatch(actions.fetchCompanyContractSuccess(response));
  } catch (error) {
    dispatch(actions.fetchCompanyContractFailure(error));
  }
};

export const deleteDraftMember = (id) => async (dispatch) => {
  dispatch(actions.deleteDraftMemberRequest);
  try {
    await del(`/rh/draftMember?id=${id}`, null, {
      redirectOnUnauthorized: true,
      api: 'rh'
    });
    dispatch(actions.deleteDraftMemberSuccess(id));
  } catch (error) {
    dispatch(actions.deleteDraftMemberFailure(error, id));
  }
};

export const updateDraftMember = (memberId, changes) => async (dispatch) => {
  dispatch(actions.updateDraftMemberRequest);
  try {
    const updatedDraftMember = await put(`/rh/draftMember?id=${memberId}`, changes, {
      redirectOnUnauthorized: true,
      api: 'rh'
    });
    dispatch(actions.updateDraftMemberSuccess(updatedDraftMember));
  } catch (error) {
    dispatch(actions.updateDraftMemberFailure(error));
  }
};
