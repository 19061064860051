import { lazy, Suspense } from 'react';
import 'intersection-observer';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import * as Sentry from '@sentry/react';
import configureStore from 'state';

import remoteHealthTheme from 'style/theme';
import GlobalStyle from 'style/globalStyle';
import { init as initApm } from '@elastic/apm-rum';
import history from 'routing/history';
import FullStorySwitcher from 'components/FullStorySwitcher';
import LoadingOverlay from 'components/LoadingOverlay';
import Toaster, { ToasterProvider } from 'components/Gen.2/Toaster';

const Footer = lazy(() => import('components/Footer'));
const Navigation = lazy(() => import('components/Navigation'));
const Auth = lazy(() => import('components/Auth'));
const ErrorPage = lazy(() => import('components/Error'));
const Routes = lazy(() => import('routing'));

smoothscroll.polyfill();

if (process.env.NODE_ENV !== 'development') {
  initApm({
    serviceName: 'remote-health',
    serverUrl: 'https://safetywing.apm.europe-west1.gcp.cloud.es.io',
    serviceVersion: '',
    environment: process.env.REACT_APP_ENVIRONMENT
  });
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0dc2f148c4d04908b9ae4e25e8a09d38@o479420.ingest.sentry.io/4505278507057152',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['safetywing.com/remote-health']
      })
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 1.0
  });
}

const store = configureStore();

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorPage}>
      <ThemeProvider theme={remoteHealthTheme}>
        <GlobalStyle />
        <ReduxProvider store={store}>
          <ToasterProvider>
            <Suspense fallback={<LoadingOverlay isLoading variant="darkFull" />}>
              <Auth>
                <Router history={history} basename="/remote-health">
                  <FullStorySwitcher
                    pathOrgIdMap={{
                      '/': 'o-1W9JSN-na1',
                      '/company': 'o-1W9HVZ-na1'
                    }}
                  />
                  <Navigation />
                  <Routes />
                  <Switch>
                    {/* Hide footer on rh home bc of parallax */}
                    <Route exact path="/" />
                    <Route path="*">
                      <Footer />
                    </Route>
                  </Switch>

                  {/* TODO: rm this and use Gen.2/Modal everywhere~ */}
                  <div id="modal-portal" />
                </Router>
              </Auth>
            </Suspense>

            {/* Toaster for toasts 🍞 */}
            <Toaster />
          </ToasterProvider>
        </ReduxProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
