import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'api/fetch';
import { Claim } from './types';

export const fetchClaim = createAsyncThunk<Claim, string>(
  'remote_health/claim/fetchClaim',
  async (claimId: string) => {
    const claim = await get(`/claim/${claimId}`, {
      api: 'rh',
      redirectOnUnauthorized: true
    });
    return claim;
  }
);
