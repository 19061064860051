import { createSlice } from '@reduxjs/toolkit';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'companyPurchase',
  initialState: {
    currentStep: '',
    validation: {
      details: false,
      'other-insurance': false,
      summary: false,
      members: false
    }
  },
  reducers: {
    setStepValidity: (state, { payload }) => {
      state.validation[payload.step] = payload.isValid;
    },
    setValidity: (state, { payload }) => {
      state.validation = payload;
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const setStepValidity = (step, isValid) => (dispatch) => {
  dispatch(actions.setStepValidity({ step, isValid }));
};

export const setValidity = (validation) => (dispatch) => {
  dispatch(actions.setValidity(validation));
};

export const setCurrentStep = (step) => (dispatch) => {
  dispatch(actions.setCurrentStep(step));
};
