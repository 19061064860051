type Nullable<T> = T | null;

/**
 * @deprecated Use type from `rhApiSchema` -> components['schemas']['PlanResponse']
 */
export type PlanType = {
  companyPlanId: number;
  planId: number;
  name: string;
  displayName: string;
  descriptionShort: string;
  descriptionLong: string;
  deductible: number;
  providesExtendedAreaOfCoverage: boolean;
  type: string;
  addons: Array<AddonType>;
};

export type AddonType = {
  addonId: number;
  type: string;
  maxCoverageAmountPerPeriod: number;
  maxOutOfPocketPerPeriod: number;
  coInsurancePercentage: number;
};

export type GroupType = {
  groupId: number;
  name: string;
  companyId: number;
};

export type MemberType = {
  userID: number;
  firstName?: string;
  lastName?: string;
  groupId?: string;
  email: string;
  dependantCount?: number;
  monthlyCost: number;
  locationCountry?: string;
  fullLocationCountry?: string;
  onboardingStatus: string;
  policyID?: string;
  dateOfBirth?: string;
  citizenship: string;
  insuranceStartDate?: string;
  insuranceExpiryDate?: string;
  contractCost?: number;
  insurancePartner?: string;
  dependants?: DependantType[];
  insuranceCanceledDate?: string;
  status: string;
};

export type DraftMemberType = {
  status: string;
  id: number;
  email: string;
  companyContractId: number;
  startDate: string;
  plan: PlanType;
};

export type DependantType = {
  citizenshipCountry: string;
  dateOfBirth: string;
  email?: string;
  firstName: string;
  lastName: string;
  gender: string;
  dependantID: number;
  relationship: string;
  homeCountry: string;
  hasUploadedIdentification: boolean;
  hasUploadedSchoolCertificate: boolean;
};

export type Filter = {
  status: Nullable<string>;
  fullLocationCountry: Nullable<string>;
};

enum SortValue {
  status,
  name,
  email,
  dependantCount,
  plan,
  annualCost
}

enum SortDirection {
  'asc',
  'desc'
}

export enum LoadingStatus {
  idle,
  loading,
  succeeded,
  failed
}

export enum FinchConnectionError {
  notAuthorized
}

export enum FinchConnectionStatus {
  CONNECTED,
  DISCONNECTED
}

export type Sort = {
  value: Nullable<SortValue>;
  direction: Nullable<SortDirection>;
};

export type DashboardSettings = {
  searchValue: Nullable<string>;
  filters: Filter;
  sort: Sort;
  page: number;
  pageSize: number;
};

export type RemoteIndividual = {
  remoteId: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
};

export type FinchConnection = {
  id: number;
  companyId: number;
  connectionStatus: FinchConnectionStatus;
  finchProvider: string;
  finchAccountId: string;
  finchClientId: string;
  finchCompanyId: string;
};
