import { type Dispatch, createSlice } from '@reduxjs/toolkit';
import { get, post } from 'api/fetch';
import { decodeJWT } from 'auth';
import { PLAN_TYPES } from 'assets/constants';
import { components } from 'types/rhApiSchema';
import { Plan } from './plans';

// ================== Types ================== //

// Types generated from endpoint result with this: https://app.quicktype.io/?l=ts

type Dependant = {
  // TODO: fill rest of Dependant type
  dependantID: number;
  relationship: string;
};

type Planholder = {
  // TODO: union type for `role`
  role: string;
  planHolderId: number;

  // TODO: union type for `onboardingStatus`
  onboardingStatus: string;

  // TODO: figure out why this is here
  contractStatus: string;

  // TODO: union type for `status`
  status: string;

  // TODO: union type for `citizenshipCountry`
  citizenshipCountry: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  gender: string;
  userID: number;
  companyID: number;
  address: Address;
  email: string;
  homeCountry: string;
  phoneNumber: string;
  phoneCountryCode: string;
  hasCompletedProfile: boolean;
  consentToTermsAndConditions: boolean;
  consentToMarketing: boolean;
  companyName: string;
  isCompanyOwner: boolean;
  isCompanyAdmin: boolean;
  isCompanyMember: boolean;

  // TODO: union type for `planHolderType`
  planHolderType: string;
  canModifyDependants: boolean;

  // TODO: figure out why these are here
  selectedStartDate: string;
  selectedPlan: Omit<Plan, 'companyPlanId' | 'displayName'>;

  dependants?: Dependant[];
};

type Address = {
  country: string;
  city: string;
  state: string;
  line1: string;
  line2: string;
  zipCode: string;
};

type State = {
  isLoading: boolean;
  error: null;
  planholder?: Planholder | null;
};

// ================== Reducers ================== //

const initialState: State = {
  isLoading: false,
  error: null,
  planholder: null
};

const slice = createSlice({
  name: 'remote_health/planholder',
  initialState,
  reducers: {
    // fetchPlanholderInfo
    fetchPlanholderInfoRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchPlanholderInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.planholder = {
        role: payload.role,
        ...payload.planholder
      };
    },
    fetchPlanholderInfoFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.planholder = null;
    },
    // setPlanHolderInfo
    setPlanholderInfoRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setPlanholderInfoSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.planholder = {
        ...(state?.planholder || {}),
        ...payload?.planholder,
        role: payload?.role
      };
    },
    setPlanholderInfoFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    // updatePlanholderInfo
    updatePlanholderInfo: (state, { payload }) => {
      state.planholder = {
        ...state.planholder,
        ...payload
      };
    }
  }
});

export default slice.reducer;

// ================== Util ================== //

const getUserRole = (
  isOwner?: boolean,
  isAdmin?: boolean,

  // TODO: type: key of `PLAN_TYPES`
  planHolderType?: string
) => {
  let role = null;
  if (isOwner && isAdmin) {
    role = 'owner';
  } else if (!isOwner && isAdmin) {
    role = 'admin';
  } else if (
    planHolderType === PLAN_TYPES.COMPANY_MHD ||
    planHolderType === PLAN_TYPES.COMPANY_FMU ||
    planHolderType === PLAN_TYPES.SOLO_INDIVIDUAL
  ) {
    role = 'member';
  }

  return role;
};

// ================== Actions ================== //

const { actions } = slice;

export const fetchPlanholderInfo =
  (redirectOnUnauthorized: boolean) => async (dispatch: Dispatch) => {
    dispatch(actions.fetchPlanholderInfoRequest());

    const accessToken = decodeJWT('accessToken');

    if (!accessToken)
      throw new Error('No tokens present with which user info can be retrieved');

    try {
      const planholder = await get(`/rh/plan-holders`, {
        api: 'rh',
        redirectOnUnauthorized
      });

      if (planholder.userID !== decodeJWT('userID'))
        throw new Error('UserInfo id and accessToken id do not match');

      const role = getUserRole(
        planholder.isCompanyOwner,
        planholder.isCompanyAdmin,
        planholder.planHolderType
      );

      dispatch(actions.fetchPlanholderInfoSuccess({ planholder, role }));
    } catch (error) {
      dispatch(actions.fetchPlanholderInfoFailure(error));
    }
  };

export const setPlanHolderInfo =
  (
    info: Partial<components['schemas']['PlanHolderJsonRH']>,

    /**
     * @deprecated -- use await instead
     */
    onSuccess = () => {}
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(actions.setPlanholderInfoRequest());

    try {
      // TODO: validate `info` with zod or similar
      const planholder = (await post('/rh/members/plan-holder/update', info, {
        api: 'rh',
        redirectOnUnauthorized: true
      })) as components['schemas']['PlanHolderJsonRH'];

      const role = getUserRole(
        planholder.isCompanyOwner,
        planholder.isCompanyAdmin,
        planholder.planHolderType
      );

      dispatch(actions.setPlanholderInfoSuccess({ planholder, role }));
      onSuccess();
      return { data: planholder };
    } catch (err) {
      const error = 'Failed to update planholder';
      dispatch(actions.setPlanholderInfoFailure(error));
      return { error };
    }
  };

export const updatePlanholderInfo =
  (info: Partial<Planholder>) => (dispatch: Dispatch) => {
    // TODO: validate `info` with zod or similar
    dispatch(actions.updatePlanholderInfo(info));
  };
