import { setCookie, parseCookies } from 'nookies';
import { FEATURE_MEMBER_EXPERIENCE } from 'consts';
import SECRET, { SEVEN_DAYS } from './constants';

const jwt = require('jsonwebtoken');

export const createJWT = async (data) => {
  const payload = {
    userID: data.userID,
    accessToken: data.accessToken,
    updatedAt: Date.now(),
    companyID: data.companyID,
    isCompanyAdmin: data.isCompanyAdmin,
    isCompanyOwner: data.isCompanyOwner,
    isCompanyMember: data.isCompanyMember
  };

  await setCookie(null, 'SWToken', data.accessToken, {
    path: '/',
    sameSite: 'lax',
    secure: true,
    expires: new Date(Date.now() + SEVEN_DAYS)
  });

  const token = jwt.sign(payload, SECRET, { expiresIn: '168h' });
  window.localStorage.setItem('SWToken', token);
};

export const clearJWT = () => {
  document.cookie = 'SWToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  window.localStorage.removeItem('SWToken');
};

const syncAccessTokenCookie = () => {
  const cookies = parseCookies();
  const token = cookies.SWToken;

  if (token) {
    const decodedToken = jwt.decode(token);

    const payload = {
      userID: decodedToken.userId,
      accessToken: token,
      updatedAt: Date.now(),
      companyID: decodedToken.companyId,
      isCompanyAdmin: false,
      isCompanyOwner: false,
      isCompanyMember: false
    };

    const localStorageToken = jwt.sign(payload, SECRET, { expiresIn: '168h' });
    window.localStorage.setItem('SWToken', localStorageToken);
  }

  if (FEATURE_MEMBER_EXPERIENCE && !token) {
    clearJWT();
  }
};

export const decodeJWT = (key) => {
  try {
    syncAccessTokenCookie();
    const token = window.localStorage.getItem('SWToken');

    const decoded = jwt.verify(token, SECRET);
    return decoded[key];
  } catch (err) {
    return null;
  }
};

export const pushToJWT = async (obj) => {
  syncAccessTokenCookie();
  const token = window.localStorage.getItem('SWToken');

  const payload = jwt.verify(token, SECRET);
  const newPayload = {
    ...payload,
    ...obj
  };

  const newToken = jwt.sign(newPayload, SECRET);

  if (obj.accessToken) {
    await setCookie(null, 'SWToken', obj.accessToken, {
      path: '/',
      sameSite: 'lax',
      secure: true,
      expires: new Date(Date.now() + SEVEN_DAYS)
    });
  }

  window.localStorage.setItem('SWToken', newToken);
};

export const pushAccessTokenToJWT = async (authToken) => {
  syncAccessTokenCookie();
  const token = window.localStorage.getItem('SWToken');

  const payload = jwt.verify(token, SECRET);
  const newPayload = {
    ...payload,
    accessToken: authToken,
    updatedAt: Date.now()
  };

  await setCookie(null, 'SWToken', authToken, {
    path: '/',
    sameSite: 'lax',
    secure: true,
    expires: new Date(Date.now() + SEVEN_DAYS)
  });

  const newToken = jwt.sign(newPayload, SECRET);

  window.localStorage.setItem('SWToken', newToken);
};
