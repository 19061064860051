import { createSlice, type Dispatch } from '@reduxjs/toolkit';
import { get, post, put } from 'api/fetch';
import isEmpty from 'lodash/isEmpty';
import { type components } from 'types/rhApiSchema';

type Beneficiary = components['schemas']['Beneficiary'];

export const slice = createSlice({
  // TODO: rename to planholderBeneficiaries?
  name: 'beneficiaries',
  initialState: {
    isLoading: false,
    isUpdating: false,
    beneficiaries: [] as Beneficiary[],
    error: null as string | null
  },
  reducers: {
    fetchBeneficiariesRequest: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    fetchBeneficiariesSuccess: (state, { payload }: { payload: Beneficiary[] }) => {
      state.isLoading = false;
      state.beneficiaries = payload;
    },
    // eslint-disable-next-line
    fetchBeneficiariesFailure: (state, { payload }: { payload: string }) => {
      state.isLoading = false;
    },

    updateBeneficiaryRequest: (state) => {
      state.isUpdating = true;
      state.error = null;
    },
    updateBeneficiarySuccess: (state) => {
      state.isUpdating = false;
      state.error = null;
    },
    updateBeneficiaryFailure: (state, { payload }: { payload: string }) => {
      state.isUpdating = false;
      state.error = payload;
    }
  }
});

const { actions } = slice;

export const fetchBeneficiaries =
  ({ planHolderId }: { planHolderId: number }) =>
  async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchBeneficiariesRequest());
    try {
      const beneficiaries = (await get(`/beneficiary/planholder/${planHolderId}`, {
        api: 'rh',
        redirectOnUnauthorized: false
      })) as Beneficiary[];

      if (isEmpty(beneficiaries)) {
        const error = 'Failed to fetch beneficiaries';
        dispatch(actions.fetchBeneficiariesFailure(error));
        return { error };
      }

      dispatch(actions.fetchBeneficiariesSuccess(beneficiaries));
      return { data: beneficiaries };
    } catch (err) {
      const error = 'Failed to fetch beneficiaries';
      dispatch(actions.fetchBeneficiariesFailure(error));
      return { error };
    }
  };

export const createBeneficiary =
  ({
    planHolderId,
    email,
    fullName
  }: {
    planHolderId: number;
    email: string;
    fullName: string;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.updateBeneficiaryRequest());
      await put(
        '/beneficiary/planholder',
        {
          planHolderId,
          email,
          fullName
        },
        { api: 'rh', redirectOnUnauthorized: true }
      ); // as components['schemas']['CreateBeneficiaryRequest'];

      const beneficiariesRes = await dispatch(fetchBeneficiaries({ planHolderId }));
      dispatch(actions.updateBeneficiarySuccess());

      // @ts-ignore -- ts can't keep up with redux-toolkit
      return { data: beneficiariesRes?.data as Beneficiary[] };
    } catch (err) {
      const error = 'Failed to update beneficiary information (code: 1)';
      dispatch(actions.updateBeneficiaryFailure(error));
      return { error };
    }
  };

export const updateBeneficiary =
  ({
    beneficiaryId,
    email,
    fullName
  }: {
    beneficiaryId: number;
    email: string;
    fullName: string;
  }) =>
  async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.updateBeneficiaryRequest());
      const res = (await post(
        '/beneficiary',
        {
          beneficiaryId,
          modifiedEmail: email,
          modifiedFullName: fullName
        },
        { api: 'rh', redirectOnUnauthorized: true }
      )) as components['schemas']['Beneficiary'];
      const beneficiariesRes = await dispatch(
        fetchBeneficiaries({ planHolderId: res.planHolderId! })
      );
      dispatch(actions.updateBeneficiarySuccess());

      // @ts-ignore -- ts can't keep up with redux-toolkit
      return { data: beneficiariesRes?.data as Beneficiary[] };
    } catch (err) {
      const error = 'Failed to update beneficiary information (code: 2)';
      dispatch(actions.updateBeneficiaryFailure(error));
      return { error };
    }
  };

export default slice.reducer;
