import { type components } from 'types/rhApiSchema';

export type SelfSignedCompany = components['schemas']['CompanySignupDto'];
export type CompanyStatus = SelfSignedCompany['status'];

export const COMPANY_STATUS_ORDER: CompanyStatus[] = [
  'COMPANY_INFO',
  'INVITE_MEMBERS',
  'MEMBERS_ONBOARDING',
  'PAYMENT',
  'APPLICATION',
  'COMPLETED'
];
