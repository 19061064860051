import { createSlice } from '@reduxjs/toolkit';
import { get, post } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/CompanyProfile/account/admins',
  initialState: {
    isLoading: false,
    admins: [],
    error: null
  },
  reducers: {
    // fetchCompanyAdmins
    fetchCompanyAdminsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchCompanyAdminsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.admins = payload;
    },
    fetchCompanyAdminsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.admins = null;
      state.error = payload;
    },
    // addCompanyAdmins
    addCompanyAdminsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    addCompanyAdminsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.admins = null;
      state.error = payload;
    },
    // deleteCompanyAdmin
    deleteCompanyAdminsRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteCompanyAdminsFailure: (state, { payload }) => {
      state.isLoading = false;
      state.admins = null;
      state.error = payload;
    }
  }
});

export const { actions } = slice;

// ================== Actions ================== //

export const fetchCompanyAdmins = () => async (dispatch) => {
  dispatch(actions.fetchCompanyAdminsRequest());

  try {
    const response = await get('/rh/roles/companies/admins', { api: 'rh' });
    dispatch(actions.fetchCompanyAdminsSuccess(response));
  } catch (error) {
    dispatch(actions.fetchCompanyAdminsFailure(error));
  }
};

export const addCompanyAdmins = (admins, closeModal) => async (dispatch) => {
  dispatch(actions.addCompanyAdminsRequest());

  const body = {
    userIdsToGrantAccess: admins.map((admin) => admin.userId)
  };

  try {
    closeModal();
    await post(`/rh/roles/companies/admin/access/grant`, body, { api: 'rh' });
    await dispatch(fetchCompanyAdmins());
  } catch (error) {
    dispatch(actions.addCompanyAdminsFailure(error));
  }
};

export const deleteCompanyAdmin = (userId) => async (dispatch) => {
  dispatch(actions.deleteCompanyAdminsRequest());

  try {
    await post(`/rh/roles/companies/admin/access/revoke?userId=${userId}`, null, {
      api: 'rh'
    });
    await dispatch(fetchCompanyAdmins());
  } catch (error) {
    dispatch(actions.deleteCompanyAdminsFailure(error));
  }
};

export default slice.reducer;
