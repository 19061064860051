// ================== Environment ================== //

export const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
export const isLocal = process.env.REACT_APP_ENVIRONMENT === 'local';
export const isStaging = process.env.REACT_APP_ENVIRONMENT === 'staging';

// ================== Backend API Urls ================== //

export const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
export const rhApiUrl = process.env.REACT_APP_RH_API_URL;

export const URL = process.env.REACT_APP_URL;
