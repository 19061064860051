import { useEffect } from 'react';
import { toggleScrollLock } from 'assets/helpers';
import { Overlay, OverlaySpinner } from './styled';

const LoadingOverlay = ({
  isLoading,
  variant = 'global',
  size,
  className,
  scrollLock,
  comment
}: Props) => {
  useEffect(() => {
    if (scrollLock) toggleScrollLock(isLoading);
    return () => {
      if (scrollLock) toggleScrollLock(false);
    };
  }, [isLoading, scrollLock]);

  if (isLoading) {
    return (
      <Overlay variant={variant} className={className} scrollLock={scrollLock}>
        <OverlaySpinner size={size} variant={variant === 'light' ? 'dark' : 'light'} />
        {comment && <p>{comment}</p>}
      </Overlay>
    );
  }

  return null;
};

type Props = {
  isLoading: boolean;
  variant?: LoadingOverlayVariants;
  className?: string;
  size?: string;
  scrollLock?: boolean;
  comment?: string;
};

export type LoadingOverlayVariants = 'light' | 'dark' | 'global' | 'darkFull';

export default LoadingOverlay;
