import { type Dispatch, createSlice } from '@reduxjs/toolkit';
import { get } from 'api/fetch';

// ================== Types ================== //

type DependantType = 'PARENT' | 'CHILD';

type DependantConfig = {
  canModifyDependants: boolean;
  dependantTypes: DependantType[];
};

type State = {
  isLoading: boolean;
  error: null;
  dependantConfig?: DependantConfig | null;
};

// ================== Reducers ================== //

const initialState: State = {
  isLoading: false,
  error: null,
  dependantConfig: null
};

const slice = createSlice({
  name: 'remote_health/onboarding/dependant/config',
  initialState,
  reducers: {
    fetchDependantConfigRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchDependantConfigSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.dependantConfig = payload || null;
    },
    fetchDependantConfigFailure: (state, { payload }) => {
      state.isLoading = false;
      state.dependantConfig = null;
      state.error = payload;
    }
  }
});

export const { actions } = slice;

// ================== Actions ================== //

export const fetchDependantConfig = () => async (dispatch: Dispatch) => {
  dispatch(actions.fetchDependantConfigRequest());

  try {
    const response = await get(`/member/onboarding/dependant/config`, {
      api: 'rh',
      redirectOnUnauthorized: true
    });
    dispatch(actions.fetchDependantConfigSuccess(response));
  } catch (error) {
    dispatch(actions.fetchDependantConfigFailure(error));
  }
};

export default slice.reducer;
