export const determineStatus = (
  submissionStatus: string,
  claimStatus: string
): string => {
  switch (submissionStatus) {
    case 'Filing':
    case null:
      return 'UNSUBMITTED';
    case 'Pending':
      return 'SUBMITTING';
    case 'Error':
      return 'SUBMITTING';
    default:
      return claimStatus;
  }
};

export const getClaimStatus = (
  submissionStatus: string,
  claimStatus: string,
  isPaymentCardClaim: boolean
) => {
  const currentStatus = determineStatus(submissionStatus, claimStatus);
  if (currentStatus === 'UNSUBMITTED' && isPaymentCardClaim) {
    return 'ACTION_REQUIRED';
  }
  return currentStatus;
};
