import { createSlice } from '@reduxjs/toolkit';
import { get, post } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/MemberProfile/billing',
  initialState: {
    isLoading: false,
    billingInfo: null,
    error: null
  },
  reducers: {
    // fetchBillingInformation
    fetchBillingInformationRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchBillingInformationSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.billingInfo = payload;
    },
    fetchBillingInformationFailure: (state, { payload }) => {
      state.isLoading = false;
      state.billingInfo = null;
      state.error = payload;
    }
  }
});

export const { actions } = slice;

// ================== Actions ================== //

export const fetchBillingInformation = (individual) => async (dispatch) => {
  dispatch(actions.fetchBillingInformationRequest());

  try {
    if (individual) {
      const response = await get('/rh/members/billing/details', {
        api: 'rh',
        redirectOnUnauthorized: true
      });
      dispatch(actions.fetchBillingInformationSuccess(response));
    } else {
      const response = await get('/rh/companies/billing/details', {
        api: 'rh',
        redirectOnUnauthorized: true
      });
      dispatch(actions.fetchBillingInformationSuccess(response));
    }
  } catch (error) {
    dispatch(actions.fetchBillingInformationFailure(error));
  }
};

export const setBillingInformation =
  (billingInfo, closeModal, individual) => async (dispatch) => {
    dispatch(actions.fetchBillingInformationRequest());
    let body = {
      paymentMethod: billingInfo.paymentMethod || '',
      frequency: billingInfo.frequency || ''
    };

    if (body.paymentMethod === 'CARD') {
      const cardData = {
        cardScheme: billingInfo.cardScheme,
        encryptedPan: billingInfo.encryptedPan,
        encryptedCvv: billingInfo.encryptedCvv,
        cardHolderName: billingInfo.cardHolderName,
        expiryMonth: billingInfo.expiryMonth,
        expiryYear: billingInfo.expiryYear,
        addressLine: billingInfo?.address?.line1,
        addressLineExtra: billingInfo?.addres?.line2,
        city: billingInfo?.address?.city,
        state: billingInfo?.address?.state,
        country: billingInfo?.address?.country,
        zip: billingInfo?.address?.zipCode
      };
      body = {
        ...body,
        ...cardData
      };
    }

    try {
      closeModal();
      await post(
        `/insurance/rh/members/billing/details?individual=${
          individual?.includes('INDIVIDUAL') ?? false
        }`,
        body
      );
      await dispatch(fetchBillingInformation(true));
    } catch (error) {
      dispatch(actions.fetchBillingInformationFailure(error));
    }
  };

export default slice.reducer;
