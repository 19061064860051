import { combineReducers } from 'redux';
import billing from 'views/MemberProfile/MyDetails/state';
import contract from './MyInsurance/state/contract';
import myInsurance from './MyInsurance/state';

export default combineReducers({
  contract,
  myInsurance,
  billing
});
