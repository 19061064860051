import { useState } from 'react';

export const getId = (() => {
  let id = 0;
  return () => `rh-${id++}`;
})();

const useId = () => {
  const [id] = useState(getId);
  return id;
};

export default useId;
