import COUNTRIES from 'assets/data/country-select';

export interface BankTransferRequirement {
  field: BankTransferField;
  validate: Function;
  validationErrorMessage?: string;
  optional?: boolean;
  label?: string;
  tooltip?: string;
  type?: 'string' | 'boolean';
}

export enum BankTransferField {
  // field = key , label = value
  swift = 'SWIFT or BIC',
  accountNumberOrIban = 'Bank account number OR IBAN',
  fullNameOfAccountHolder = 'Full name of account holder',
  bankName = 'Bank name',
  bankCity = 'City',
  bankCountry = 'Country',
  accountType = 'Bank account type',
  accountHolderPhoneNumber = 'Phone number of account holder',
  indianFinancialSystemCode = 'Indian Financial System Code',
  bankBranchName = 'Bank branch name',
  bankCode = 'Bank code',
  bankClearingCodeWhenNotIban = 'Bank clearing code',
  beneficiaryTaxId = 'Beneficiary Tax ID',
  beneficiaryNationalId = 'Beneficiary National ID',
  routingOrBankBranchCode = 'Routing number or agency code', // TODO: Slowly split up as Expa adds to validation requirements
  bankBranchCode = 'Bank Branch Code',
  agencyCode = 'Agency Code',
  beneficiaryVO = 'Beneficiary VO',
  beneficiaryCPF = 'Beneficiary CPF',
  beneficiaryKNP = 'Beneficiary KNP',
  beneficiaryKBE = 'Beneficiary KBE',
  curpNumber = 'CURP Number',
  includeVATax = 'Include VA Tax',
  purposeOfPayment = 'Purpose of Payment',
  vatAmount = 'VAT Amount',
  vatCode = 'VAT Code',
  beneficiaryPatronymic = 'Beneficiary Patronymic Name'
}

const isCheckingOrSaving = (accountType: string) =>
  accountType === 'CHECKING' || accountType === 'SAVINGS';

const isValidIban = (accountNumber: string, code: string, length: number) =>
  new RegExp(`^(${code})[\\d\\w]{${length}}$`).test(accountNumber);

const hasNumberOfDigits = (accountNumber: string, min: number, max?: number) =>
  new RegExp(`^\\d{${min},${max || min}}$`).test(accountNumber);

const isValidSwiftCode = (swiftCode: string): boolean =>
  /^(?:[A-Z]{4}[A-Z]{2}[A-Z0-9]{2})(?:[A-Z0-9]{3})?$/.test(swiftCode.toUpperCase());

const feeWarning = (currency: string, country: string): CurrencyWarning => ({
  text: `Payments in ${currency} to accounts in ${country} are only possible via wire transfer. Your bank may charge you for receiving funds by wire transfer.`,
  title: 'Wire Transfer Fee'
});

const sanctionWarning = (text: string): CurrencyWarning => ({
  text,
  title: 'Possible Sanctions'
});

export const indexOfBankTransferField = (field: BankTransferField): number =>
  Object.values(BankTransferField)?.indexOf(field);

export const getKeyForField = (field: BankTransferField) => {
  return Object.keys(BankTransferField)[indexOfBankTransferField(field)] || '';
};

const valueIsWithinCharacterLimits = (
  value: string,
  minimumNumberOfChars?: number,
  maximumNumberOfChars?: number
): boolean => {
  if (!minimumNumberOfChars && !maximumNumberOfChars) {
    return value?.length > 0;
  }
  if (minimumNumberOfChars && maximumNumberOfChars) {
    return value?.length >= minimumNumberOfChars && value?.length <= maximumNumberOfChars;
  }
  if (minimumNumberOfChars && !maximumNumberOfChars) {
    return value?.length >= minimumNumberOfChars;
  }
  return (
    maximumNumberOfChars != null &&
    value?.length <= maximumNumberOfChars &&
    value?.length >= 1
  );
};

const isOnlyLatinCharacters = (value: string): boolean => {
  return /^[\w -]+$/.test(value);
};

const commonCurrencyFields: BankTransferRequirement[] = [
  {
    field: BankTransferField.swift,
    validate: (swiftCode: string) => isValidSwiftCode(swiftCode),
    validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`,
    optional: true
  },
  {
    field: BankTransferField.bankName,
    validate: (nameOrCode: string) =>
      valueIsWithinCharacterLimits(nameOrCode) && isOnlyLatinCharacters(nameOrCode),
    validationErrorMessage: `Bank name must be in Latin characters.`,
    optional: true
  },
  {
    field: BankTransferField.accountNumberOrIban,
    validate: (accountNumber: string) => valueIsWithinCharacterLimits(accountNumber),
    optional: false
  },
  {
    field: BankTransferField.fullNameOfAccountHolder,
    validate: (fullName: string) =>
      valueIsWithinCharacterLimits(fullName) && isOnlyLatinCharacters(fullName),
    validationErrorMessage: `Full name must be in Latin characters.`,
    optional: false
  },
  {
    field: BankTransferField.bankCity,
    validate: (city: string) =>
      valueIsWithinCharacterLimits(city) && isOnlyLatinCharacters(city),
    validationErrorMessage: `Bank city must be in Latin characters.`,
    optional: true
  }
];

export interface CurrencyWarning {
  text: string;
  title: string;
}
export interface CurrencySpecification {
  requirements: BankTransferRequirement[];
  warning?: CurrencyWarning | CurrencyWarning[] | null;
  default?: boolean;
}

type CurrencyDictionary = {
  [currencyCode: string]: CurrencySpecification;
};

const SUPPORTED_COUNTRIES_AND_CURRENCIES: {
  [countryCode: string]: CurrencyDictionary;
} = {
  // UAE
  AE: {
    AED: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'AE', 21),
          validationErrorMessage: `IBAN must be on the format AE followed by 21 characters without spaces.`
        }
      ],
      default: true
    }
  },

  // Afghanistan
  AF: {
    AFN: {
      requirements: [],
      default: true,
      warning: feeWarning('AFN', 'Afghanistan')
    }
  },

  // Albania
  AL: {
    ALL: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'AL', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format AL followed by 26 characters.`
        }
      ],
      warning: feeWarning('ALL', 'Albania'),
      default: true
    }
  },

  // Andorra
  AD: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'AD', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format AD followed by 22 characters.`
        }
      ],
      default: true
    }
  },

  // Armenia
  AM: {
    AMD: {
      requirements: [],
      warning: feeWarning('AMD', 'Armenia'),
      default: true
    }
  },

  // Argentina
  AR: {
    ARS: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 24),
          validationErrorMessage: `Account number must be between 1 and 24 characters`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 30 characters`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => valueIsWithinCharacterLimits(taxId, 9, 12),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be between 9 and 12 characters`
        }
      ],
      default: true
    }
  },

  // Austria
  AT: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'AT', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format AT followed by 18 characters.`
        }
      ],
      default: true
    }
  },

  // Australia
  AU: {
    AUD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 6, 10),
          validationErrorMessage: 'Account number must be between 6 and 10 digits.'
        },
        {
          field: BankTransferField.bankBranchCode,
          label: 'BSB (Bank-State-Branch)',
          validate: (BSB: string) => hasNumberOfDigits(BSB, 6),
          validationErrorMessage: 'BSB must be exactly 6 digits (no hyphen in between).'
        }
      ],
      default: true
    }
  },

  // Angola
  AO: {
    AOA: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'A0', 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format A0 followed by 23 characters.`
        }
      ],
      warning: feeWarning('AOA', 'Angola'),
      default: true
    }
  },

  // Aruba
  AW: {
    AWG: {
      requirements: [
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        }
      ],
      warning: feeWarning('AWG', 'Aruba'),
      default: true
    }
  },

  // Azerbaijan
  AZ: {
    AZN: {
      requirements: [
        {
          field: BankTransferField.bankBranchCode,
          validate: (branchCode: string) =>
            valueIsWithinCharacterLimits(branchCode, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchCode} Bank branch code must be between 3 and 30 characters.`
        },
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'AZ', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format AZ followed by 26 characters.`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => valueIsWithinCharacterLimits(taxId, 1, 10),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be maximum 10 characters (without special characters)`
        }
      ],
      warning: feeWarning('AZN', 'Azerbaijan'),
      default: true
    }
  },

  // Belarus
  BY: {
    BYN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'BY', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format BY followed by 26 characters.`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => hasNumberOfDigits(taxId, 9),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be exactly 9 numerical digits (without special characters)`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 9),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} (MFO) must be exactly 9 digits`
        }
      ],
      warning: [
        feeWarning('BYN', 'Belarus'),
        sanctionWarning(
          'Transfers to Belarusian accounts are sometimes blocked due to sanctions, depending on the individual and your bank. Consider choosing another bank account.'
        )
      ],
      default: true
    }
  },

  // Bosnia and Herzegovina
  BA: {
    BAM: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'BA', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format BA followed by 18 characters.`
        }
      ],
      warning: feeWarning('BAM', 'Bosnia and Herzegovina'),
      default: true
    }
  },

  // Barbados
  BB: {
    BBD: {
      requirements: [],
      warning: feeWarning('BBD', 'Barbados'),
      default: true
    }
  },

  // Belgium
  BE: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'BE', 14),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format BE followed by 14 characters.`
        }
      ],
      default: true
    }
  },

  // Bangladesh
  BD: {
    BDT: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            hasNumberOfDigits(accountNumber, 9) || hasNumberOfDigits(accountNumber, 13),
          validationErrorMessage: 'Account number must be exactly 9 OR 13 characters.'
        },
        {
          field: BankTransferField.bankBranchCode,
          label: 'Branch code',
          validate: (branchCode: string) => hasNumberOfDigits(branchCode, 3),
          validationErrorMessage: 'Branch code must be exactly 3 digits.'
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 30),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be at most 30 characters`
        }
      ],
      default: true
    }
  },

  // Bahrain
  BH: {
    BHD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'BH', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format BH followed by 20 characters.`
        }
      ],
      warning: feeWarning('BHD', 'Bahrain'),
      default: true
    }
  },

  // Brazil
  BR: {
    BRL: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (iban: string) => isValidIban(iban, 'BR', 27),
          validationErrorMessage: `IBAN must start with 'BR' followed by exactly 27 characters.`,
          optional: false
        },
        {
          field: BankTransferField.swift,
          label: 'Swift Code',
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `Swift must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`,
          optional: false
        },
        {
          field: BankTransferField.beneficiaryCPF,
          validate: (cpf: string) => hasNumberOfDigits(cpf, 11, 11),
          validationErrorMessage: `${BankTransferField.beneficiaryCPF} must be exactly 11 digits`,
          optional: false
        },
        {
          field: BankTransferField.agencyCode,
          validate: (agencyCode: string) => hasNumberOfDigits(agencyCode, 4, 5),
          validationErrorMessage: `Your Agency Code is a 4-5 digit code that identifies your bank branch and is the 13th-17th characters of your 29 character IBAN`,
          optional: false
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountType} must be checking or savings`,
          optional: false
        },
        {
          field: BankTransferField.bankCode,
          validate: (bankCode: string) => hasNumberOfDigits(bankCode, 3, 3),
          validationErrorMessage: `${BankTransferField.bankCode} must be exactly 3 digits`,
          optional: false
        }
      ],
      default: true
    }
  },

  // Burkina Faso
  BF: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            (accountNumber.startsWith('BF') && isValidIban(accountNumber, 'BR', 26)) ||
            hasNumberOfDigits(accountNumber, 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format BF followed by 26 characters OR exactly 24 digits .`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      warning: feeWarning('XOF', 'Burkina Faso'),
      default: true
    }
  },

  // Bulgaria
  BG: {
    BGN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'BG', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'BG' followed by exactly 20 characters`
        }
      ],
      default: true
    }
  },

  // Burundi
  BI: {
    BIF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'BI', 14),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'BI' followed by exactly 14 characters`
        }
      ],
      warning: feeWarning('BIF', 'Burundi'),
      default: true
    }
  },

  // Bermuda
  BM: {
    BMD: {
      requirements: [],
      warning: feeWarning('BMD', 'Bermuda'),
      default: true
    }
  },

  // Brunei
  BN: {
    BND: {
      requirements: [],
      warning: feeWarning('BND', 'Brunei'),
      default: true
    }
  },

  // Benin
  BJ: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'BJ', 26) || hasNumberOfDigits(accountNumber, 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format BJ followed by 26 characters OR exactly 24 digits.`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      warning: feeWarning('XOF', 'Benin'),
      default: true
    }
  },

  // Bahamas
  BS: {
    BSD: {
      requirements: [],
      warning: feeWarning('BSD', 'Bahamas'),
      default: true
    }
  },

  // Bolivia
  BO: {
    BOB: {
      requirements: [],
      warning: feeWarning('BOB', 'Bolivia'),
      default: true
    }
  },

  // Bhutan
  BT: {
    BTN: {
      requirements: [],
      warning: feeWarning('BTN', 'Bhutan'),
      default: true
    }
  },

  // Belize
  BZ: {
    BZD: {
      requirements: [],
      warning: feeWarning('BZD', 'Belize'),
      default: true
    }
  },

  // Canada
  CA: {
    CAD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 6, 16), // TODO: check correct number of digits
          validationErrorMessage: `Account number must be between 6 and 16 digits.`,
          optional: false
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountType} must be checking or savings`,
          optional: false
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'Financial Institution & Branch Transit',
          tooltip:
            'The institution number (3 digits) and transit number (5 digits) should be put together and the sequence should start with a zero. The sequence will have the following format 0YYYXXXXX having 9 digits in total.',
          validate: (routingOrBankBranchCode: string) =>
            /^0\d{8}$/.test(routingOrBankBranchCode),
          validationErrorMessage: `Starts with a zero followed by the Institution number (3 digits) and the transit number (5 digits). In total 9 digits.`,
          optional: false
        }
      ],
      default: true
    }
  },

  // Democratic Rep. of Congo
  CD: {
    CDF: {
      requirements: [],
      warning: feeWarning('CDF', 'Democratic Rep. of Congo'),
      default: true
    }
  },

  // Central African Republic
  CF: {
    XAF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'CF', 25) || hasNumberOfDigits(accountNumber, 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format CF followed by 25 characters OR 23 digits exactly.`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        }
      ],
      default: true
    }
  },

  // Cameroon
  CM: {
    XAF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'CM', 25) || hasNumberOfDigits(accountNumber, 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format CM followed by 25 characters OR 23 digits exactly.`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        }
      ],
      warning: feeWarning('XAF', 'Cameroon'),
      default: true
    }
  },

  // China
  CN: {
    CNY: {
      requirements: [
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        },
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('CNY', 'China'),
      default: false
    }
  },

  // Colombia
  CO: {
    COP: {
      requirements: [
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 3, 30),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.beneficiaryNationalId, // CPF in Brazil
          validate: (nationalId: string) =>
            valueIsWithinCharacterLimits(nationalId, 8, 11) &&
            nationalId.match(/^\d+$/) != null,
          validationErrorMessage: `${BankTransferField.beneficiaryNationalId} must be between 8 and 11 numerical digits (without special characters)`
        }
      ],
      default: true
    }
  },

  // Ivory Coast
  CI: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            (accountNumber.startsWith('CI') && accountNumber.length === 28) ||
            accountNumber.length === 24,
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'CI' followed by exactly 26 characters OR entire length must be exactly 24 characters`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      default: true,
      warning: feeWarning('XOF', 'Ivory Coast')
    }
  },

  // Chile
  CL: {
    CLP: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => /^[K\-.\d]{1,13}$/.test(accountNumber),
          validationErrorMessage:
            'Account number can be up to 13 characters. Digits, the letter K, - and . are allowed.'
        },
        {
          field: BankTransferField.swift,
          validate: (swiftCode: string) => isValidSwiftCode(swiftCode),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        },
        {
          field: BankTransferField.beneficiaryNationalId,
          validate: (nationalId: string) =>
            valueIsWithinCharacterLimits(nationalId, 7, 10),
          validationErrorMessage: `${BankTransferField.beneficiaryNationalId} (RUT) must be between 7 and 10 numerical digits (without special characters)`
        }
      ],
      default: true
    }
  },

  // Costa Rica
  CR: {
    CRC: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CR', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'CR' followed by exactly 20 characters`
        },
        {
          field: BankTransferField.beneficiaryNationalId,
          validate: (nationalId: string) =>
            valueIsWithinCharacterLimits(nationalId, 9, 12) &&
            nationalId.match(/^\d+$/) != null,
          validationErrorMessage: `${BankTransferField.beneficiaryNationalId} (Benificiary cedula) must be between 9 and 12 numerical digits (without special characters)`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 3, 30),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            valueIsWithinCharacterLimits(routingOrBankBranchCode, 1, 20),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be between 1 and 20 characters.`
        }
      ],
      default: true
    }
  },

  // Cape Verde
  CV: {
    CVE: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CV', 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'CV' followed by exactly 23 characters`
        }
      ],
      warning: feeWarning('CVE', 'Cape Verde'),
      default: true
    }
  },

  // Cyprus
  CY: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CY', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'CY' followed by exactly 26 characters`
        }
      ],
      default: true
    }
  },

  // Curaçao
  CW: {
    ANG: {
      requirements: [],
      warning: feeWarning('ANG', 'Curaçao'),
      default: true
    }
  },

  // Czechia/Czech Republic
  CZ: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CZ', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'CZ' followed by exactly 22 characters`
        }
      ],
      default: false
    },
    CZK: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CZ', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'CZ' followed by exactly 22 characters`
        }
      ],
      default: true
    }
  },

  // Djibouti
  DJ: {
    DJF: {
      requirements: [],
      warning: feeWarning('DJF', 'Djibouti'),
      default: true
    }
  },

  // Denmark
  DK: {
    DKK: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'DK', 16),
          validationErrorMessage: `IBAN must be on the format DK followed by 16 characters.`
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'DK', 16),
          validationErrorMessage: `IBAN must be on the format DK followed by 16 characters.`
        }
      ],
      default: false
    }
  },

  // Dominican Republic
  DO: {
    DOP: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'DO', 26) ||
            hasNumberOfDigits(accountNumber, 1, 31),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format DO followed by 26 characters(IBAN) OR between 1 and 31 digits`
        }
      ],
      warning: feeWarning('DOP', 'Dominican Republic'),
      default: true
    }
  },

  // Algeria
  DZ: {
    DZD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'DZ', 24) || hasNumberOfDigits(accountNumber, 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format DZ followed by 24 characters OR 22 digits exactly.`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        }
      ],
      warning: feeWarning('DZD', 'Algeria'),
      default: true
    }
  },

  // Estonia
  EE: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'EE', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'EE' followed by exactly 18 characters`
        }
      ],
      default: true
    }
  },

  // Egypt
  EG: {
    EGP: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'EG', 27),
          validationErrorMessage: `IBAN must start with 'EG' followed by exactly 27 characters`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        }
      ],
      default: true
    }
  },

  // Ecuador
  EC: {},

  // Spain
  ES: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'ES', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'ES' followed by exactly 22 characters`
        }
      ],
      default: true
    }
  },

  // Eritrea
  ER: {
    ERN: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('ERN', 'Eritrea'),
      default: true
    }
  },

  // Ethiopia
  ET: {
    ETB: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        }
      ],
      warning: feeWarning('ETB', 'Ethiopia'),
      default: true
    }
  },

  // Finland
  FI: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'FI', 16),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format FI followed by 16 characters.`
        }
      ],
      default: true
    }
  },

  // Falkland Islands
  FK: {
    FKP: {
      requirements: [],
      default: true,
      warning: feeWarning('FKP', 'Falkland Islands')
    }
  },

  // France
  FR: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'FR', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'FR' followed by exactly 25 characters`
        }
      ],
      default: true
    }
  },

  // Georgia
  GE: {
    GEL: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'GE', 20),
          validationErrorMessage: `IBAN must be on the format GE followed by 20 characters.`
        },
        {
          field: BankTransferField.swift,
          validate: (swiftCode: string) => isValidSwiftCode(swiftCode),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`,
          optional: true
        }
      ],
      warning: sanctionWarning(
        'Transfers to Georgian accounts are sometimes blocked due to sanctions, depending on the individual and your bank. Consider choosing another bank account.'
      ),
      default: true
    },
    USD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Bank account or IBAN',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 17),
          validationErrorMessage: `Bank account number OR IBAN must be between 1 and 17 characters`
        }
      ],
      warning: feeWarning('USD', 'Georgia')
    }
  },
  // Germany
  DE: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'DE', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'DE' followed by exactly 20 characters`
        }
      ],
      default: true
    }
  },

  // Gabon
  GA: {
    XAF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'GA', 25) || hasNumberOfDigits(accountNumber, 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format GA followed by 25 characters OR 23 digits exactly.`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        }
      ],
      warning: feeWarning('XAF', 'Gabon'),
      default: true
    }
  },

  // French Guiana
  GF: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => /^\d\w{27}$/.test(accountNumber),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be exactly 27 characters`
        }
      ],
      default: true
    }
  },

  // Ghana
  GH: {
    GHS: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 8, 20),
          validationErrorMessage: 'Account number must be between 8 to 20 characters'
        },
        {
          field: BankTransferField.bankName,
          validate: (nameOrCode: string) =>
            valueIsWithinCharacterLimits(nameOrCode, 1, 30),
          validationErrorMessage: `${BankTransferField.bankName} must be between 1 and 30 characters`
        }
      ],
      default: true
    }
  },

  // Greenland
  GL: {
    DKK: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (iban: string) => isValidIban(iban, 'GL', 16),
          validationErrorMessage: `IBAN must start with 'GL' followed by exactly 16 characters`
        }
      ],
      default: true
    }
  },

  // Gambia
  GM: {
    GMD: {
      requirements: [],
      default: true
    }
  },

  // Guinea
  GN: {
    GNF: {
      requirements: [],
      warning: feeWarning('GNF', 'Guinea'),
      default: true
    }
  },

  // Guadeloupe
  GP: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => accountNumber.length === 27,
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be exactly 27 characters`
        }
      ],
      default: true
    }
  },

  // Greece
  GR: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'GR', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'GR' followed by exactly 25 characters`
        }
      ],
      default: true
    }
  },

  // Guatemala
  GT: {
    GTQ: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'GT', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'GT' followed by exactly 26 characters`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType)
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => valueIsWithinCharacterLimits(taxId, 9, 13),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be between 9 and 13 characters`
        }
      ],
      warning: feeWarning('GTQ', 'Guatemala'),
      default: true
    }
  },

  // Guyana
  GY: {
    GYD: {
      requirements: [
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 8),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 8 digits`
        }
      ],
      warning: feeWarning('GYD', 'Guyana'),
      default: true
    }
  },

  // Equatorial Guinea
  GQ: {
    XAF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'GQ', 25) || hasNumberOfDigits(accountNumber, 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format GQ followed by 25 characters OR 23 digits exactly.`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        }
      ],
      warning: feeWarning('XAF', 'Equatorial Guinea'),
      default: true
    }
  },

  // Guinea Bissau
  GW: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'GW', 23) || hasNumberOfDigits(accountNumber, 21),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'GW' followed by exactly 23 characters OR entire length must be exactly 21 digits`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      warning: feeWarning('XOF', 'Guinea Bissau'),
      default: true
    }
  },

  // Honduras
  HN: {
    HNL: {
      requirements: [],
      warning: feeWarning('HNL', 'Honduras'),
      default: true
    }
  },

  // Croatia
  HR: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'HR', 19),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'HR' followed by exactly 19 characters`
        }
      ],
      default: true,
      warning: feeWarning('EUR', 'Croatia')
    }
  },

  // Hong Kong
  HK: {
    HKD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 9, 12),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be between 9 and 12 digits`
        },
        {
          field: BankTransferField.bankCode,
          label: 'HK Bank Code',
          validate: (code: string) => {
            return code?.length === 3;
          },
          validationErrorMessage: `HK Bank Code must be exactly 3 characters`
        }
      ],
      default: true
    },
    CHN: {
      requirements: [
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 3, 30),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 3 and 30 characters`
        }
      ],
      default: false
    }
  },

  // Hungary
  HU: {
    HUF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            hasNumberOfDigits(accountNumber, 8) ||
            hasNumberOfDigits(accountNumber, 16) ||
            hasNumberOfDigits(accountNumber, 24),
          validationErrorMessage: `Account number must be exactly either 8, 16 OR 24 digits`
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'HU', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'HU' followed by exactly 26 characters`
        }
      ],
      default: false
    }
  },

  // Haiti
  HT: {
    HTG: {
      requirements: [],
      warning: feeWarning('HTG', 'Haiti'),
      default: true
    }
  },

  // Ireland
  IE: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'IE', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format IE followed by 20 characters.`
        }
      ],
      default: true
    }
  },

  // Iceland
  IS: {
    ISK: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'IS', 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format IS followed by 24 characters.`
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'IS', 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format IS followed by 24 characters.`
        }
      ],
      default: false
    }
  },

  // Italy
  IT: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'IT', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format IT followed by 25 characters.`
        }
      ],
      default: true
    }
  },

  // India
  IN: {
    INR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 16),
          validationErrorMessage: `Account number must be between 1 and 16 characters`,
          optional: false
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking, savings`,
          optional: false
        },
        {
          field: BankTransferField.indianFinancialSystemCode,
          validate: (ifsc: string) => {
            return ifsc?.length === 11;
          },
          validationErrorMessage: `${BankTransferField.indianFinancialSystemCode} must be exactly 11 characters`,
          optional: false
        }
      ],
      default: true
    }
  },

  // Indonesia
  ID: {
    IDR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 7, 18),
          validationErrorMessage: `Account number must be between 7 and 18 characters`
        }
      ],
      default: true
    }
  },

  // Israel
  IL: {
    ILS: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'IL', 21) ||
            hasNumberOfDigits(accountNumber, 1, 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be between 1 and 18 digits.`
        }
      ],
      default: true
    }
  },

  // Iraq
  IQ: {
    IQD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'IQ', 21),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must start with 'IQ' followed by exactly 21 characters`
        }
      ],
      warning: feeWarning('IQD', 'Iraq'),
      default: true
    }
  },

  // Jamaica
  JM: {
    JMD: {
      requirements: [
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 8),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 8 digits`
        },
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 1, 18),
          validationErrorMessage: `Account number must be between 1 and 18 digits.`
        }
      ],
      default: true
    }
  },

  // Jordan
  JO: {
    JOD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'JO', 28),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'JO' followed by exactly 28 characters`
        }
      ],
      warning: feeWarning('JOD', 'Jordan'),
      default: true
    }
  },

  // Japan
  JP: {
    JPY: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 7, 7),
          validationErrorMessage: `Account number be 7 digits`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) =>
            isCheckingOrSaving(accountType) || accountType === 'ORDINARY',
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking, savings or ORDINARY`
        },
        {
          field: BankTransferField.bankBranchCode,
          label: 'Branch code',
          validate: (branchCode: string) => hasNumberOfDigits(branchCode, 3, 3),
          validationErrorMessage: `Branch code must be 3 digits`
        }
      ],
      default: true
    }
  },

  // Kenya
  KE: {
    KES: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 30),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 30 characters`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 5),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 5 digits`
        }
      ],
      default: true
    }
  },

  // Kyrgyzstan
  KG: {
    KGS: {
      requirements: [],
      warning: feeWarning('KGS', 'Kyrgyzstan'),
      default: true
    }
  },

  // Cambodia
  KH: {
    KHR: {
      requirements: [],
      warning: feeWarning('KHR', 'Cambodia'),
      default: true
    }
  },

  // Comoros
  KM: {
    KMF: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('KMF', 'Comoros'),
      default: true
    }
  },

  // Cayman Islands
  KY: {
    KYD: {
      requirements: [],
      warning: feeWarning('KYD', 'Cayman Islands'),
      default: true
    }
  },

  // Kuwait
  KW: {
    KWD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'KW', 28),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'KW' followed by exactly 28 characters`
        }
      ],
      warning: feeWarning('KWD', 'Kuwait'),
      default: true
    }
  },

  // Kazakhstan
  KZ: {
    KZT: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'KZ', 18),
          validationErrorMessage: `IBAN must start with 'KZ' followed by exactly 18 characters`,
          optional: false
        },
        {
          field: BankTransferField.swift,
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `${BankTransferField.swift} must be between 8 and 11 characters`,
          optional: false
        },
        {
          field: BankTransferField.beneficiaryKNP,
          validate: (knp: string) => hasNumberOfDigits(knp, 3, 7),
          validationErrorMessage: `${BankTransferField.beneficiaryKNP} must be between 3 and 7 digits`,
          optional: false
        },
        {
          field: BankTransferField.beneficiaryKBE,
          label: 'KBE Code',
          validate: (kbe: string) => hasNumberOfDigits(kbe, 2),
          validationErrorMessage: `The KBE code is the beneficiary code and must be 2 digits`,
          optional: false
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          label: 'IIN (Individual Identification Number)',
          validate: (taxId: string) => taxId.length === 12,
          validationErrorMessage: `IIN must be exactly 12 characters`,
          optional: false
        }
      ],
      warning: feeWarning('KZT', 'Kazakhstan'),
      default: true
    }
  },

  // Laos
  LA: {
    LAK: {
      requirements: [],
      warning: feeWarning('LAK', 'Laos'),
      default: true
    }
  },

  // Lebanon
  LB: {
    LBP: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'LB', 26),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'LB' followed by exactly 26 characters`
        }
      ],
      default: true
    }
  },

  // Latvia
  LV: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'LV', 19) ||
            isValidIban(accountNumber, 'LV', 18) ||
            isValidIban(accountNumber, 'LT', 19) ||
            isValidIban(accountNumber, 'LT', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must start with LV or LT followed by 18-19 digits`
        }
      ],
      default: true
    }
  },

  // Liechtenstein
  LI: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'LI', 19),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format LI followed by 19 characters.`
        }
      ],
      default: true
    }
  },

  // Lithuania
  LT: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'LT', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'LT' followed by exactly 18 characters`
        }
      ],
      default: true
    }
  },

  // Luxembourg
  LU: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'LU', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format LU followed by 18 characters.`
        }
      ],
      default: true
    }
  },

  // Lesotho
  LS: {
    LSL: {
      requirements: [],
      warning: feeWarning('LSL', 'Lesotho'),
      default: true
    }
  },

  // Liberia
  LR: {
    LRD: {
      requirements: [],
      warning: feeWarning('LRD', 'Liberia'),
      default: true
    }
  },

  // Libya
  LY: {
    LYD: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('LYD', 'Libya'),
      default: true
    }
  },

  // Morocco
  MA: {
    MAD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'RIB',
          validate: (accountNumber: string) => accountNumber.length >= 19,
          validationErrorMessage: `RIB starts with your bank code (3 digits), followed by your branch code (3–8 digits), account number (11–16 digits), and RIB key (2 digits).`
        }
      ],
      default: true
    }
  },

  // Madagascar
  MG: {
    MGA: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'MG', 25) ||
            hasNumberOfDigits(accountNumber, 23, 27),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MG followed by 25 characters OR between 23-27 digits.`
        }
      ],
      warning: feeWarning('MGA', 'Madagascar'),
      default: true
    }
  },

  // Malawi
  MW: {
    DZD: {
      requirements: [],
      warning: feeWarning('DZD', 'Malawi'),
      default: true
    }
  },

  // Mali
  ML: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'ML', 26) || hasNumberOfDigits(accountNumber, 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'ML' followed by exactly 26 characters OR entire length must be exactly 24 digits`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      warning: feeWarning('XOF', 'Mali'),
      default: true
    }
  },

  // Mauritania
  MR: {
    MRO: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MR', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MR followed by 25 characters.`
        }
      ],
      warning: feeWarning('MRO', 'Mauritania'),
      default: true
    }
  },

  // Mauritius
  MU: {
    MUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MU', 28),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MU followed by 28 characters.`
        }
      ],
      warning: feeWarning('MUR', 'Mauritius'),
      default: true
    }
  },

  // Mozambique
  MZ: {
    MZN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MZ', 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MZ followed by 23 characters.`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => taxId.length === 9,
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be exactly 9 characters`
        }
      ],
      warning: feeWarning('MZN', 'Mozambique'),
      default: true
    }
  },

  // Malta
  MT: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MT', 29),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MT followed by 29 characters.`
        }
      ],
      default: true
    }
  },

  // Moldova
  MD: {
    MDL: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MD', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MD followed by 22 characters.`
        }
      ],
      warning: feeWarning('MDL', 'Moldova'),
      default: true
    }
  },

  // Monaco
  MC: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MC', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format MC followed by 25 characters.`
        }
      ],
      default: true
    }
  },

  // Montenegro
  ME: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'ME', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format ME followed by 20 characters.`
        }
      ],
      default: true
    }
  },

  // Macedonia
  MK: {
    MKD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'MK', 17),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'MK' followed by exactly 17 characters`
        }
      ],
      warning: feeWarning('MKD', 'Macedonia'),
      default: true
    }
  },

  // Myanmar
  MM: {
    MMK: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('MMK', 'Myanmar'),
      default: true
    }
  },

  // Mongolia
  MN: {
    MNT: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('MNT', 'Mongolia'),
      default: true
    }
  },

  // Macau
  MO: {
    MOP: {
      requirements: [],
      warning: feeWarning('MOP', 'Macau'),
      default: true
    }
  },

  // Maldives
  MV: {
    MVR: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('MVR', 'Maldives'),
      default: true
    }
  },

  // Malaysia
  MY: {
    MYR: {
      requirements: [],
      default: true
    }
  },

  // Mexico
  MX: {
    MXN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Clabe number',
          validate: (clabe: string) => valueIsWithinCharacterLimits(clabe, 18, 18),
          validationErrorMessage: `Clabe must be exactly 18 characters`,
          optional: false
        },
        {
          field: BankTransferField.swift,
          label: 'Swift',
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `Swift is either 8 or 11 digits`,
          optional: true
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`,
          optional: false
        },
        {
          field: BankTransferField.curpNumber,
          validate: (curp: string) => valueIsWithinCharacterLimits(curp, 18, 18),
          validationErrorMessage: `Providing a CURP number can expedite your reimbursement. Your CURP number must be 18 characters`
        }
      ],
      default: true
    }
  },

  // Martinique
  MQ: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => accountNumber.length === 27,
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be exactly 27 characters.`
        }
      ],
      default: true
    }
  },

  // Namibia
  NA: {
    NAD: {
      requirements: [],
      warning: feeWarning('NAD', 'Namibia'),
      default: true
    }
  },

  // Niger
  NE: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'NE', 26) || hasNumberOfDigits(accountNumber, 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'NE' followed by exactly 26 characters OR entire length must be exactly 24 digits`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      warning: feeWarning('XOF', 'Niger'),
      default: true
    }
  },

  // New Caledonia
  NC: {
    XPF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'FR', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'FR' followed by exactly 25 characters`
        }
      ],
      warning: feeWarning('XPF', 'New Caledonia'),
      default: true
    }
  },

  // Nicaragua
  NI: {
    NIO: {
      requirements: [],
      warning: feeWarning('NIO', 'Nicaragua'),
      default: true
    }
  },

  // Netherlands
  NL: {
    ANG: {
      requirements: [],
      warning: feeWarning('ANG', 'Netherlands'),
      default: false
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'NL', 16),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'NL' followed by exactly 16 characters`
        }
      ],
      default: true
    }
  },

  // Nigeria
  NG: {
    NGN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => accountNumber.length === 10,
          validationErrorMessage: `Account number must be exactly 10 characters`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: () => true,
          label: 'Bank Code',
          optional: true
        }
      ],
      default: true
    }
  },

  // Norway
  NO: {
    NOK: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'NO', 13) ||
            hasNumberOfDigits(accountNumber, 7) ||
            hasNumberOfDigits(accountNumber, 11),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'NO' followed by exactly 13 characters OR (iACH) must be either 7 OR 11 digits`
        },
        {
          field: BankTransferField.bankBranchCode,
          validate: (bankBranchCode: string) => hasNumberOfDigits(bankBranchCode, 4),
          validationErrorMessage: `${BankTransferField.bankBranchCode} Bank branch code must be exactly 4 digits.`,
          optional: true
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'NO', 13),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'NO' followed by exactly 13 characters`
        }
      ],
      default: false
    }
  },

  // Nepal
  NP: {
    NPR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 9, 20),
          validationErrorMessage: `Account number must be between 9 and 20 characters`
        },
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      default: true
    }
  },

  // New Zealand
  NZ: {
    NZD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 15, 16),
          validationErrorMessage: `Account number must be 15 or 16 digits made up of a 2-digit bank code, 4-digit branch code, 7-digit account number and 2–3 digit suffix`
        }
      ],
      default: true
    }
  },

  // Oman
  OM: {
    OMR: {
      requirements: [],
      warning: feeWarning('OMR', 'Oman'),
      default: true
    }
  },

  // Panama
  PA: {
    PAB: {
      requirements: [],
      warning: feeWarning('PAB', 'Panama'),
      default: true
    }
  },

  // Peru
  PE: {
    PEN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 20, 26),
          validationErrorMessage: `Account number must be between 20 and 26 characters`
        },
        {
          field: BankTransferField.bankBranchCode,
          label: 'Branch ID',
          validate: (code: string) => valueIsWithinCharacterLimits(code, 3, 3),
          validationErrorMessage: `${BankTransferField.bankBranchCode} must be 3 characters`,
          optional: true
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'Bank code',
          validate: (routingCode: string) =>
            valueIsWithinCharacterLimits(routingCode, 3, 3),
          validationErrorMessage: `Bank code must be 3 characters`,
          optional: true
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => valueIsWithinCharacterLimits(taxId, 8, 11),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be between 8 and 11 characters`
        }
      ],
      default: true
    }
  },

  // French Polynesia
  PF: {
    XPF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'FR', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'FR' followed by exactly 25 characters`
        }
      ],
      warning: feeWarning('XPF', 'French Polynesia'),
      default: true
    }
  },

  // Papua New Guinea
  PG: {
    PGK: {
      requirements: [],
      warning: feeWarning('PGK', 'Papua New Guinea'),
      default: true
    }
  },

  // Philippines
  PH: {
    PHP: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be between 1 and 25 characters`
        },
        {
          field: BankTransferField.bankName,
          validate: (nameOrCode: string) =>
            valueIsWithinCharacterLimits(nameOrCode, 1, 30),
          validationErrorMessage: `${BankTransferField.bankName} must be between 1 and 30 characters`,
          optional: false
        },
        {
          field: BankTransferField.swift,
          label: 'Swift code',
          validate: (code: string) => isValidSwiftCode(code),
          validationErrorMessage: `Swift must be between 8 and 11 digits`
        }
      ],
      default: true
    }
  },

  // Pakistan
  PK: {
    PKR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'PK', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'PK' followed by exactly 22 characters`
        }
      ],
      warning: feeWarning('PKR', 'Pakistan'),
      default: true
    }
  },

  // Poland
  PL: {
    PLN: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 26, 26),
          validationErrorMessage: `Account number must have exactly 26 characters`
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 26, 26),
          validationErrorMessage: `Account number must have exactly 26 characters`
        }
      ],
      default: false
    }
  },

  // Portugal
  PT: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'PT', 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'PT' followed by exactly 23 characters`
        },
        {
          field: BankTransferField.swift,
          validate: (swiftCode: string) => isValidSwiftCode(swiftCode),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`,
          optional: true
        }
      ],
      default: true
    }
  },

  // Paraguay
  PY: {
    PYG: {
      requirements: [
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 20 characters`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) => hasNumberOfDigits(taxId, 6, 9),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be between 6 and 9 digits`
        }
      ],
      warning: feeWarning('PYG', 'Paraguay'),
      default: true
    }
  },

  // Réunion
  RE: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => accountNumber.length === 27,
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be exactly 27 characters`
        }
      ],
      default: true
    }
  },

  // Romania
  RO: {
    RON: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'RO', 22),
          validationErrorMessage: `IBAN must start with 'RO' followed by exactly 22 characters`
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'RO', 22),
          validationErrorMessage: `IBAN must start with 'RO' followed by exactly 22 characters`
        }
      ],
      default: false
    }
  },

  // Russia
  RU: {
    RUB: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 20, 20),
          validationErrorMessage: `Account number must be 20 characters.`
        },
        {
          field: BankTransferField.fullNameOfAccountHolder,
          label: 'Beneficiary Name',
          validate: (fullName: string) => valueIsWithinCharacterLimits(fullName, 1, 50),
          validationErrorMessage: `Beneficiary name must be between 1 and 50 characters`
        },
        {
          field: BankTransferField.swift,
          label: 'BIK Code',
          validate: (code: string) => valueIsWithinCharacterLimits(code, 10, 10),
          validationErrorMessage: `BIK Code must be 10 characters`
        },
        {
          field: BankTransferField.beneficiaryPatronymic,
          validate: (name: string) => valueIsWithinCharacterLimits(name, 3, 30),
          validationErrorMessage: `${BankTransferField.beneficiaryPatronymic} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) =>
            hasNumberOfDigits(taxId, 10, 10) || hasNumberOfDigits(taxId, 12, 12),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId} must be 10 or 12 digits`
        },
        {
          field: BankTransferField.beneficiaryVO,
          validate: (vo: string) => hasNumberOfDigits(vo, 5, 5),
          validationErrorMessage: `${BankTransferField.beneficiaryVO} must be 5 digits`
        },
        {
          field: BankTransferField.purposeOfPayment,
          validate: (purpose: string) => valueIsWithinCharacterLimits(purpose, 3, 50),
          validationErrorMessage: `${BankTransferField.purposeOfPayment} must be between 3 and 50 characters`
        },
        {
          field: BankTransferField.vatAmount,
          label: `${BankTransferField.vatAmount} In Rubles`,
          validate: (amount: number) => amount != null
        },
        {
          field: BankTransferField.vatCode,
          validate: (code: string) => valueIsWithinCharacterLimits(code, 2, 2),
          validationErrorMessage: `${BankTransferField.vatCode} VAT code should be 01 or 02 or 03 or 04 or 05 or 06`
        },
        {
          field: BankTransferField.includeVATax,
          validate: (value: boolean) => value !== null,
          type: 'boolean',
          optional: true
        }
      ],
      warning: sanctionWarning(
        'Transfers to VTB Bank, Bank Otkritie, Novikombank, Promsvyazbank, Rossiya Bank, Sovcombank, and VEB are blocked due to sanctions.'
      )
    }
  },

  // Rwanda
  RW: {
    RWF: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('RWF', 'Rwanda'),
      default: true
    }
  },

  // Qatar
  QA: {
    QAR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'QA', 27),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'QA' followed by exactly 27 characters`
        }
      ],
      warning: feeWarning('QAR', 'Qatar'),
      default: true
    }
  },

  // Serbia
  RS: {
    RSD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'RS', 20),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'RS' followed by exactly 20 characters`
        }
      ],
      warning: feeWarning('RSD', 'Serbia'),
      default: true
    }
  },

  // Saudi Arabia
  SA: {
    SAR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'SA', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format SA followed by 22 characters.`
        }
      ],
      warning: feeWarning('SAR', 'Saudi Arabia'),
      default: true
    }
  },

  // Solomon Islands
  SB: {
    SBD: {
      requirements: [],
      warning: feeWarning('SBD', 'Solomon Islands'),
      default: true
    }
  },

  // Singapore
  SG: {
    SGD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 14),
          validationErrorMessage: `Account number must be between 1 and 14 characters.`
        },
        {
          field: BankTransferField.bankClearingCodeWhenNotIban,
          validate: (clearingCode: string) => {
            return clearingCode?.length === 7;
          },
          validationErrorMessage: `${BankTransferField.bankClearingCodeWhenNotIban} must be exactly 7 characters`
        }
      ],
      default: true
    }
  },

  // San Marino
  SM: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'SM', 25),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format SM followed by 25 characters.`
        }
      ],
      default: true
    }
  },

  // Slovenia
  SI: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'SI', 17),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format SI followed by 17 characters.`
        }
      ],
      default: true
    }
  },

  // Slovak Republic/Slovakia
  SK: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'SK', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format SK followed by 22 characters.`
        }
      ],
      default: true
    }
  },

  // Seychelles
  SC: {
    SCR: {
      requirements: [],
      warning: feeWarning('SCR', 'Seychelles'),
      default: true
    }
  },

  // Sweden
  SE: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'SE', 22),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format SE followed by 22 characters.`
        }
      ],
      default: false
    },
    SEK: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'SE', 22) ||
            hasNumberOfDigits(accountNumber, 12) ||
            hasNumberOfDigits(accountNumber, 17),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format SE followed by 22 characters(IBAN). For (iACH) ${BankTransferField.accountNumberOrIban} must be either 12 or 17 digits.`
        },
        {
          field: BankTransferField.bankBranchCode,
          validate: (branchCode: string) =>
            (branchCode.length === 4 || branchCode.length === 5) &&
            branchCode.match(/^\d+$/) != null,
          validationErrorMessage: `${BankTransferField.bankBranchCode} Bank branch code must be either 4 or 5 digits.`
        }
      ],
      default: true
    }
  },

  // Suriname
  SR: {
    SRD: {
      requirements: [],
      warning: feeWarning('SRD', 'Suriname'),
      default: true
    }
  },

  // El Salvador
  SV: {},

  // Sint Maarten
  SX: {
    ANG: {
      requirements: [],
      warning: feeWarning('ANG', 'Sint Maarten'),
      default: true
    }
  },

  // Sierra Leone
  SL: {
    SLL: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('SLL', 'Sierra Leone'),
      default: true
    }
  },

  // Senegal
  SN: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            hasNumberOfDigits(accountNumber, 24) ||
            (accountNumber.length === 26 &&
              accountNumber.slice(0, 2) === 'SN' &&
              hasNumberOfDigits(accountNumber.slice(2), 24)),
          validationErrorMessage: `Account number must be exactly 24 digits or SN followed by 24 digits`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      warning: feeWarning('XOF', 'Senegal'),
      default: true
    }
  },

  // Eswatini (Swaziland)
  SZ: {
    SZL: {
      requirements: [],
      warning: feeWarning('SZL', 'Eswatini (Swaziland)'),
      default: true
    }
  },

  // Switzerland
  CH: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CH', 19),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format CH followed by 19 characters.`
        }
      ],
      default: false
    },
    CHF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'CH', 19),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format CH followed by 19 characters.`
        }
      ],
      default: true
    }
  },

  // South Korea
  KR: {
    KRW: {
      requirements: [
        {
          field: BankTransferField.accountHolderPhoneNumber,
          label: 'Account number',
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `Account number must be between 1 and 20 characters`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'Bank Code',
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 3, 3),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 3 digits`,
          optional: true
        }
      ],
      default: true
    }
  },

  // St Lucia
  /** Missing from Expa Spreadsheet */
  LC: {
    XCD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'LC', 30),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format LC followed by 30 characters.`
        },
        {
          field: BankTransferField.swift,
          validate: (swiftCode: string) => isValidSwiftCode(swiftCode),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`
        },
        {
          field: BankTransferField.fullNameOfAccountHolder,
          validate: (fullName: string) => valueIsWithinCharacterLimits(fullName, 1, 50),
          validationErrorMessage: `${BankTransferField.fullNameOfAccountHolder} must be between 1 and 50 characters`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 30 characters`
        },
        {
          field: BankTransferField.bankName,
          validate: (nameOrCode: string) =>
            valueIsWithinCharacterLimits(nameOrCode, 1, 30),
          validationErrorMessage: `${BankTransferField.bankName} must be between 1 and 30 characters`
        }
      ],
      warning: feeWarning('XCD', 'St Lucia'),
      default: true
    }
  },

  // Chad
  TD: {
    XAF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'TD', 25) || hasNumberOfDigits(accountNumber, 23),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format TD followed by 25 characters OR 23 digits exactly.`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be checking or savings`
        }
      ],
      warning: feeWarning('XAF', 'Chad'),
      default: true
    }
  },

  // Tajikistan
  TJ: {
    TJS: {
      requirements: [],
      warning: feeWarning('TJS', 'Tajikistan'),
      default: true
    }
  },

  // Turkmenistan
  TM: {
    TMT: {
      requirements: [],
      warning: feeWarning('TMT', 'Turkmenistan'),
      default: true
    }
  },

  // Tonga
  TO: {
    TOP: {
      requirements: [],
      warning: feeWarning('TOP', 'Tonga'),
      default: true
    }
  },

  // Turkey
  TR: {
    TRY: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'IBAN',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'TR', 24),
          validationErrorMessage: `IBAN must be on the format TR followed by 24 characters.`
        }
      ],
      default: true
    }
  },

  // Trinidad and Tobago
  TT: {
    TTD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 1, 17),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be between 1 and 17 digits.`
        },
        {
          field: BankTransferField.bankBranchCode,
          validate: (branchCode: string) =>
            branchCode.length === 8 && branchCode.match(/^\d+$/) != null,
          validationErrorMessage: `${BankTransferField.bankBranchCode} (TTRN) must be exactly 8 digits.`
        }
      ],
      warning: feeWarning('TTD', 'Trinidad and Tobago'),
      default: true
    }
  },

  // Thailand
  TH: {
    THB: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 5, 16),
          validationErrorMessage: `Account number must be between 5 and 16 digits.`
        },
        {
          field: BankTransferField.swift,
          label: 'Swift code',
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `Swift must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`,
          optional: false
        }
      ],
      default: true
    }
  },

  // Taiwan
  TW: {
    TWD: {
      requirements: [],
      warning: feeWarning('TWD', 'Taiwan'),
      default: true
    }
  },

  // Tunisia
  TN: {
    TND: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 1, 34),
          validationErrorMessage: `Account number length must be 1-34 digits`
        },
        {
          field: BankTransferField.bankBranchCode,
          validate: (branchCode: string) =>
            valueIsWithinCharacterLimits(branchCode, 1, 5),
          validationErrorMessage: `${BankTransferField.bankBranchCode} Bank branch code must be between 1 - 5 characters.`
        }
      ],
      default: true
    }
  },

  // Togo
  TG: {
    XOF: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'TG', 26) || hasNumberOfDigits(accountNumber, 24),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} (IBAN) must start with 'TG' followed by exactly 26 characters OR entire length must be exactly 24 digits`
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountType} must be checking or savings`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            routingOrBankBranchCode.length === 4,
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 4 characters`
        }
      ],
      default: true
    }
  },

  // Tanzania
  TZ: {
    TZS: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      default: true
    }
  },

  // Ukraine
  UA: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => isValidIban(accountNumber, 'UA', 27),
          validationErrorMessage: `Account number must start with 'UA' followed by exactly 27 characters`
        }
      ],
      warning: feeWarning('EUR', 'Ukraine'),
      default: true
    }
  },

  // United Kingdom
  GB: {
    GBP: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 8),
          validationErrorMessage: `Bank account number must be 8 digits`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'Sort code',
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 6),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 6 digits`
        }
      ],
      default: true
    },
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            isValidIban(accountNumber, 'GB', 20) || hasNumberOfDigits(accountNumber, 8),
          validationErrorMessage: `Account number (IBAN) must start with 'GB' followed by exactly 20 characters`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'Sort code',
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 6),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 6 digits`,
          optional: true
        }
      ],
      default: false
    }
  },

  // Venezuela
  VE: {},

  // Vietnam
  VN: {
    VND: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 34),
          validationErrorMessage: `Account number must be between 1 and 34 characters.`
        },
        {
          field: BankTransferField.swift,
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`
        },
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      default: true
    }
  },

  // Vanuatu
  VU: {
    VUV: {
      requirements: [],
      warning: feeWarning('VUV', 'Vanuatu'),
      default: true
    }
  },

  // Samoa
  WS: {
    WST: {
      requirements: [],
      warning: feeWarning('WST', 'Samoa'),
      default: true
    }
  },

  // Uganda
  UG: {
    UGX: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 8, 20),
          validationErrorMessage: `Account number must be between 8 and 20 digits`
        },
        {
          field: BankTransferField.swift,
          validate: (swiftCode: string) => isValidSwiftCode(swiftCode),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`,
          optional: true
        }
      ],
      default: true
    }
  },

  // United States of America
  US: {
    USD: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) =>
            valueIsWithinCharacterLimits(accountNumber, 1, 17),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be between 1 and 17 characters`,
          optional: false
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'ABA Routing Number',
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 9),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be exactly 9 digits`,
          optional: false
        },
        {
          field: BankTransferField.accountType,
          validate: (accountType: string) => isCheckingOrSaving(accountType),
          validationErrorMessage: `${BankTransferField.accountType} must be checking or savings`,
          optional: false
        }
      ],
      default: true
    }
  },

  // Uruguay
  UY: {
    UYU: {
      requirements: [
        {
          field: BankTransferField.bankBranchCode,
          validate: (branchCode: string) =>
            valueIsWithinCharacterLimits(branchCode, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchCode} Bank branch code must be between 3 and 30 characters.`
        },
        {
          field: BankTransferField.beneficiaryTaxId,
          validate: (taxId: string) =>
            hasNumberOfDigits(taxId, 8) || hasNumberOfDigits(taxId, 12),
          validationErrorMessage: `${BankTransferField.beneficiaryTaxId}(RUC/CI) must be either 8(CI) and 12(RUC) digits`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          validate: (routingOrBankBranchCode: string) =>
            hasNumberOfDigits(routingOrBankBranchCode, 3) ||
            hasNumberOfDigits(routingOrBankBranchCode, 7),
          validationErrorMessage: `${BankTransferField.routingOrBankBranchCode} must be either 3 or 7 digits`
        }
      ],
      default: true
    }
  },

  // Uzbekistan
  UZ: {
    UZS: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('UZS', 'Uzbekistan'),
      default: true
    }
  },

  // Kosovo
  XK: {
    EUR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          validate: (accountNumber: string) => isValidIban(accountNumber, 'XK', 18),
          validationErrorMessage: `${BankTransferField.accountNumberOrIban} must be on the format XK followed by 18 characters.`
        }
      ],
      default: true
    }
  },

  // Yemen
  YE: {
    YER: {
      requirements: [
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        }
      ],
      warning: feeWarning('YER', 'Yemen'),
      default: true
    }
  },

  // South Africa
  ZA: {
    ZAR: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account Number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 1, 17),
          validationErrorMessage: `Account Number must be between 1 and 17 digits`
        },
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.accountHolderPhoneNumber,
          validate: (phoneNumber: string) =>
            valueIsWithinCharacterLimits(phoneNumber, 1, 20),
          validationErrorMessage: `${BankTransferField.accountHolderPhoneNumber} must be between 1 and 30 characters`
        },
        {
          field: BankTransferField.swift,
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`
        }
      ],
      default: true
    }
  },

  // Zambia
  ZM: {
    ZMW: {
      requirements: [
        {
          field: BankTransferField.accountNumberOrIban,
          label: 'Account Number',
          validate: (accountNumber: string) => hasNumberOfDigits(accountNumber, 8, 20),
          validationErrorMessage: `Account Number must be between 8 and 20 digits`
        },
        {
          field: BankTransferField.bankBranchName,
          validate: (bankBranchName: string) =>
            valueIsWithinCharacterLimits(bankBranchName, 3, 30),
          validationErrorMessage: `${BankTransferField.bankBranchName} must be between 3 and 30 characters`
        },
        {
          field: BankTransferField.routingOrBankBranchCode,
          label: 'Bank Code',
          validate: (code: string) => valueIsWithinCharacterLimits(code, 6, 6),
          validationErrorMessage: `Bank code must be 6 digits`
        },
        {
          field: BankTransferField.swift,
          validate: (swift: string) => isValidSwiftCode(swift),
          validationErrorMessage: `Swift/BIC must be 8 or 11 characters long, composed of a 4-letter bank code, 2-letter country code, 2-character location code, and an optional 3-character branch code.`
        }
      ],
      default: true
    }
  }
};

const alternativeCurrenciesAvailable = ['USD', 'EUR'];

Object.entries(SUPPORTED_COUNTRIES_AND_CURRENCIES).forEach(
  ([countryCode, countryCurrencies]) => {
    const currenciesIncluded = Object.keys(countryCurrencies);
    const defaultCurrencyRequirements =
      Object.values(SUPPORTED_COUNTRIES_AND_CURRENCIES[countryCode]).filter(
        (it) => it.default
      )[0]?.requirements || [];
    alternativeCurrenciesAvailable.forEach((currencyCode) => {
      if (!currenciesIncluded.includes(currencyCode)) {
        SUPPORTED_COUNTRIES_AND_CURRENCIES[countryCode][currencyCode] = {
          requirements: defaultCurrencyRequirements,
          default: false,
          warning: feeWarning(currencyCode, countryCode)
        };
      }
    });
  }
);

export const getWarningForCurrencyAndCountry = (
  currencyCode: string,
  countryCode: string
): CurrencyWarning | null | CurrencyWarning[] =>
  SUPPORTED_COUNTRIES_AND_CURRENCIES[countryCode]?.[currencyCode]?.warning ?? null;

export const getFieldsForCurrencyAndCountry = (
  currencyCode: string,
  countryCode: string
): BankTransferRequirement[] => {
  const processedFields = new Set();

  const specificRequirements =
    SUPPORTED_COUNTRIES_AND_CURRENCIES[countryCode]?.[currencyCode]?.requirements || [];
  specificRequirements.forEach((requirement: BankTransferRequirement) => {
    processedFields.add(requirement.field);
  });

  return specificRequirements.concat(
    commonCurrencyFields.filter((requirement) => !processedFields.has(requirement.field))
  );
};

export const getSupportedCountries = COUNTRIES.filter((country) =>
  Object.keys(SUPPORTED_COUNTRIES_AND_CURRENCIES)?.includes(country?.value)
);

export const getSupportedCurrenciesForCountryDropdown = (countryCode: string) =>
  Object.keys(SUPPORTED_COUNTRIES_AND_CURRENCIES[countryCode] || {}).map(
    (currency: string) => {
      return { value: currency, label: currency };
    }
  );
