import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get, post, del, postMultipart } from 'api/fetch';
import { getFieldsForCurrencyAndCountry } from '../../../assets/currencies';
import { fetchClaim } from './index-ts';

/**
 * Do not add to this file, instead move / add any required functionality to index-ts.
 * Importing js createAsyncThunk files into ts components causes the types to buggy and ts-ignore to be everywhere.
 * Once this file is empty, we can rename index-ts to index.ts and remove this file.
 */

export const initiateClaim = createAsyncThunk(
  'remote_health/claim/initiateClaim',
  async (claimValues) => {
    const claim = await post(
      '/claim/initiate/v2',
      {
        claimant: claimValues.userId,
        claimType: 'MEDICAL', // TODO maybe do sth about it, it's actually useless and the value is incorrect anyway
        expaClaimType: claimValues.claimType ?? 'MEDICAL',
        product: 'REMOTE_HEALTH',
        benefit: claimValues.benefit
      },
      { api: 'rh' }
    );

    const { id: claimId, updateBankingDetails } = claim;

    return {
      ...claimValues,
      claimId,
      claimant: claimValues.userId,
      updateBankingDetails
    };
  }
);

const convertCommentToStore = (shouldUseComment, comment) => {
  if (shouldUseComment === null) {
    return '-';
  }
  if (shouldUseComment) {
    return comment;
  }
  return 'no';
};

export const editClaim = createAsyncThunk(
  'remote_health/claim/editClaim',
  async (editedInfo) => {
    const {
      claimant,
      claimId,
      userId,
      description,
      isPreEx,
      preExistingCondition,
      isOtherParties,
      otherParties,
      bankDetails,
      incidentCountry,
      claimType,
      ...rest
    } = editedInfo;
    await post(
      `/claim/update`,
      {
        ...rest,
        incidentCountry: incidentCountry === '' ? null : incidentCountry,
        happenedComment: description || '-',
        startComment: convertCommentToStore(isPreEx, preExistingCondition),
        otherPartiesComment: convertCommentToStore(isOtherParties, otherParties),
        detailedClaimType: claimType,
        expaClaimType: claimType,
        id: claimId,
        claimant
      },
      { api: 'rh' }
    );

    return editedInfo;
  }
);

export const toggleClaimBankingUpdate = createAsyncThunk(
  'remote_health/claim/disableBankingUpdate',
  async ({ claimId, toggleValue }) => {
    await post(
      `/claim/update`,
      {
        id: claimId,
        updateBankingDetails: toggleValue
      },
      { api: 'rh' }
    );
  }
);

export const sendFile = createAsyncThunk(
  'remote_health/claim/sendFile',
  async ({ file, claimId }, { rejectWithValue }) => {
    try {
      const { id, fileSize } = await postMultipart('/insurance/claims/file/upload', {
        claimId,
        file,
        fileType: file?.type,
        fileName: file.name
      });
      return { id, name: file.name, fileSize };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeFile = createAsyncThunk(
  'remote_health/claim/removeFile',
  async (fileId, { rejectWithValue }) => {
    try {
      await del(`/insurance/claims/file/delete?claimFileId=${fileId}`);
      return fileId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBankingDetails = createAsyncThunk(
  'remote-health/claim/getBankingDetails',
  async () => {
    return get('/claim/bank-details', { api: 'rh' });
  }
);

export const areBankDetailsOptional = createAsyncThunk(
  'remote-health/claim/bankDetailsOptional',
  async () => {
    return get(`/claim/bank-details-optional`, { api: 'rh' });
  }
);

const cleanData = (values) => {
  const cleanedValues = { ...values };
  Object.keys(cleanedValues).forEach((key) => {
    const value = cleanedValues[key];
    if (typeof value === 'object' && value !== null) {
      cleanedValues[key] = cleanData(value); // Call function recursively for nested objects
    } else if (value === '' || value === false) {
      delete cleanedValues[key];
    }
  });
  return cleanedValues;
};

export const updateBankingDetails = createAsyncThunk(
  'remote-health/claim/reimbursement',
  async ({ bankDetails }) => {
    const cleanBankDetails = cleanData(bankDetails);
    /**
     * Since there are two form different fields that map to the same payload property
     * we need to determine which one to use. We are using a magic string
     * since we the value is stored in an enum and this file is still not typescript
     */
    const usesBankClearingCode =
      getFieldsForCurrencyAndCountry(
        cleanBankDetails.reimbursementCurrency,
        cleanBankDetails.bankCountry
      ).filter((field) => field.field === 'Bank clearing code*').length > 0;
    const payload = {
      displayName: 'default',
      id: cleanBankDetails.id,
      reimbursementCurrency: cleanBankDetails.reimbursementCurrency,
      fullNameOfAccountHolder: cleanBankDetails.fullNameOfAccountHolder,
      accountHolderPhoneNumber: cleanBankDetails.accountHolderPhoneNumber,
      accountNumberOrIban: cleanBankDetails.accountNumberOrIban,
      routingOrBankBranchCode: usesBankClearingCode
        ? cleanBankDetails.bankClearingCodeWhenNotIban
        : cleanBankDetails.routingOrBankBranchCode,
      swift: cleanBankDetails.swift,
      bankName: cleanBankDetails.bankName,
      bankCity: cleanBankDetails.bankCity,
      bankCountry: cleanBankDetails.bankCountry,
      beneficiaryTaxId: cleanBankDetails.beneficiaryTaxId,
      beneficiaryNationalId: cleanBankDetails.beneficiaryNationalId,
      bankBranchName: cleanBankDetails.bankBranchCode,
      accountType: cleanBankDetails.accountType,
      indianFinancialSystemCode: cleanBankDetails.indianFinancialSystemCode,
      bankClearingCodeWhenNotIban: cleanBankDetails.clearingCode,
      beneficiaryVO: cleanBankDetails.beneficiaryVO,
      /**
       * Grab from bankDetails and not cleanBankDetails since cleanBankDetails removes false values
       */
      includeVATax:
        bankDetails.includeVATax !== null &&
        bankDetails.includeVATax !== undefined &&
        cleanBankDetails.reimbursementCurrency === 'RUB'
          ? bankDetails.includeVATax
          : null,
      purposeOfPayment: cleanBankDetails.purposeOfPayment,
      vatAmount: cleanBankDetails.vatAmount,
      vatCode: cleanBankDetails.vatCode,
      beneficiaryPatronymic: cleanBankDetails.beneficiaryPatronymic,
      beneficiaryCPF: cleanBankDetails.beneficiaryCPF,
      beneficiaryCNPJ: cleanBankDetails.beneficiaryCNPJ,
      beneficiaryKNP: cleanBankDetails.beneficiaryKNP,
      beneficiaryKBE: cleanBankDetails.beneficiaryKBE,
      agencyCode: cleanBankDetails.agencyCode,
      curpNumber: cleanBankDetails.curpNumber,
      bankCode: cleanBankDetails.bankCode
    };
    await post('/claim/update-bank-details', payload, { api: 'rh' });
    return payload;
  }
);

const parseBankDetails = (payload) => {
  return {
    ...payload,
    bankClearingCodeWhenNotIban: payload.routingOrBankBranchCode
  };
};

const initialState = {
  policyID: '',
  prefillRequired: true,
  submitClaimIsPending: false,
  initiateClaimIsPending: false,
  editClaimIsPending: false,
  sendFileIsPending: false,
  removeFileIsPending: false,
  bankDetailsIsPending: false,
  bankDetailsFetched: false,
  bankOptionalFlagIsPending: false,
  loadingQueue: [],
  error: false,
  editError: false,
  documentError: null,
  claimSubmitted: false,
  requestStarted: false,
  bankOptional: false,
  bankAccounts: {
    accounts: null,
    activeBankDetailsId: null
  },
  claimInfo: {
    claimId: null,
    claimant: null,
    claimantFirstName: '',
    claimantLastName: '',
    claimType: 'MEDICAL',
    incidentCountry: '',
    incidentCity: '',
    claimAmount: '',
    claimCurrency: '',
    dateOfService: null,
    preExistingCondition: '',
    description: '',
    isOtherParties: null,
    otherParties: '',
    reimbursementCurrency: '',
    claimFiles: [],
    bankDetails: {
      fullNameOfAccountHolder: '',
      accountHolderPhoneNumber: '',
      accountNumberOrIban: '',
      routingOrBankBranchCode: '',
      swift: '',
      bankName: '',
      bankCity: '',
      bankCountry: '',
      reimbursementCurrency: '',
      beneficiaryTaxId: '',
      beneficiaryNationalId: '',
      bankBranchName: '',
      accountType: '',
      bankClearingCodeWhenNotIban: '',
      indianFinancialSystemCode: '',
      beneficiaryVO: '',
      includeVATax: false,
      purposeOfPayment: '',
      vatAmount: '',
      vatCode: '',
      beneficiaryPatronymic: ''
    },
    consent: {
      truthConsent: false,
      infoShareConsent: false,
      processingConsent: false
    }
  },
  validSteps: []
};

const slice = createSlice({
  name: 'remote_health/claims',
  initialState,
  reducers: {
    setClaimInfo: (state, { payload }) => {
      state.claimInfo = { ...state.claimInfo, ...payload };
    },
    clearClaimInfo: (state) => {
      /**
       * Since we set the date of service based on contract dates
       * we won't reset it to reduce queiries and loading
       */
      state.claimInfo = {
        ...initialState.claimInfo,
        dateOfService: state.claimInfo.dateOfService
      };
    },
    addValidStep: (state, { payload }) => {
      if (state.validSteps.length > 0) {
        if (!state.validSteps.find((x) => x === payload)) {
          state.validSteps = [...state.validSteps, payload];
        } else {
          return state;
        }
      } else {
        state.validSteps = [payload];
      }
    },
    removeValidStep: (state, { payload }) => {
      state.validSteps = state.validSteps.filter((x) => x !== payload);
    },
    resetClaimError: (state) => {
      state.error = null;
    },
    submitClaimRequest: (state) => {
      state.submitClaimIsPending = true;
      state.error = null;
    },
    submitClaimSuccess: (state) => {
      state.submitClaimIsPending = false;
      state.error = null;
      state.claimSubmitted = true;
    },
    submitClaimFailure: (state) => {
      state.submitClaimIsPending = false;
      state.error = 'Unknown error submitting claim';
    },
    clearErrors: (state) => {
      state.error = false;
      state.documentError = false;
      state.editError = false;
    },
    resetRequestState: (state) => {
      state.requestStarted = false;
    },
    prefillClaim: (state, { payload }) => {
      const {
        startComment,
        happenedComment,
        otherPartiesComment,
        insuranceContractID,
        id,
        userID,
        claimStatus,
        claimType,
        detailedClaimType,
        claimant,
        ...rest
      } = payload;
      state.claimInfo = { ...state.claimInfo, ...rest };
      state.claimInfo.claimant = claimant || userID;
      state.claimInfo.claimId = id;

      state.claimInfo.claimType =
        detailedClaimType !== 'Unknown' ? detailedClaimType : claimType;
      state.claimInfo.description = happenedComment === '-' ? '' : happenedComment;

      const isOtherPartiesSet = otherPartiesComment !== '-';
      const isOtherParties = isOtherPartiesSet ? otherPartiesComment !== 'no' : null;
      state.claimInfo.isOtherParties = isOtherParties;
      state.claimInfo.otherParties = isOtherParties ? otherPartiesComment : '';
      state.requestStarted = false;
    }
  },
  extraReducers: {
    [initiateClaim.pending]: (state) => {
      state.prefillRequired = false;
      state.requestStarted = true;
      state.initiateClaimIsPending = true;
      state.error = null;
    },
    [initiateClaim.fulfilled]: (state, { payload }) => {
      state.claimInfo = { ...state.claimInfo, ...payload };
      state.initiateClaimIsPending = false;
      state.error = null;
    },
    [initiateClaim.rejected]: (state, { error }) => {
      state.initiateClaimIsPending = false;
      state.error = error?.message || true;
    },
    [fetchClaim.fulfilled]: (state, { payload }) => {
      state.claimInfo = { ...state.claimInfo, claimId: payload.id, ...payload };
      state.prefillRequired = false;
    },
    [editClaim.pending]: (state) => {
      state.requestStarted = true;
      state.editClaimIsPending = true;
      state.editError = null;
    },
    [editClaim.fulfilled]: (state, { payload }) => {
      state.claimInfo = { ...payload };
      state.editClaimIsPending = false;
      state.editError = null;
    },
    [editClaim.rejected]: (state, { error }) => {
      state.editClaimIsPending = false;
      state.editError = error?.message || true;
    },
    [toggleClaimBankingUpdate.pending]: (state) => {
      state.requestStarted = true;
      state.editClaimIsPending = true;
      state.editError = null;
    },
    [toggleClaimBankingUpdate.fulfilled]: (state) => {
      state.editClaimIsPending = false;
      state.editError = null;
    },
    [toggleClaimBankingUpdate.rejected]: (state, { error }) => {
      state.editClaimIsPending = false;
      state.editError = error?.message || true;
    },
    [sendFile.pending]: (state) => {
      state.sendFileIsPending = true;
    },
    [sendFile.fulfilled]: (state, { payload }) => {
      state.claimInfo.claimFiles = [
        ...state.claimInfo.claimFiles,
        {
          id: payload.id,
          userFileName: payload.name,
          fileSize: payload.fileSize
        }
      ];
      state.sendFileIsPending = false;
      state.documentError = null;
    },
    [sendFile.rejected]: (state, { payload }) => {
      state.sendFileIsPending = false;
      state.documentError = payload.message;
    },
    [removeFile.pending]: (state, { meta }) => {
      state.deletingFileID = meta.arg;
      state.removeFileIsPending = true;
      state.documentError = null;
    },
    [removeFile.fulfilled]: (state, { payload }) => {
      state.claimInfo.claimFiles = state.claimInfo.claimFiles.filter(
        (file) => file.id !== payload
      );
      state.removeFileIsPending = false;
      state.documentError = null;
    },
    [removeFile.rejected]: (state, { payload }) => {
      state.removeFileIsPending = false;
      state.documentError = payload.message;
    },
    [updateBankingDetails.pending]: (state) => {
      state.requestStarted = true;
      state.bankDetailsIsPending = true;
      state.error = null;
    },
    [updateBankingDetails.fulfilled]: (state, { payload }) => {
      state.bankDetailsIsPending = false;
      state.error = null;
      state.claimInfo.bankDetails = parseBankDetails(payload);
    },
    [updateBankingDetails.rejected]: (state, { error }) => {
      state.error = error?.message || true;
      state.bankDetailsIsPending = false;
    },
    [getBankingDetails.fulfilled]: (state, { payload }) => {
      state.bankDetailsIsPending = false;
      state.error = null;
      state.bankDetailsFetched = true;
      state.claimInfo.bankDetails = parseBankDetails(payload);
    },
    [getBankingDetails.rejected]: (state) => {
      state.bankDetailsIsPending = false;
      state.bankDetailsFetched = true;
    },
    [getBankingDetails.pending]: (state) => {
      state.bankDetailsIsPending = true;
      state.error = null;
    },
    [areBankDetailsOptional.fulfilled]: (state, { payload }) => {
      state.bankDetailsOptional = payload;
      state.bankOptionalFlagIsPending = false;
    },
    [areBankDetailsOptional.rejected]: (state, { error }) => {
      state.error = error?.message || true;
      state.bankOptionalFlagIsPending = false;
    },
    [areBankDetailsOptional.pending]: (state) => {
      state.bankOptionalFlagIsPending = true;
    }
  }
});

export const {
  resetClaimError,
  prefillClaim,
  resetRequestState,
  addValidStep,
  removeValidStep,
  setClaimInfo,
  clearClaimInfo,
  ...actions
} = slice.actions;

export const submitClaim = (claimId) => async (dispatch) => {
  dispatch(actions.submitClaimRequest());

  try {
    await post('/insurance/claims/submit', {
      id: claimId
    });

    dispatch(actions.submitClaimSuccess());
  } catch (error) {
    dispatch(actions.submitClaimFailure());
  }
};

export const clearAllErrors = () => async (dispatch) => {
  dispatch(actions.clearErrors());
};

export const isLoading = (state) => {
  return (
    state.remoteHealth.claims.submitClaimIsPending ||
    state.remoteHealth.claims.initiateClaimIsPending ||
    state.remoteHealth.claims.editClaimIsPending ||
    state.remoteHealth.claims.sendFileIsPending ||
    state.remoteHealth.claims.removeFileIsPending ||
    state.remoteHealth.claims.bankDetailsIsPending ||
    state.remoteHealth.claims.length > 0
  );
};

export default slice.reducer;
