export * from './lib';

/**
 * @deprecated
 */
const fontSizes = [12, 14, 16, 18, 20, 24, 32, 38, 40, 48, 56, 62, 70];

/**
 * @deprecated
 */
const space = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60];

export const shadows = {
  tiny: '0px 1px 2px #0000001a',
  small: '0px 3px 6px #00000029',
  large: '0px 8px 32px rgba(15, 23, 42, 0.08)'
} as const;

// All the code that controls vertical stacking is in one place. The idea being
// that you could slip something in between later if need be, which you couldn't
// if you did 1, 2, 3, etc, because z-index doesn't support decimals.
export const zIndexes = {
  notification: 9000,
  overlay: 8000,
  modal: 7000,
  navigation: 6000,
  dropdown: 5000,
  finchConnect: 5001,
  background: 0
} as const;

const baseColors = {
  trueBlack: '#000',
  black: '#273C49',
  lightBlack: '#385264',
  fiord: '#3B515F',
  white: '#FFFFFF',

  primaryText: '#FFFFFF',

  turquoise: '#5CC6C7',
  fadedTurquoise: '#7eb1b7',
  cyan: '#71DCDD',
  mint: '#B8F1F1',
  dew: '#EAFFFF',
  jaggedIce: '#C2EAEA',

  // blues
  blue: '#50AEEB',
  lightBlueGray: '#77A6E2',
  blueGrayDark3: '#3E4756',
  blueGray: '#64748B',
  blueGray2: '#CFD5DD',
  blueGray3: '#AFB8C5',
  blueGray4: '#73839a',
  blueGray45: '#E7EAEE',
  blueGray5: `#F1F5F9`,
  blueGray5Old: `#C7C7ff`,
  blueGrayMin2: `#4A5667`,
  grayBlue: '#AFB8C5',
  steelBlue: '#477ab9',
  aliceBlue: '#EBF4FF',
  pictonBlue: '#5B8DCB',
  pictonBlue45: '#F5FBFF',
  chambrayBlue: '#35587D',
  cornflowerBlue: '#84ade0',
  regentBlue: '#99bae2',
  frenchBlue: '#BDDBFF',
  danubeBlue: '#5B8DCB',
  jordyBlue: '#77A6E2',
  indigoBlue: '#4982C9',
  blueBayoux: '#4c6372',
  fadedBlue: '#87B1E5',
  keppelBlue: '#399e9e',
  blueGrayPlus2: '#8593A7',

  // new blues
  newBlueLight4: '#EFF5FE',
  newBlueLight3: '#BAD3FC',
  newBlue: '#3B82F6',
  newBlueDark4: '#0847AF',

  // pink, red, nude and orange
  nude: '#EFDAC4',
  mutedOrange: '#EFC89F',
  orange: '#EDAD68',
  orange400: '#F0B359',
  orange800: '#5E4013',
  darkOrange: '#DB9D5A',

  red: '#C76358',
  lightRed: '#fef2f2',
  darkRed: '#B75B51',
  mutedRed: '#ff9289',
  darkRed4: '#601B1B',
  lightRed4: '#FCDADA',
  rhRed: '#EF4444',

  newRedLight4: '#FEF2F2',
  newRedLight3: '#F9BEBE',
  newRed: '#EF4444',
  newRedDark1: '#DA3737',
  newRedDark2: '#AD3030',
  newRedDark4: '#601B1B',

  pink: '#E1718B',
  lightPink: '#FFEDF1',
  mediumPink: '#F298AE',
  sweetPink: '#FA9FB4',
  darkPink: '#DB6580',

  // yellow
  yellow: '#EDCE68',
  lightYellow: '#ffe9b8',

  newYellowLight4: '#FDE8C5',
  newYellowLight3: '#FAD18A',
  newYellow: '#F59E0B',
  newYellowDark4: '#805305',

  // purples
  minskLight: '#4545A2',
  minsk: '#333377',
  minskDark: '#21214C',

  indigoLigth4: '#E1E1F4',
  indigoLigth3: '#B7B7E5',
  indigoLigth2: '#8383D2',
  indigoLigth1: '#6C6CCA',
  indigo: '#5656C2',
  indigoDark1: '#4242B7',
  indigoDark2: '#3A3AA1',
  indigoDark3: '#32328A',
  indigoDark4: '#2A2A74',

  indigo50: '#EEEEFC',
  indigo100: '#E1E1F4',
  indigo200: '#B7B7E5',

  blueVioletLight: '#6C6CC9',
  blueViolet: '#4343B5',
  blueVioletDark: '#323288',

  portGoreLight: '#353586',
  portGore: '#24245A',
  portGoreDark: '#13132E',

  purpleLight4: '#E4DAFD',
  purpleLight3: '#B496F9',
  purple: '#8B5CF6',
  purpleDark4: '#460BCC',

  // greens
  mintGreen: '#93FFB3',
  bermudaGreen: '#7ADDB6',
  pastelGreen: '#65E87E',
  chateauGreen: '#3FB254',
  charlotte: '#B2F8F8',

  bermudaLight4: '#E3F8F0',
  bermudaLight3: '#C7F0E0',
  bermudaLight2: '#ABE9D1',
  bermudaLight1: '#92E3C3',
  bermuda: '#7ADDB6',
  bermudaDark1: '#62D7A9',
  bermudaDark2: '#49D19B',
  bermudaDark3: '#33C88D',
  bermudaDark4: '#2DB07C',

  sushiLight4: '#E3F3D8',
  sushiLight3: '#C6E6B0',
  sushiLight2: '#AADA89',
  sushiLight1: '#8DCD61',
  sushi: '#71C13A',
  sushiDark1: '#5A9A2E',
  sushiDark2: '#447423',
  sushiDark3: '#2D4D17',
  sushiDark4: '#17270C',

  // grays
  galleryGray: '#F0F0F0',
  silverGray: '#C4C4C4',
  silverLightGray: '#BEBEBE',
  silverChaliceGray: '#A2A2A2',
  doveGray: '#707070',
  regentGray: '#939EA4',
  darkSlateGray: '#364c5a',

  pickledBluewoodLight45: '#FAFAFB',
  pickledBluewoodLight4: '#F4F5F6',
  pickledBluewoodLight35: '#E9ECED',
  pickledBluewoodLight3: '#A9B1B6',
  pickledBluewoodLight2: '#7D8A92',
  pickledBluewoodLight1: '#52636D',
  pickledBluewood: '#273C49',
  pickledBluewoodDark1: '#1F303A',
  pickledBluewoodDark2: '#17242C',
  pickledBluewoodDark3: '#10181D',

  bluewood: '#273C49',
  bluewood200: '#D4D8DB',
  bluewood300: '#A0AEC0',
  bluewood500: '#4A5568',

  fountain: '#3BAAAB',

  // Blush
  deepBlushLight4: '#F8DEE4',
  deepBlushLight2: '#ECA4B4',
  deepBlush: '#E1718B',
  deepBlushDark2: '#D63E62',
  deepBlushDark4: '#B22646',

  transparentWhite: [...new Array(10).keys()].map(
    // eg. transparentWhite.9 has opacity 0.9
    (i) => `rgba(255, 255, 255, ${i / 10})`
  ),
  transparentBlack: [...new Array(10).keys()].map(
    // eg. transparentBlack.9 has opacity 0.9
    (i) => `rgba(39, 60, 73, ${i / 10})`
  )
} as const;

// global button styles
/**
 * @deprecated
 */
const darkButton = {
  default: baseColors.black,
  hover: '#385264',
  active: '#627886',
  text: baseColors.white
};

/**
 * @deprecated
 */
const flatButton = {
  hover: '#E1E1F4'
};

/**
 * @deprecated
 */
const dangerButton = {
  default: baseColors.red,
  hover: '#E07266',
  active: '#EA8C81',
  text: baseColors.white
};

/**
 * @deprecated
 */
const successButton = {
  default: baseColors.turquoise,
  hover: '#85E2E3',
  active: '#B2F8F8',
  text: baseColors.black
};

/**
 * @deprecated
 */
const disabledButton = {
  default: baseColors.silverGray,
  hover: baseColors.silverGray,
  active: baseColors.silverGray,
  text: baseColors.black
};

/**
 * @deprecated
 */
const primaryButton = {
  default: baseColors.turquoise,
  hover: '#85E2E3',
  active: '#B2F8F8',
  text: baseColors.black
};

// links
/**
 * @deprecated
 */
const darkLink = {
  default: baseColors.black,
  hover: baseColors.white
};

/**
 * @deprecated
 */
const lightLink = {
  default: baseColors.white,
  hover: baseColors.black
};

/**
 * @deprecated
 */
const primaryLink = {
  default: baseColors.turquoise,
  hover: '#85E2E3'
};

// modal
const modal = {
  /**
   * @deprecated
   */
  modalBg: baseColors.white,
  /**
   * @deprecated
   */
  modalHeaderBg: baseColors.white,
  /**
   * @deprecated
   */
  modalText: baseColors.black,
  /**
   * @deprecated
   */
  backdrop: 'rgba(39, 60, 73, 0.9)'
};

const toast = {
  info: {
    bg: '#E3F2FC',
    fg: '#50AEEB',
    text: baseColors.pickledBluewoodDark2
  },
  success: {
    bg: '#E3F3D8',
    fg: '#71C13A',
    text: baseColors.pickledBluewoodDark2
  },
  warning: {
    bg: '#FBECD5',
    fg: '#ECA02F',
    text: baseColors.pickledBluewoodDark2
  },
  error: {
    bg: '#FBDEDA',
    fg: '#EB5A47',
    text: baseColors.pickledBluewoodDark2
  }
} as const;

export const colors = {
  ...baseColors,
  toast,
  /**
   * @deprecated
   */
  darkButton,
  /**
   * @deprecated
   */
  flatButton,
  /**
   * @deprecated
   */
  dangerButton,
  /**
   * @deprecated
   */
  successButton,
  /**
   * @deprecated
   */
  disabledButton,
  /**
   * @deprecated
   */
  primaryButton,
  /**
   * @deprecated
   */
  darkLink,
  /**
   * @deprecated
   */
  lightLink,
  /**
   * @deprecated
   */
  primaryLink,

  ...modal
} as const;

/**
 * @deprecated ?
 */
const fonts = {
  headings: 'Times, serif',
  body: '"Helvetica Now", "Helvetica", Arial, sans-serif'
};

const baseTheme = {
  colors,
  fonts,
  shadows,
  space,
  fontSizes,
  zIndexes
};

/**
 * @deprecated ?
 */
export const remoteHealthColors = {
  primaryText: colors.white,
  backdrop: 'rgba(36, 36, 90, 0.9)',

  // modal
  modalBg: { primary: colors.blueViolet, secondary: colors.white },
  modalText: { light: colors.white, dark: colors.black },
  modalHeaderBg: colors.indigo,

  // links
  primaryLink: {
    default: colors.bermudaGreen,
    hover: '#A8E8D0'
  },

  secondaryLink: {
    default: colors.indigo,
    hover: '#9393C4'
  },

  countrySelect: {
    text: colors.white,
    dropdown: '#5a5b84',
    dropdownHighlight: 'rgba(51, 51, 119, 0.75)'
  },

  // buttons
  primaryButton: {
    default: colors.bermudaGreen,
    hover: '#A8E8D0',
    active: '#CAEBDE',
    text: colors.black
  },

  secondaryButton: {
    default: colors.indigo,
    hover: '#6C69D8',
    active: '#8581DD',
    text: colors.white
  }
} as const;

/**
 * @deprecated theme is not always used and isn't type safe.
 */
const theme = {
  ...baseTheme,
  colors: {
    ...colors,
    ...remoteHealthColors
  }
};

export default theme;
