import { type FC } from 'react';
import styled from 'styled-components';
import { ToastType } from '../../types';

type IconProps = Omit<
  JSX.IntrinsicElements['svg'],
  'ref' | 'width' | 'height' | 'viewBox' | 'fill' | 'xmlns'
> & {
  type: ToastType;
};

const Wrapper = styled.svg.attrs({
  width: '20',
  height: '20',
  viewBox: '0 0 20 20',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg'
})`
  display: block;
  width: 20px;
  height: 20px;

  & > path {
    fill: var(--toast-fg);
  }
`;

const Icon: FC<IconProps> = ({ type, ...props }) => {
  switch (type) {
    case 'success':
      return (
        <Wrapper {...props}>
          <path d="M14.1665 2.78334C15.4234 3.50905 16.469 4.55045 17.1997 5.80442C17.9304 7.0584 18.321 8.48148 18.3327 9.93279C18.3444 11.3841 17.9769 12.8133 17.2665 14.0789C16.5561 15.3445 15.5275 16.4027 14.2825 17.1486C13.0375 17.8945 11.6193 18.3024 10.1683 18.3317C8.7172 18.3611 7.28363 18.011 6.00947 17.316C4.7353 16.6211 3.66473 15.6054 2.90373 14.3696C2.14272 13.1338 1.71768 11.7206 1.67067 10.27L1.6665 10L1.67067 9.73001C1.71734 8.29083 2.13613 6.88831 2.8862 5.65917C3.63628 4.43003 4.69205 3.41622 5.95058 2.71658C7.20911 2.01693 8.62746 1.65534 10.0673 1.66704C11.5072 1.67874 12.9195 2.06334 14.1665 2.78334ZM13.089 7.74417C12.9455 7.60069 12.7546 7.5145 12.5521 7.50177C12.3496 7.48903 12.1493 7.55063 11.989 7.67501L11.9107 7.74417L9.1665 10.4875L8.089 9.41084L8.01067 9.34167C7.85032 9.21739 7.65015 9.15586 7.44767 9.16863C7.2452 9.18141 7.05434 9.2676 6.91089 9.41106C6.76744 9.55451 6.68124 9.74537 6.66847 9.94784C6.65569 10.1503 6.71722 10.3505 6.8415 10.5108L6.91067 10.5892L8.57734 12.2558L8.65567 12.325C8.80182 12.4384 8.98153 12.4999 9.1665 12.4999C9.35148 12.4999 9.53119 12.4384 9.67734 12.325L9.75567 12.2558L13.089 8.92251L13.1582 8.84417C13.2825 8.68384 13.3441 8.48363 13.3314 8.28111C13.3187 8.07858 13.2325 7.88767 13.089 7.74417Z" />
        </Wrapper>
      );

    case 'warning':
      return (
        <Wrapper {...props}>
          <path d="M9.94984 1.66667C10.3508 1.66694 10.7458 1.76398 11.1012 1.94953C11.4567 2.13509 11.7621 2.40368 11.9915 2.7325L12.0815 2.86917L19.1073 14.5975C19.3179 14.9625 19.4323 15.375 19.4401 15.7962C19.4478 16.2175 19.3485 16.6339 19.1516 17.0064C18.9546 17.3789 18.6663 17.6953 18.3138 17.9261C17.9612 18.1568 17.5559 18.2944 17.1357 18.3258L16.969 18.3333H2.92234C2.50166 18.3283 2.08911 18.2168 1.72314 18.0093C1.35717 17.8018 1.0497 17.505 0.829391 17.1466C0.609086 16.7882 0.483119 16.3799 0.463234 15.9596C0.44335 15.5394 0.530195 15.1209 0.715674 14.7433L0.79984 14.5858L7.82067 2.865C8.04274 2.49918 8.3553 2.19674 8.72824 1.98685C9.10117 1.77695 9.5219 1.66668 9.94984 1.66667ZM10.0082 13.3333L9.90234 13.3392C9.6998 13.3633 9.51313 13.4608 9.37768 13.6133C9.24224 13.7658 9.16743 13.9627 9.16743 14.1667C9.16743 14.3706 9.24224 14.5675 9.37768 14.72C9.51313 14.8725 9.6998 14.9701 9.90234 14.9942L9.99984 15L10.1057 14.9942C10.3082 14.9701 10.4949 14.8725 10.6303 14.72C10.7658 14.5675 10.8406 14.3706 10.8406 14.1667C10.8406 13.9627 10.7658 13.7658 10.6303 13.6133C10.4949 13.4608 10.3082 13.3633 10.1057 13.3392L10.0082 13.3333ZM9.99984 6.66667C9.79573 6.6667 9.59873 6.74163 9.4462 6.87726C9.29367 7.0129 9.19622 7.19979 9.17234 7.4025L9.16651 7.5V10.8333L9.17234 10.9308C9.19643 11.1334 9.29397 11.32 9.44648 11.4555C9.59899 11.5909 9.79587 11.6657 9.99984 11.6657C10.2038 11.6657 10.4007 11.5909 10.5532 11.4555C10.7057 11.32 10.8032 11.1334 10.8273 10.9308L10.8332 10.8333V7.5L10.8273 7.4025C10.8035 7.19979 10.706 7.0129 10.5535 6.87726C10.401 6.74163 10.204 6.6667 9.99984 6.66667Z" />
        </Wrapper>
      );

    case 'error':
      return (
        <Wrapper {...props}>
          <path d="M9.99984 1.66667C14.6023 1.66667 18.3332 5.39751 18.3332 10C18.3349 12.1879 17.4761 14.2887 15.9423 15.8489C14.4084 17.409 12.3225 18.3034 10.1349 18.3388C7.94725 18.3743 5.83345 17.548 4.24984 16.0383C2.66622 14.5287 1.73983 12.4568 1.67067 10.27L1.6665 10L1.66984 9.76667C1.79317 5.27251 5.47484 1.66667 9.99984 1.66667ZM10.0082 12.5L9.90234 12.5058C9.6998 12.5299 9.51312 12.6275 9.37768 12.78C9.24224 12.9325 9.16743 13.1294 9.16743 13.3333C9.16743 13.5373 9.24224 13.7342 9.37768 13.8867C9.51312 14.0392 9.6998 14.1367 9.90234 14.1608L9.99984 14.1667L10.1057 14.1608C10.3082 14.1367 10.4949 14.0392 10.6303 13.8867C10.7658 13.7342 10.8406 13.5373 10.8406 13.3333C10.8406 13.1294 10.7658 12.9325 10.6303 12.78C10.4949 12.6275 10.3082 12.5299 10.1057 12.5058L10.0082 12.5ZM9.99984 5.83334C9.79573 5.83337 9.59872 5.9083 9.44619 6.04393C9.29366 6.17957 9.19622 6.36646 9.17234 6.56917L9.1665 6.66667V10L9.17234 10.0975C9.19643 10.3 9.29397 10.4867 9.44647 10.6222C9.59898 10.7576 9.79587 10.8324 9.99984 10.8324C10.2038 10.8324 10.4007 10.7576 10.5532 10.6222C10.7057 10.4867 10.8032 10.3 10.8273 10.0975L10.8332 10V6.66667L10.8273 6.56917C10.8035 6.36646 10.706 6.17957 10.5535 6.04393C10.401 5.9083 10.2039 5.83337 9.99984 5.83334Z" />
        </Wrapper>
      );

    case 'info':
    default:
      return (
        <Wrapper {...props}>
          <path d="M10.0002 18.3399C5.39766 18.3399 1.66683 14.6091 1.66683 10.0066C1.6651 7.81871 2.52388 5.7179 4.05775 4.15773C5.59162 2.59757 7.67754 1.70322 9.86515 1.66777C12.0528 1.63232 14.1665 2.45862 15.7502 3.96827C17.3338 5.47792 18.2602 7.5498 18.3293 9.7366L18.3335 10.0066L18.3302 10.2399C18.2068 14.7341 14.5252 18.3399 10.0002 18.3399ZM9.99183 7.5066L10.0977 7.50077C10.3002 7.47668 10.4869 7.37914 10.6223 7.22663C10.7578 7.07412 10.8326 6.87724 10.8326 6.67327C10.8326 6.4693 10.7578 6.27241 10.6223 6.11991C10.4869 5.9674 10.3002 5.86986 10.0977 5.84577L10.0002 5.83994L9.89433 5.84577C9.69179 5.86986 9.50512 5.9674 9.36967 6.11991C9.23423 6.27241 9.15943 6.4693 9.15943 6.67327C9.15943 6.87724 9.23423 7.07412 9.36967 7.22663C9.50512 7.37914 9.69179 7.47668 9.89433 7.50077L9.99183 7.5066ZM10.0002 14.1733C10.2043 14.1732 10.4013 14.0983 10.5538 13.9627C10.7063 13.827 10.8038 13.6401 10.8277 13.4374L10.8335 13.3399L10.8335 10.0066L10.8277 9.9091C10.8036 9.70656 10.706 9.51989 10.5535 9.38445C10.401 9.249 10.2041 9.1742 10.0002 9.1742C9.79619 9.1742 9.59931 9.249 9.4468 9.38445C9.29429 9.51989 9.19676 9.70656 9.17266 9.9091L9.16683 10.0066L9.16683 13.3399L9.17266 13.4374C9.19654 13.6401 9.29399 13.827 9.44652 13.9627C9.59905 14.0983 9.79605 14.1732 10.0002 14.1733Z" />
        </Wrapper>
      );
  }
};

export default Icon;
