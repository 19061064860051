import { createSlice } from '@reduxjs/toolkit';
import { post } from 'api/fetch';
import { fetchPlanholderInfo as fetchRHPlanholderInfo } from 'state/planholder';

// TODO: could probably add types from plans reducer

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/plan',
  initialState: {
    isLoading: false,
    error: null,
    id: null
  },
  reducers: {
    updatePlanRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updatePlanSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.id = payload;
    },
    updatePlanFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const updatePlan = (planId) => async (dispatch) => {
  dispatch(actions.updatePlanRequest());

  try {
    await post(`/member/onboarding/update-plan/${planId}`, null, { api: 'rh' });

    dispatch(actions.updatePlanSuccess(planId));
    dispatch(fetchRHPlanholderInfo(false));
  } catch (error) {
    dispatch(actions.updatePlanFailure(error));
  }
};
