import { createSlice } from '@reduxjs/toolkit';
import formatISO from 'date-fns/formatISO';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'companyPurchase/details',
  initialState: {
    startDate: formatISO(new Date()),
    includeDependants: false,
    registrationNumber: '',
    countries: []
  },
  reducers: {
    updateDetails: (state, { payload }) => {
      state.registrationNumber = payload.registrationNumber;
      state.countries = payload.countries;
      state.startDate = payload.startDate;
      state.includeDependants = payload.includeDependants;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const updateDetails = (info) => (dispatch) => {
  dispatch(actions.updateDetails(info));
};
