import { type PropsWithoutRef, type FC } from 'react';
import styled from 'styled-components';
import AutoPortal from 'components/Gen.2/AutoPortal';
import { AnimatePresence, motion } from 'framer-motion';
import { useToasterContext } from './context';
import Toast from './components/Toast';

export * from './context';

type ToasterProps = PropsWithoutRef<JSX.IntrinsicElements['div']>;

const Wrapper = styled.div`
  position: fixed;
  top: 78px;
  left: 0;

  height: 0;
  width: 0;

  display: flex;
  flex-direction: column;

  z-index: 10000;
`;

const Item = styled.div`
  position: relative;
  left: 50vw;
  width: calc(100vw - 32px);
  max-width: 390px;
  box-sizing: content-box;

  > * {
    /* gap that plays nicely with the list animation */
    margin-bottom: 8px;
  }
`;

const MotionItem = motion(Item);

const Toaster: FC<ToasterProps> = (props) => {
  const { toasts } = useToasterContext();

  return (
    <AutoPortal>
      <Wrapper {...props}>
        {/* @ts-ignore */}
        <AnimatePresence>
          {toasts.map((toast) => (
            <MotionItem
              key={toast.id}
              transition={{
                type: 'spring',
                stiffness: 400,
                damping: 30
              }}
              initial={{ opacity: 0, height: 0, scale: 0.97, x: '-50%' }}
              animate={{ opacity: 1, height: 'auto', scale: 1, x: '-50%' }}
              exit={{ opacity: 0, height: 0, scale: 0.97, x: '-50%' }}
            >
              <Toast {...toast.toastProps} />
            </MotionItem>
          ))}
        </AnimatePresence>
      </Wrapper>
    </AutoPortal>
  );
};

export default Toaster;
