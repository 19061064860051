import get from 'lodash/get';

// TODO: ts
/**
 * @deprecated use colors straight from theme `import { colors } from 'theme'`
 */
export const th = (path) => (props) => get(props.theme, path);

/**
 * @deprecated
 */
const getValue = (props, variant) =>
  typeof variant === 'function' ? variant(props) : variant;

// Creates a function that returns a matching prop value when passed a string
export const variants = (prop, values) => (props) => {
  const variant = props[prop] && values[props[prop]];
  return variant && getValue(props, variant);
};
