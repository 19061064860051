import { keyframes } from 'styled-components';

export const SCREEN_SIZES = {
  mobileMedium: 375,
  mobileLarge: 425,
  tabletPortrait: 576,
  tabletLandscape: 768,
  desktop: 992,
  largeDesktop: 1200,
  mediumLargeDesktop: 1300,
  extraLargeDesktop: 1440,
  monitor: 1800
} as const;

export const sizes = {
  mobileMedium: `(min-width: ${SCREEN_SIZES.mobileMedium}px)`,
  mobileLarge: `(min-width: ${SCREEN_SIZES.mobileLarge}px)`,
  tabletPortrait: `(min-width: ${SCREEN_SIZES.tabletPortrait}px)`,
  tabletLandscape: `(min-width: ${SCREEN_SIZES.tabletLandscape}px)`,
  desktop: `(min-width: ${SCREEN_SIZES.desktop}px)`,
  largeDesktop: `(min-width: ${SCREEN_SIZES.largeDesktop}px)`,
  mediumLargeDesktop: `(min-width: ${SCREEN_SIZES.mediumLargeDesktop}px)`,
  extraLargeDesktop: `(min-width: ${SCREEN_SIZES.extraLargeDesktop}px)`,
  monitor: `(min-width: ${SCREEN_SIZES.monitor}px)`
} as const;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
