import { createSlice } from '@reduxjs/toolkit';
import formatISO from 'date-fns/formatISO';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'companyPurchase/otherInsurance',
  initialState: {
    otherInsurance: null,
    policyNumber: '',

    expirationDate: formatISO(new Date()),
    insuranceCompany: '',

    secondaryPlan: false,
    recentClaims: '',

    insuranceCertificateFile: null
  },
  reducers: {
    updateOtherInsurance: (state, { payload }) => {
      state.otherInsurance = payload.otherInsurance;
      state.policyNumber = payload.policyNumber;
      state.expirationDate = payload.expirationDate;
      state.insuranceCompany = payload.insuranceCompany;
      state.secondaryPlan = payload.secondaryPlan;
      state.recentClaims = payload.recentClaims;
      state.insuranceCertificateFile = payload.insuranceCertificateFile;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const updateOtherInsurance = (info) => (dispatch) => {
  dispatch(actions.updateOtherInsurance(info));
};
