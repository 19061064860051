// sends login event to GTM. Method is either "email" or "facebook"
export const fireLoginTrackingEvent = (userID, method) => {
  window.dataLayer.push({
    event: 'login',
    method,
    user_id: userID
  });
};

// sends signup event to GTM. Method is either "email" or "facebook"
export const fireSignupTrackingEvent = (userID, method, affiliateID) => {
  window.dataLayer.push({
    event: 'sign_up',
    method,
    user_id: userID,
    affiliation: affiliateID
  });
};

export const ANALYTICS_ITEM_IDS = {
  nomadInsurance: 1,
  addon_ni_usCoverage: 11,
  nomadHealth: 2,
  addon_nh_dental: 21,
  addon_nh_deductible: 22,
  addon_nh_outpatient: 23,
  addon_nh_usElective: 24,
  remoteHealth: 3,
  addon_rh_dental: 31,
  addon_rh_deductible: 32,
  addon_rh_maternity: 33,
  addon_rh_screeningsAndVaccines: 34,
  addon_rh_outpatient: 35,
  addon_rh_usElective: 36,
  remoteDoctor: 4,
  addon_rd_tier2: 41
};

export const getPurchaseItem = (name, variant, quantity, price) => {
  const item = {
    item_id: ANALYTICS_ITEM_IDS[name],
    item_name: name,
    item_variant: variant,
    quantity
  };
  if (price) {
    return { ...item, price };
  }
  return item;
};

/*
  Items should be an array of objects in format:
   'item_name': 'nomadInsurance',
    'item_id': ‘69836’, // see item id's above
    'price': ’41’,
    'item_variant': ‘recurring’,// either "recurring" (subscription) or "single"
    'quantity': 2	
*/
export const firePurchaseTrackingEvent = (
  transactionID,
  userID,
  value,
  affiliateID,
  items
) => {
  window.dataLayer.push({
    event: 'purchase',
    user_id: userID,
    ecommerce: {
      purchase: {
        transaction_id: transactionID,
        affiliation: affiliateID,
        value,
        currency: 'USD',
        items
      }
    }
  });
};
