import { type FC, type PropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'style/theme';
import { labelSStyle, paragraphSStyle } from 'components/Gen.2/Typography';
import { ToastProps, ToastType } from '../../types';
import Icon from '../Icon';
import CloseButton from '../CloseButton';

type TToastProps = PropsWithoutRef<JSX.IntrinsicElements['div']> & ToastProps;

const Wrapper = styled.div<{ $type: ToastType; $extraBottomPadding: boolean }>`
  --toast-bg: ${(p) => colors.toast[p.$type].bg};
  --toast-fg: ${(p) => colors.toast[p.$type].fg};
  --toast-text: ${(p) => colors.toast[p.$type].text};

  display: grid;
  grid-template-columns: 20px 1fr auto;
  grid-template-areas:
    'icon title close'
    '. body .';
  gap: 8px;
  row-gap: 0;
  padding: 6px 8px 6px 10px;
  border-radius: 12px;
  align-items: center;
  background-color: var(--toast-bg);
  border: 1.5px solid var(--toast-fg);
  color: var(--toast-text);

  ${(p) =>
    p.$extraBottomPadding &&
    css`
      padding-bottom: 12px;
    `}
`;

const CustomIcon = styled(Icon)`
  grid-area: icon;
`;

const Title = styled.h6<{ $strong?: boolean }>`
  grid-area: title;
  ${paragraphSStyle};
  ${(p) => p.$strong && labelSStyle};
`;

const Body = styled.p`
  grid-area: body;
  ${paragraphSStyle};
`;

const Toast: FC<TToastProps> = ({ type = 'info', title, body, onClose, ...props }) => {
  const hasBody = !!body;

  return (
    <Wrapper $type={type} $extraBottomPadding={hasBody} {...props}>
      <CustomIcon type={type} />
      <Title $strong={hasBody}>{title}</Title>
      <CloseButton onClick={onClose} />

      {body && <Body>{body}</Body>}
    </Wrapper>
  );
};

export default Toast;
