import { createSlice } from '@reduxjs/toolkit';
import { get, post } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'remote_health/CompanyProfile/account/billing',
  initialState: {
    isLoading: false,
    billingInfo: null,
    error: null
  },
  reducers: {
    // fetchBillingInformation
    fetchBillingInformationRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchBillingInformationSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.billingInfo = payload || {};
    },
    fetchBillingInformationFailure: (state, { payload }) => {
      state.isLoading = false;
      state.billingInfo = null;
      state.error = payload;
    },
    // setBillingInformation
    setBillingInformationRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    setBillingInformationFailure: (state, { payload }) => {
      state.isLoading = false;
      state.billingInfo = null;
      state.error = payload;
    }
  }
});

export const { actions } = slice;

// ================== Actions ================== //

export const fetchBillingInformation = () => async (dispatch) => {
  dispatch(actions.fetchBillingInformationRequest());

  try {
    const response = await get('/rh/companies/billing/details', {
      api: 'rh',
      redirectOnUnauthorized: true
    });
    dispatch(actions.fetchBillingInformationSuccess(response));
  } catch (error) {
    dispatch(actions.fetchBillingInformationFailure(error));
  }
};

export const setBillingInformation = (billingInfo, companyId) => async (dispatch) => {
  dispatch(actions.setBillingInformationRequest());
  let body = {
    paymentMethod: billingInfo.paymentMethod || '',
    frequency: billingInfo.frequency || ''
  };

  if (body.paymentMethod === 'CARD') {
    const cardData = {
      cardScheme: billingInfo.cardScheme,
      encryptedPan: billingInfo.encryptedPan,
      encryptedCvv: billingInfo.encryptedCvv,
      cardHolderName: billingInfo.cardHolderName,
      expiryMonth: billingInfo.expiryMonth,
      expiryYear: billingInfo.expiryYear,
      addressLine: billingInfo?.address?.line1,
      addressLineExtra: billingInfo?.addres?.line2,
      city: billingInfo?.address?.city,
      state: billingInfo?.address?.state,
      country: billingInfo?.address?.country,
      zip: billingInfo?.address?.zipCode
    };
    body = {
      ...body,
      ...cardData
    };
  }

  try {
    await post(`/rh/companies/${companyId}/billing/details`, body, { api: 'rh' });
    await dispatch(fetchBillingInformation());
  } catch (error) {
    dispatch(actions.setBillingInformationFailure(error));
  }
};

export default slice.reducer;
