import { createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';
import format from 'date-fns/format';
import history from 'routing/history';
import { sendRHPurchaseEvent } from 'assets/scripts/analytics';
import { fetchCompanyContract } from 'state/company';
import { post } from 'api/fetch';

// ================== Reducers ================== //

const slice = createSlice({
  name: 'companyPurchase/summary',
  initialState: {
    isLoading: false,
    error: null,
    informationProvided: false,
    hasPurchasedContract: false
  },
  reducers: {
    updateSummary: (state, { payload }) => {
      state.informationProvided = payload.informationProvided;
      state.hasPurchasedContract = payload.hasPurchasedContract;
    },
    // initiateCompanyPurchase
    initiateCompanyPurchaseRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    initiateCompanyPurchaseSuccess: (state) => {
      state.isLoading = false;
    },
    initiateCompanyPurchaseFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    }
  }
});

export default slice.reducer;

// ================== Actions ================== //

const { actions } = slice;

export const initiateCompanyPurchase = (next) => async (dispatch, useState) => {
  dispatch(actions.initiateCompanyPurchaseRequest());

  const { config, price } = useState().remoteHealth.companyProfile.purchase;
  const { userID } = useState().app.user;

  const body = {
    insuranceConfig: {
      isCompany: true,
      company: true,
      usElective: config.addons.includes('COVERAGE'),
      deductible: config.addons.includes('DEDUCTIBLE') ? 0 : 250,
      paymentFrequency: config.paymentFrequency === 'Annually' ? 'YEARLY' : 'MONTHLY',
      addons: config.addons,
      memberCount: {
        memberCount0to17: config.members['0to17'],
        memberCount18to39: config.members['18to39'],
        memberCount40to50: config.members['40to49'],
        memberCount51to60: config.members['50to59'],
        memberCount61to70: config.members['60to69']
      }
    },
    startDate: format(new Date(), 'yyyy-MM-dd'),
    includeDependants: config.addons.includes('DEPENDANTS'),
    companyContractDetails: {
      otherInsuranceDetails: null,
      companyRegistrationNumber: null,
      companyEntityCountries: []
    }
  };

  try {
    const res = await post('/insurance/rh/purchase/company', body);
    const contractID = res.companyContractID;
    await dispatch(fetchCompanyContract());
    dispatch(actions.initiateCompanyPurchaseSuccess());
    ReactGA.event({
      category: 'Remote Health',
      action: 'Purchase'
    });

    if (contractID) {
      // send analytics
      sendRHPurchaseEvent(config, userID, price, contractID);
    }

    if (next) {
      history.push(next);
    }
  } catch (error) {
    dispatch(actions.initiateCompanyPurchaseFailure(error));
  }
};

export const updateSummary = (info) => (dispatch) => {
  dispatch(actions.updateSummary(info));
};
