import styled, { css } from 'styled-components';
import { variants, th } from 'style/theme';
import Spinner from 'components/Spinner';
import type { LoadingOverlayVariants } from '.';

interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  scrollLock?: boolean;
  variant: LoadingOverlayVariants;
}

export const Overlay = styled.div<OverlayProps>`
  ${variants('variant', {
    light: css`
      background-color: rgba(255, 255, 255, 0.6);
      filter: blur(0px) !important;
      & > p {
        color: black;
      }
    `,
    dark: css`
      background-color: rgba(36, 36, 90, 0.6);
      filter: blur(0px) !important;
      & > p {
        color: white;
      }
    `,
    global: css`
      background-color: transparent;
      filter: blur(0px) !important;
      & > p {
        color: ${th('colors.black')};
      }
    `,
    darkFull: css`
      background-color: var(--color-minsk-700);
      & > p {
        color: var(--color-white);
      }
    `
  })}

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  ${({ scrollLock }) =>
    scrollLock &&
    css`
      position: fixed;
      height: 100vh;
    `}
  & > p {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
    white-space: pre-line;
  }
`;

export const OverlaySpinner = styled(Spinner)`
  margin: 0;
`;
