export const DEEL_INDIVIDUALS_COMPANIES = [
  9464121, // Staging
  181 // Production
];

export const SESSION_STORAGE_COMPANY_NAME = 'company-name';

export const NOMAD_HEALTH_WAITLIST =
  'https://explore.safetywing.com/nomad-health-waitlist/';

export const NAVIGATION_HEIGHT = 60;
export const NAVIGATION_HEIGHT_MOBILE = 50;

export const COMPANY_PLAN_TYPES = {
  ESSENTIAL: 'ESSENTIAL',
  STANDARD: 'STANDARD',
  PREMIUM: 'PREMIUM',
  MATERNITY: 'MATERNITY',
  AFFINITY_STANDARD: 'AFFINITY_STANDARD',
  AFFINITY_PREMIUM: 'AFFINITY_PREMIUM',
  AFFINITY_MATERNITY: 'AFFINITY_MATERNITY',
  AFFINITY_OLD: 'AFFINITY_OLD',
  AFFINITY_ESSENTIAL: 'AFFINITY_ESSENTIAL',
  INDIVIDUAL_STANDARD: 'INDIVIDUAL_STANDARD',
  INDIVIDUAL_PREMIUM: 'INDIVIDUAL_PREMIUM',
  INDIVIDUAL_MATERNITY: 'INDIVIDUAL_MATERNITY'
};

export const providers = {
  NOW_HEALTH_US: 'UNITED_HEALTHCARE',
  NOW_HEALTH_GLOBAL: 'NOW_HEALTH_GLOBAL',
  VUMI_BASIC_FLEX: 'VUMI_BASIC_FLEX',
  VUMI_VIP: 'VUMI_VIP',
  VUMI_INDIVIDUALS: 'VUMI_INDIVIDUALS',
  UNITED_HEALTHCARE: 'UNITED_HEALTHCARE',
  STANDARD_OLD: 'STANDARD_OLD',
  VUMI_DEEL: 'VUMI_DEEL'
};

export const PROVIDERS = [
  'NOW_HEALTH_US',
  'UNITED_HEALTHCARE',
  'NOW_HEALTH_GLOBAL',
  'VUMI_VIP',
  'VUMI_BASIC_FLEX',
  'VUMI_INDIVIDUALS',
  ...Object.values(COMPANY_PLAN_TYPES)
];

export const PLAN_TYPES = {
  COMPANY_FMU: 'COMPANY_FMU',
  COMPANY_MHD: 'COMPANY_MHD',
  COMPANY_INDIVIDUAL_PLAN_ADMIN: 'COMPANY_INDIVIDUAL_PLAN_ADMIN',
  SOLO_INDIVIDUAL: 'SOLO_INDIVIDUAL'
};

export const ADDONS = [
  'DENTAL',
  'DEDUCTIBLE',
  'OUTPATIENT',
  'MATERNITY',
  'SCREENING',
  'VISION'
];

export const ADDON_TYPES = {
  DENTAL: 'DENTAL',
  DEDUCTIBLE: 'DEDUCTIBLE',
  OUTPATIENT: 'OUTPATIENT',
  MATERNITY: 'MATERNITY',
  SCREENING: 'SCREENING',
  VISION: 'VISION'
};

export const AGE_RANGES = [
  { value: '0-17 years', label: '0-17 years' },
  { value: '18-39 years', label: '18-39 years' },
  { value: '40-49 years', label: '40-49 years' },
  { value: '50-59 years', label: '50-59 years' },
  { value: '60-75 years', label: '60-75 years' }
];

export const FINCH_PRODUCTS = ['company', 'directory', 'individual'];

export const NH_COMPANY_ID = 3079;
