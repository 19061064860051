const SECRET = 'rgwV1E42uoA3Ba1uMQcuAt2DNhK5SVkSTV1YZzjuxXTF9AGiz7cbzId6YOWhFfqF';

export const SEVEN_DAYS = 1000 * 60 * 60 * 24 * 7;

export const ROLES = {
  MEMBER: 'member',
  OWNER: 'owner',
  ADMIN: 'admin'
};

export default SECRET;
